import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[dateMask]',
})
export class DateMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    console.log(event);
    let newVal = event.replace(/\./g, '');
    console.log(newVal);
    // if (backspace && newVal.length <= 6) {
    //   newVal = newVal.substring(0, newVal.length - 1);
    // }

    if (newVal.length < 2) {
      // newVal = newVal.replace(/^(\d{0,3})/, '$1');
      // newVal = '375 ' + (newVal.length == 1 ? newVal : '');
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1.');
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1.$2.');
    } else {
      newVal = newVal.substring(0, 8);
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1.$2.$3');
    }
    console.log(newVal);
    this.ngControl.valueAccessor.writeValue(newVal);
  }
}
