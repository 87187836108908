import {Component, ViewEncapsulation} from '@angular/core';
import {DataForm} from '../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {RootRememberPasswordModalComponent} from '../../root/sign-in/remember-password-modal/remember-password-modal.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent extends DataForm {
  siteKey: string = environment.recaptchaPublicKey;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        login: [
          null, [
            Validators.required
          ]],
        password: [
          null, [
            Validators.required
          ]]
      });

    // reset login status
    this.adminAuth.logout();
  }

  toLogin() {
    this.reCaptchaV3Service.execute(this.siteKey, 'admin/auth', (token) => {
      console.log('captcha token: ', token);
      this.adminAuth.login(
        this.f.login.value,
        this.f.password.value,
        token
      )
        .pipe(first())
        .subscribe(
          user => {
            this.router.navigate(['/admin/orders']);
          },
          err => {
            this.onStopSubmit();

            switch (err.status) {
              case 401:
                err.error = 'Неверный логин или пароль';
                break;

              case 403:
                err.error = 'Недостаточно прав для выполнения операции';
                break;
            }

            this.error = err.error;
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

  switchValidators(isRequire: boolean) {
    const control = this.dataForm.get('password');

    const validators =
      isRequire
        ? [ Validators.required ]
        : [ ];

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  onSubmit() {
    this.switchValidators(true);
    this.error = null;

    if (!this.onStartSubmit()) {
      return;
    }

    this.toLogin();
  }

  onRemember() {
    this.switchValidators(false);

    if (!this.onStartSubmit()) {
      this.error = 'Пожалуйста, укажите логин';
      return;
    }

    this.reCaptchaV3Service.execute(this.siteKey, 'root/fgetpsw', (token) => {
      console.log('captcha token: ', token);
      this.rootAuth.requestForgetPass(
        this.f.login.value,
        token
      )
        .subscribe(
          data => {
            this.onStopSubmit();

            const modalDelRef = this.modalService.open(
              RootRememberPasswordModalComponent,
              { backdrop: 'static' }
            );
            modalDelRef.componentInstance.login = this.f.login.value;
            modalDelRef.componentInstance.token = token;
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

}
