import { Component, OnInit } from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {Utils} from '../../../_helpers/utils';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['../page-sign-up.component.scss']
})
export class ContactInformationComponent extends DataForm implements OnInit {

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        phone: [
          // this.phoneFormat(this.auth.currentUserValue.login)
          null, [ Validators.pattern('^\\+375 \\d{2} \\d{3} \\d{2} \\d{2}$'), Validators.required ]
        ],
        email: [
          null, [ Validators.email ]
        ]
      });
  }

  ngOnInit() {
    super.ngOnInit();

    // формат вывода телефона
    let phone = Utils.phoneFormat(this.auth.currentUserValue.login);

    if (this.obj && this.obj.phone) {
      phone = Utils.phoneFormat(this.obj.phone);
    }

    this.dataForm.get('phone').setValue(phone, { emitEvent: false });

    // FIXME: WTF?
    this.onResponse.emit({
      valid: this.dataForm.valid,
      data: this.dataForm.value
    });

    // console.log('valid', this.dataForm.valid);
  }

}
