import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';
import {AdminAuthenticationService} from '../_services/admin-authentication.service';
import {ActivatedRoute} from '@angular/router';
import {RootAuthenticationService} from '../_services/root-authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private adminAuthService: AdminAuthenticationService,
    private rootAuthService: RootAuthenticationService,
    private activatedRoute: ActivatedRoute
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with token if available
    const currentUser = this.authenticationService.currentUserValue;
    const currentAdmin = this.adminAuthService.currentAdminValue;
    const currentRoot = this.rootAuthService.currentRootValue;

    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          'Token-key': `${currentUser.token}`
          // Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    if (
      currentAdmin && currentAdmin.token
      && this.activatedRoute.firstChild.snapshot.data && this.activatedRoute.firstChild.snapshot.data.section === 'admin'
    ) {
      request = request.clone({
        setHeaders: {
          'Token-key': `${currentAdmin.token}`
        }
      });
    }

    if (
      currentRoot && currentRoot.token
      && this.activatedRoute.firstChild.snapshot.data && this.activatedRoute.firstChild.snapshot.data.section === 'root'
    ) {
      request = request.clone({
        setHeaders: {
          'Token-key': `${currentRoot.token}`
        }
      });
    }

    // console.log(request);
    // console.log(request.headers.get('Accept'));

    if (!request.headers.get('Accept')) {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json'
          // 'Accept': 'application/json;charset=UTF8'
          // 'X-Requested-By':'Angular 2'
          // 'Accept': 'application/json;q=0.9,*/*;q=0.8'
        }
      });
    }

    return next.handle(request);
  }
}
