import { Component, OnInit } from '@angular/core';
import {DataTable} from '../../_helpers/dataTable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from '../../_services/admin.service';
import {first} from 'rxjs/operators';
import {OptionModalComponent} from '../options/option-modal/option-modal.component';
import {environment} from '../../../environments/environment';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html'
})
export class AccessComponent extends DataTable implements OnInit {

  accessList: any[];

  constructor(
    private modalService: NgbModal,
    private adminService: AdminService
  ) {
    super();
  }

  ngOnInit() {
    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        columns: [
          { data: 'id' },
          { data: 'auth' },
          { data: 'mtd' },
          { data: 'url' }
        ]
      }
    );

    this.loadData();
  }

  loadData() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.adminService.getAccessList(dataTablesParameters)
        .pipe(first())
        .subscribe(data => {
          console.log(data);

          const count = data.length;

          if (data.length > dataTablesParameters.length) {
            data.pop();
          }

          this.accessList = data;

          callback({
            recordsTotal: dataTablesParameters.start + count,
            recordsFiltered: this.accessList.length,
            data: { data: this.accessList }
          });
        });
    };
  }

  onShowPop() {
    const modalRef = this.modalService.open(
      OptionModalComponent
    );

    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.reload();
    });
  }

  onDelete(id: number) {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить #' + id + '?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.adminService.deleteConst(id)
        .pipe(first())
        .subscribe(
          data => {
            if (data && data.errorText) {
              alert(data.errorText);
            } else {
              this.reload();
            }
          },
          err => {
            alert(err.error);
          }
        );
    });
  }

}
