import {Component, Input, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {DataForm} from '../../_helpers/dataForm';
import {first, map} from 'rxjs/operators';
import {AcceptModalComponent} from './accept-modal/accept-modal.component';
import {ActivatedRoute, NavigationExtras} from '@angular/router';
import {UserService} from '../../_services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../_services/authentication.service';
import {environment} from '../../../environments/environment';
import {DatePipe} from '@angular/common';

declare var jQuery: any;

@Component({
  selector: 'app-page-sign-up',
  templateUrl: './page-sign-up.component.html',
  styleUrls: ['./page-sign-up.component.scss']
})
export class PageSignUpComponent implements OnInit {

  phone = '';
  error = '';
  loading = false;
  userDataLoaded = false;
  debug: boolean = environment.debug;
  agreeDt;
  datePipe = new DatePipe('ru-RU');

  @Input() title = 'Регистрация';
  @Input() userData;

  selectedSection = null;

  sectionList = {
    userInfo: {
      title: 'Персональные данные',
      content: '<app-personaldata></app-personaldata>'
    },
    // userPassport: {
    //   title: 'Документ, удостоверяющий личность',
    //   content: '<app-identity-document></app-identity-document>'
    // },
    userContacts: {},
    // additionalContactLst: {},
    // accommodation: {},
    // familyStatus: {},
    // education: {},
    // employment: {},
    // income: {},
    // loansGrantedLst: {},
    userIncomeLst: {},
    userBanksLst: {}
  };

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    public modalService: NgbModal,
    public auth: AuthenticationService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['phone'] && params['phone'] !== 'undefined') {
        this.phone = params['phone'];
      }
    });

    const currentUser = this.auth.currentUserValue;

    if (
      // e  - не заполнена
      // f  - заполнена
      currentUser &&
      !this.phone &&
      currentUser.userformStatusCode === 'e' // анкета не заполнена
    ) {
      this.userService.getInfoFromOrder()
        .subscribe(data => {
          this.userData = data;
          // this.userData = {"userContacts":{"email":"av@3c.by"},"userInfo":{"name":"Андрей",
          //     "patronymic":"Геннадьевич","surname":"Васильков"}};
          this.userDataLoaded = true;

          if (this.userData && this.userData.agreedDt) {
            this.agreeDt = true;
          }
        });
    } else {
      this.userDataLoaded = true;
    }

    if (this.userData && this.userData.agreedDt) {
      this.agreeDt = true;
    }
  }

/*  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        agreement: [
          true, [
            Validators.requiredTrue
          ]]
      });
  }*/

  onChangeForm(section: string, event: boolean) {
    if (event) {
      this.selectedSection = section;
    } else {
      this.selectedSection = null;
    }
  }

  onNextSubmit() {
    const group = this.sectionList[this.selectedSection];
    group.submitted = true;

    if (
      group.response && group.response.valid
      || !group.response && group.submitted
    ) {

      // убираем пробелы из телефона
      if (this.selectedSection === 'userContacts' && group.response && group.response.data.phone) {
        group.response.data.phone = group.response.data.phone.replace(/[ \+]/g, '');
      }

      let lastNext = false;

      for (const section in this.sectionList) {
        if (lastNext) {
          this.selectedSection = section;
          break;
        }

        if (section === this.selectedSection) {
          lastNext = true;
          this.selectedSection = null;
        }
      }
    }
  }

  onRegisterSubmit() {
    // if (!this.onStartSubmit()) {
    //   return;
    // }

    let valid = this.agreeDt ? true : false;

    for (const section in this.sectionList) {
      const group = this.sectionList[section];
      group.submitted = true;

      if (group.response && group.response.valid) {
        // убираем пробелы из телефона
        if (group.response && group.response.data.phone) {
          group.response.data.phone = group.response.data.phone.replace(/[ \+]/g, '');
        }
      } else {
        valid = false;
      }
    }

    if (!valid) {
      setTimeout(function() {
        jQuery('html, body').animate({
          scrollTop: (
            jQuery('.is-invalid:first').offset().top -
            (jQuery('body').hasClass('mobile') ? jQuery('header').outerHeight() + 40 : 0)
          )
        }, 500);
      });

      return false;
    }

    this.loading = true;
    this.error = '';

    this.userService.sendCode()
      .pipe(first())
      .subscribe(
        data => {
          // this.onStopSubmit();
          this.loading = false;

          const now = new Date();
          const d = {
            agreedDt: this.datePipe.transform(now, 'yyyy-MM-ddTHH:mm:ssZZZZZ')
          };

          for (const section in this.sectionList) {
            const group = this.sectionList[section];

            if (group.response && group.response.valid) {
              if (group.response.data.items) {
                d[section] = group.response.data.items;
              } else {
                d[section] = group.response.data;
              }
            }
          }

          let issueOrderId = null;

          this.route.queryParams.subscribe(params => {
            issueOrderId = params['issue'];
          });

          // issueOrderId = this.route
          //   .queryParamMap
          //   .pipe(map(params => params.get('issue') || null));

          console.log('issueOrderId', issueOrderId);

          const modalRef = this.modalService.open(
            AcceptModalComponent,
            { backdrop: 'static' }
          );
          modalRef.componentInstance.number = this.auth.currentUserValue.login;
          modalRef.componentInstance.data = d;
          modalRef.componentInstance.issueOrderId = issueOrderId;
        },
        err => {
          // this.onStopSubmit();
          this.error = err.error;
          this.loading = false;
        }
      );

  }

}
