import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RootService} from '../../../_services/root.service';
import {NamedService} from '../../../_services/named.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PriorityModalComponent} from '../../priorities/priority-modal/priority-modal.component';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';
import {Named} from '../../../_business/named';
import {BankProductModalComponent} from './bank-product-modal/bank-product-modal.component';
import {ProductTypeEnum} from '../../../_business/ProductType.enum';
import {BankStatusTypeEnum} from '../../../_business/BankStatusType.enum';
import {ToastaService} from 'ngx-toasta';
import {RootAuthenticationService} from '../../../_services/root-authentication.service';

declare var jQuery: any;

@Component({
  selector: 'app-products',
  templateUrl: './bank-products.component.html'
})
export class BankProductsComponent implements OnInit {

  debug: boolean = environment.debug;

  stores;
  priorityStores;
  banks;
  filterStores = [];
  // filterStoreName;
  filterBanks = [];

  productTypeEnum = ProductTypeEnum;
  bankStatusTypeEnum = BankStatusTypeEnum;
  productTypes: Named[];
  bankStatusTypes: Named[];

  dataList: any[];
  selectedList = [];

  error;
  errorGlobal;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rootService: RootService,
    private namedService: NamedService,
    private modalService: NgbModal,
    public toastr: ToastaService,
    public auth: RootAuthenticationService
  ) {
  }

  ngOnInit() {
    this.rootService.getPriorityStores()
      .subscribe(data => {
          this.priorityStores = data;

          // if (this.priorityStores.length) {
          //   this.filterStore = this.priorityStores[0].id;
          //   this.filterStoreName = this.priorityStores[0].name;
          // }

          this.reload();

          setTimeout(function () {
            jQuery('select.sp').selectpicker({
              actionsBox: true,
              liveSearch: true,
              // countSelectedText: 1,
              deselectAllText: 'Убрать все',
              selectAllText: 'Выбрать все',
              noneResultsText: 'Не найдено {0}',
              // allSelectedText: 'All',
              // maxHeight: 200,
            });
          }, 500);
        },
        err => {
          this.errorGlobal = err.error;
        }
      );

    this.rootService.getStores()
      .subscribe(data => {
        this.stores = data;
      });

    // this.namedService.getBanks()
    //   .subscribe(data => {
    //     this.banks = data;
    //   });

    this.rootService.getPriorityBanks()
      .subscribe(data => {
        this.banks = data;
      });

    this.namedService.getNamedList('bpproducts')
      .subscribe(types => {
        this.productTypes = types;
      });

    this.namedService.getNamedList('bpstatuses')
      .subscribe(types => {
        this.bankStatusTypes = types;
      });
  }

  reload() {
    this.dataList = this.errorGlobal = null;

    this.rootService.getBankProducts({
      filterStores: this.filterStores,
      filterBanks: this.filterBanks
    })
      .subscribe(data => {
          this.dataList = data;
        },
        err => {
          this.errorGlobal = err.error;
        }
      );
  }

  getStoreById(id: number) {
    if (this.stores) {
      for (let i = 0; i < this.stores.length; i++) {
        if (this.stores[i].id === id) {
          return this.stores[i];
        }
      }
    }

    return null;
  }

  getStoreNamesByIds(ids) {
    const names = [];

    for (let i = 0; i < ids.length; i++) {
      const store = this.getStoreById(ids[i]);
      names.push(
        store ? store.name : ids[i]
      );
    }

    return names;
  }

  getBankById(id: number) {
    if (this.banks) {
      for (let i = 0; i < this.banks.length; i++) {
        if (this.banks[i].id === id) {
          return this.banks[i];
        }
      }
    }

    return null;
  }

  getBankStatusById(id: number) {
    if (this.bankStatusTypes) {
      for (let i = 0; i < this.bankStatusTypes.length; i++) {
        if (this.bankStatusTypes[i].id === id) {
          return this.bankStatusTypes[i];
        }
      }
    }

    return null;
  }

  getProductTypeById(id: number) {
    if (this.productTypes) {
      for (let i = 0; i < this.productTypes.length; i++) {
        if (this.productTypes[i].id === id) {
          return this.productTypes[i];
        }
      }
    }

    return null;
  }

  onShowPop(obj = null) {
    if (!this.auth.hasRight('%/webresources/com.zabiray.root.bank.products%', 'post')) {
      return false;
    }

    const modalRef = this.modalService.open(
      BankProductModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.banks = this.banks;
    // modalRef.componentInstance.stores = this.stores;
    // modalRef.componentInstance.selectedStore = this.filterStore;
    modalRef.componentInstance.productTypes = this.productTypes;
    modalRef.componentInstance.bankStatusTypes = this.bankStatusTypes;

    // modalRef.componentInstance.selectedPriority = this.dataList.length + 1;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.reload();

      switch ($e) {
        case 'save':
          this.toastr.success('Данные успешно сохранены');
          break;
      }
    });
  }

  onSelectObj(event, id: any) {
    if (event.target.checked) {
      this.selectedList.push(id);
    } else {
      const index = this.selectedList.indexOf(id);
      this.selectedList.splice(index, 1);
    }
  }

  toDelete() {
    this.error = '';

    const id = this.selectedList.shift();

    this.rootService.deleteBankProduct(id)
      .pipe(first())
      .subscribe(
        data => {
          for (let x = 0; x < this.dataList.length; x++) {
            const d = this.dataList[x];

            if (d.id === id) {
              this.dataList.splice(x, 1);
              break;
            }
          }

          if (this.selectedList.length) {
            this.toDelete();
          } else {
            this.toastr.success('Успешно удалено');
          }
        },
        err => {
          this.error = err.error;
        }
      );
  }

  toChangeStatus(status: BankStatusTypeEnum) {
    this.error = '';

    const id = this.selectedList.shift();

    for (const i in this.dataList) {
      const obj = this.dataList[i];

      if (obj.id === id) {
        obj.statusId = status;

        this.rootService.createBankProduct(obj)
          .pipe(first())
          .subscribe(
            data => {
              if (this.selectedList.length) {
                this.toChangeStatus(status);
              } else {
                this.reload();
                this.toastr.success(
                  status === this.bankStatusTypeEnum.block
                  ? 'Выбранные банковские продукты заблокированы'
                  : 'Доступ к выбранным банковским продуктам восстановлен'
                );
              }
            },
            err => {
              this.error = err.error;
            }
          );
        break;
      }
    }
  }

  onDelete() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Выбранные банковские продукты будут удалены. Продолжить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toDelete();
    });
  }

  onRestore() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Выбранные банковские продукты будут доступны для работы с платформой. Продолжить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStatus(this.bankStatusTypeEnum.active);
    });
  }

  onBlock() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Выбранные банковские продукты будут недоступны. Продолжить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStatus(this.bankStatusTypeEnum.block);
    });
  }

  onChangeData($e) {
    this.reload();
  }

}
