import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {RootAuthenticationService} from './root-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RootGuardService implements CanActivate  {

  constructor(
    private router: Router,
    private auth: RootAuthenticationService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentRoot = this.auth.currentRootValue;

    if (currentRoot) {
      // logged in so return true
      return true;
    }

    console.log('root guard', this.router.url);

    // not logged in so redirect to login page
    this.router.navigate(['/root/signin']);

    return false;
  }

}
