import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDateAdapter, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-banks-tabs',
  templateUrl: './tabs.component.html'
})
export class BanksTabsComponent implements OnInit {

  @Input() item: string;

  ngOnInit() {
  }

}
