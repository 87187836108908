import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PageSignInComponent} from '../../page-sign-in/page-sign-in.component';
import {Utils} from '../../../_helpers/utils';
import {Validators} from '@angular/forms';
import {timer} from 'rxjs';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent extends PageSignInComponent {

  password = false;
  loadingSms = false;
  disabledSms = true;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        login: [
          Utils.phoneFormat('+375 ' + this.phone), [
            Validators.required,
            Validators.pattern('^\\+375 \\d{2} \\d{3} \\d{2} \\d{2}$')
          ]]
      });

    // reset login status
    // this.auth.logout();
  }

  showPassword() {
    this.dataForm =
      this.formBuilder.group({
        login: [
          this.f.login.value, [
            Validators.required,
            Validators.pattern('^\\+375 \\d{2} \\d{3} \\d{2} \\d{2}$')
          ]],
        password: [
          null, [
            Validators.required
          ]]
      });

    this.password = true;

    const secondsCounter = timer(30000);
    secondsCounter.subscribe(n => {
      this.disabledSms = false;
    });
  }

  toRegister() {
    this.error = '';
    // https://www.npmjs.com/package/ngx-captcha
    this.reCaptchaV3Service.execute(this.siteKey, 'user/signup', (token) => {
      console.log('captcha token: ', token);
      this.auth.register(
        this.f.login.value,
        token
      )
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();
            this.showPassword();
            this.error = null;
          },
          err => {
            this.onStopSubmit();

            if (err.status === 406) {
              this.error = 'Пользователь уже зарегистрирован';
            } else {
              this.error = err.error;
            }
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (!this.password) {
      this.toRegister();
    } else {
      this.toLogin();
    }
  }

  onResendPass() {
    if (this.disabledSms) {
      return;
    }

    this.loadingSms = true;
    this.disabledSms = true;
    this.error = '';

    this.auth.resendPass(this.f.login.value)
      .subscribe(
        data => {
          this.loadingSms = false;

          const secondsCounter = timer(30000);
          secondsCounter.subscribe(n => {
            this.disabledSms = false;
          });
        },
        err => {
          this.loadingSms = false;
          this.disabledSms = false;
          this.error = err.error;
        }
      );
  }

}
