import {EventEmitter, Injectable, Input, OnInit, Output, Renderer2} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {NamedService} from '../_services/named.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../_services/user.service';
import {FormService} from '../_services/form.service';
import {AuthenticationService} from '../_services/authentication.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../_services/alert.service';
import {environment} from '../../environments/environment';
import {AdminService} from '../_services/admin.service';
import {AdminAuthenticationService} from '../_services/admin-authentication.service';
import {OrderService} from '../_services/order.service';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {RootAuthenticationService} from '../_services/root-authentication.service';
import {RootService} from '../_services/root.service';
import {Utils} from './utils';
import {ConfirmModalComponent} from '../parts/confirm-modal/confirm-modal.component';
import {ToastaService} from 'ngx-toasta';

@Injectable()
export class DataForm implements OnInit {

  dataForm: FormGroup;
  loading = false;
  error = '';
  success = false;

  debug: boolean = environment.debug;
  utils = Utils;

  isAddedValidators = true;
  validators = {};

  @Input() submitted = false;
  @Input() obj: any;

  @Output() onResponse = new EventEmitter();
  @Output() emitData = new EventEmitter();

  constructor(
    public namedService: NamedService,
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public auth: AuthenticationService,
    public adminAuth: AdminAuthenticationService,
    public rootAuth: RootAuthenticationService,
    public userService: UserService,
    public formService: FormService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public alertService: AlertService,
    public adminService: AdminService,
    public orderService: OrderService,
    public rootService: RootService,
    public reCaptchaV3Service: ReCaptchaV3Service,
    public renderer: Renderer2,
    public toastr: ToastaService
  ) {}

  ngOnInit() {
    this.initForm();

    if (this.dataForm) {
      this.subscribeForm();

      if (this.obj) {
        this.addItems('items');
        this.addItems('productOrders');
        this.dataForm.patchValue(this.obj);
      }
    }
  }

  addItems(field: string) {
    // console.log('addItems', field);
    // console.log(this.obj[field]);

    if (this.obj[field]) {
      let i = (this.dataForm.get(field) as FormArray).length ? 1 : 0;
      // console.log('i', i);
      // console.log('length', this.obj[field].length);

      for (; i < this.obj[field].length; i++) {
        (this.dataForm.get(field) as FormArray).push(
          this.createItem()
        );
      }
    }
  }

/*  dateValidator(control: FormControl): ValidationErrors {
    const value = control.value;
    /!** Проверка на содержание цифр *!/
    const hasNumber = /[0-9]/.test(value);
    /!** Проверка на содержание заглавных букв *!/
    const hasCapitalLetter = /[A-Z]/.test(value);
    /!** Проверка на содержание прописных букв *!/
    const hasLowercaseLetter = /[a-z]/.test(value);
    /!** Проверка на минимальную длину пароля *!/
    const isLengthValid = value ? value.length > 7 : false;

    /!** Общая проверка *!/
    const passwordValid = hasNumber && hasCapitalLetter && hasLowercaseLetter && isLengthValid;

    if (!passwordValid) {
      return { invalidDate: 'Пароль не прошел валидацию' };
    }
    return null;
  }*/

  get items() {
    return this.dataForm.get('items') as FormArray;
  }

  subscribeForm() {
    this.dataForm.valueChanges.subscribe(() => {

      if (!this.isAddedValidators) {
        this.isAddedValidators = true;
        Object.keys(this.dataForm.controls).forEach(field => {
          const control = this.dataForm.get(field);

          if (control instanceof FormControl) {
            this.updateControlValidators(field, control);
          } else if (control instanceof FormArray) {
/*            for (let i = 0; i < control.length; i++) {
              let formInner = control.at(i) as FormGroup;

              Object.keys(formInner.controls).forEach(fieldInner => {
                const controlInner = formInner.get(fieldInner) as FormControl;
                this.updateControlValidators(fieldInner, controlInner);
              });
            }*/
          }
        });
      }

      this.onResponse.emit({
        valid: this.dataForm.valid,
        data: this.dataForm.value
      });
    });
  }

  updateControlValidators(field: string, control: FormControl) {
    const validators = this.validators[field] ? this.validators[field] : [ Validators.required ];

    if (validators.length) {
      control.setValidators(validators);
      control.updateValueAndValidity();
    }
  }

  initForm(): void {
    console.error('Implements initForm');
  }

  createItem(): FormGroup {
    console.error('Implements createItem');
    return this.formBuilder.group({});
  }

  get f() {
    return this.dataForm.controls;
  }

  onStartSubmit(): boolean {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dataForm.invalid || this.loading) {
      return false;
    }

    this.loading = true;
    this.error = '';

    return true;
  }

  onStopSubmit() {
    this.submitted = false;
    this.loading = false;
  }

  onModalClosing() {
    // if (this.obj) {
    //   this.dataForm.value.id = this.obj.id;
    // }

    if (!this.obj || !Utils.isEquivalent(this.dataForm.value, this.obj)) {
      const modalRef = this.modalService.open(
        ConfirmModalComponent,
        { backdrop: 'static' }
      );
      modalRef.componentInstance.body = 'Введенные данные не будут сохранены. Вы уверены, что хотите продолжить?';
      modalRef.componentInstance.submit.subscribe(($e) => {
        this.onResponse.next('refresh');
        this.activeModal.close('Close click');
      });

      return;
    }

    this.onResponse.next('refresh');
    this.activeModal.close('Close click');
  }

}
