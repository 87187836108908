import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ReportsComponent} from '../stats/reports.component';
import {Order} from '../../../_business/order';
import {OrderStatusEnum} from '../../../_business/OrderStatus.enum';
import {Named} from '../../../_business/named';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {first} from 'rxjs/operators';


@Component({
  selector: 'app-reports',
  templateUrl: './reports-orders.component.html',
  styleUrls: ['./reports-orders.component.scss'],
  providers: [DatePipe]
})
export class ReportsOrdersComponent extends ReportsComponent implements OnInit {
  loading = true;
  error: string;
  orders: Order[];

  orderStatusEnum = OrderStatusEnum;
  orderStatuses: Named[];
  // bankNames: Named[];

  filterFio = '';
  filterPhone = '';
  filterApplicationNumber = '';

  orderStatusOptionsModel: number[];
  bankOptionsModel: number[];

  ngOnInit() {
    super.ngOnInit();
    this.getOrderStatuses();
    // this.getBankNames();
  }

  reloadReports() {
    if (this.dtOptions.ajax) {
      this.reload();
      return;
    }

    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        ajax: (dataTablesParameters: any, callback) => {
          dataTablesParameters.columns[4].search.value = this.filterPhone;
          dataTablesParameters.filterFio = this.filterFio;
          dataTablesParameters.filterApplicationNumber = this.filterApplicationNumber;

          dataTablesParameters = Object.assign(dataTablesParameters, this.filters);

          dataTablesParameters.filterFromDate = this.o.filterFromDate;
          dataTablesParameters.filterToDate = this.o.filterToDate;

          if (this.orderStatusOptionsModel && this.orderStatusOptionsModel.length) {
            dataTablesParameters.filterStatus = this.orderStatusOptionsModel.join(':');
          }

          // this.dataTablesParameters = dataTablesParameters;

          this.rootService.getOrders(dataTablesParameters, '/count')
            .pipe(first())
            .subscribe(countData => {
              console.log(countData);

              const count = countData['count'];

              this.rootService.getOrders(dataTablesParameters)
                .pipe(first())
                .subscribe(data => {
                  console.log(data);

                  this.orders = data;

                  const params = {
                    recordsTotal: count,
                    recordsFiltered: count,
                    data: []
                    // data: this.orders
                    // data: { data: this.orders }
                  };

                  console.log(params);

                  callback(params);
                });
            });
        },
        columns: [
          { data: 'id' },
          { data: 'applicationNumber' },
          { data: 'createDt' },
          { data: 'fullNameStr' },
          { data: 'phoneContact' },
          { data: 'statusId' },
          { data: 'term' },
          { data: 'totalPrice' },
          { data: 'creditAmount' },
          { data: 'bankId' },
          { data: 'storesId' }
        ]
      }
    );
  }

  getOrderStatuses(): void {
    this.namedService.getNamedList('orderstatus')
      .subscribe(orderStatuses => {
        this.orderStatuses = orderStatuses;
      });
  }

  getOrderStatusById(id: number) {
    if (this.orderStatuses) {
      for (let i = 0; i < this.orderStatuses.length; i++) {
        if (this.orderStatuses[i].id === id) {
          return this.orderStatuses[i];
        }
      }
    }

    return null;
  }

  // getBankNames(): void {
  //   this.namedService.getNamedList('bankpartner')
  //     .subscribe(bankNames => {
  //       this.bankNames = bankNames;
  //     });
  // }

  getBankById(id: number) {
    // if (this.bankNames) {
    //   for (let i = 0; i < this.bankNames.length; i++) {
    //     if (this.bankNames[i].id === id) {
    //       return this.bankNames[i];
    //     }
    //   }
    // }

    if (this.banks) {
      for (let i = 0; i < this.banks.length; i++) {
        if (this.banks[i].id === id) {
          return this.banks[i];
        }
      }
    }

    return null;
  }

  getStoreById(id: number) {
    if (this.stores) {
      for (let i = 0; i < this.stores.length; i++) {
        if (this.stores[i].id === id) {
          return this.stores[i];
        }
      }
    }

    return null;
  }

  onFilter($e) {
    console.log('onFilter', $e);
    this.reload();
  }

}
