import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() link: string;

  @Output() submit = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.activeModal.close('Close click')
    this.submit.emit();
  }

}
