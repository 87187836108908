export enum OrderStatusCodeEnum {
  new = 'new',
  review = 'pen',
  approve = 'apr',
  rejected = 'rej',
  finished = 'fin',
  canceled = 'ann',
  wrong = 'wro',
  opn = 'opn',
  wfc = 'wfc',
  dft = 'dft',
  snt = 'snt',
  rje = 'rje',
  apc = 'apc',
  ane = 'ane',
  wui = 'wui',
  wca = 'wca',
  wcp = 'wcp',
  wod = 'wod',
  wnp = 'wnp',
  won = 'won',
  wth = 'wth',
  prf = 'prf',
  dlv = 'dlv'
}

/*
[
  {
    "code": "new",
    "id": 0,
    "name": "Новая"
  },
  {
    "code": "pen",
    "id": 1,
    "name": "На рассмотрении"
  },
  {
    "code": "apr",
    "id": 2,
    "name": "Утверждена"
  },
  {
    "code": "rej",
    "id": 3,
    "name": "Отклонена"
  },
  {
    "code": "fin",
    "id": 4,
    "name": "Исполнена"
  },
  {
    "code": "ann",
    "id": 5,
    "name": "Аннулирована"
  },
  {
    "code": "wro",
    "id": 6,
    "name": "Требует уточнения данных"
  },
  {
    "code": "opn",
    "id": 7,
    "name": "Открытие договоров"
  },
  {
    "code": "wfc",
    "id": 8,
    "name": "В ожидании клиента"
  },
  {
    "code": "dft",
    "id": 9,
    "name": "Черновик"
  },
  {
    "code": "snt",
    "id": 10,
    "name": "Отправлена в банк"
  },
  {
    "code": "rje",
    "id": 11,
    "name": "Отклонена. Истек срок"
  },
  {
    "code": "apc",
    "id": 12,
    "name": "Утверждена с изменениями"
  },
  {
    "code": "ane",
    "id": 13,
    "name": "Аннулирована. Истек срок"
  },
  {
    "code": "wui",
    "id": 14,
    "name": "Требует уточнения данных. Личные данные"
  },
  {
    "code": "wca",
    "id": 15,
    "name": "Требует уточнения данных. Сумма кредита"
  },
  {
    "code": "wcp",
    "id": 16,
    "name": "Требует уточнения данных. Срок рассрочки"
  },
  {
    "code": "wod",
    "id": 17,
    "name": "Требует уточнения данных. Данные по заказу"
  },
  {
    "code": "wnp",
    "id": 18,
    "name": "Требует уточнения данных. Товар не в наличии"
  },
  {
    "code": "won",
    "id": 19,
    "name": "Требует уточнения данных. Номер заказа"
  },
  {
    "code": "wth",
    "id": 20,
    "name": "Отозвана магазином"
  },
  {
    "code": "win",
    "id": 26,
    "name": "Требует уточнения данных. Счет-фактура"
  },
  {
    "code": "wdp",
    "id": 27,
    "name": "Требует уточнения данных. Дублируемая заявка"
  },
  {
    "code": "wii",
    "id": 28,
    "name": "Требует уточнения данных. Неверен размер дохода"
  },
  {
    "code": "wsn",
    "id": 30,
    "name": "Требует уточнения данных. Наименование ОТС"
  },
  {
    "code": "wji",
    "id": 31,
    "name": "Требует уточнения данных. Неверные сведения о работе"
  },
  {
    "code": "rpl",
    "id": 32,
    "name": "Заменена"
  },
  {
    "code": "prf",
    "id": 33,
    "name": "Заполнение анкеты"
  },
  {
    "code": "dlv",
    "id": 34,
    "name": "Заказ доставлен"
  }
]
*/
