import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {OrderStatusEnum} from '../../_business/OrderStatus.enum';
import {Named} from '../../_business/named';
import {OrderService} from '../../_services/order.service';
import {NamedService} from '../../_services/named.service';
import {AdminAuthenticationService} from '../../_services/admin-authentication.service';
import {first} from 'rxjs/operators';
import {Order} from '../../_business/order';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {OrderModalComponent} from './order-modal/order-modal.component';
import {DataTable} from '../../_helpers/dataTable';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {calendar} from 'ngx-bootstrap/chronos/moment/calendar';
import {NgModel} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NgbUTCStringAdapter} from '../../_helpers/NgbUTCStringAdapter';
import {AdminService} from '../../_services/admin.service';
import {ToastaConfig, ToastaService} from 'ngx-toasta';
import {Utils} from '../../_helpers/utils';


const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? true : one.day > two.day : one.month > two.month : one.year > two.year;


@Component({
  selector: 'app-order',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent extends DataTable implements OnInit {

  exporting = false;
  dataTablesParameters = null;

  error: string;
  orders: Order[];
  debug: boolean = environment.debug;

  orderStatusEnum = OrderStatusEnum;
  orderStatuses: Named[];
  banks: Named[];

  filterFio = '';
  filterPhone = '';
  filterApplicationNumber = '';

  orderStatusOptionsModel: number[];
  bankOptionsModel: number[];

  storeData = {};

  amount = 0;

  // https://www.npmjs.com/package/angular-2-dropdown-multiselect
  // Settings configuration
  mySettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'glyphicon',
    buttonClasses: 'btn btn-sm btn-default',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: true
  };

// Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Выбрать все',
    uncheckAll: 'Убрать все',
    checked: 'выбран',
    checkedPlural: 'выбраны',
    searchPlaceholder: 'Найти',
    searchEmptyResult: 'Пусто...',
    searchNoRenderText: 'Введите текст...',
    defaultTitle: 'Выбрать',
    allSelected: 'Все выбраны',
  };

  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  model: any;
  private _subscription: Subscription;
  private _selectSubscription: Subscription;
  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  isHovered = date =>
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);


  constructor(
    private orderService: OrderService,
    private namedService: NamedService,
    private adminService: AdminService,
    private auth: AdminAuthenticationService,
    private modalService: NgbModal,
    // calendar: NgbCalendar,

    element: ElementRef,
    private renderer: Renderer2,
    private _parserFormatter: NgbDateParserFormatter,
    private _adapter: NgbDateAdapter<any>,

    public toastr: ToastaService,
    private toastaConfig: ToastaConfig
  ) {
    super();

    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.showClose = false;

    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    this.getOrderStatuses();
    this.getBanks();

    this.adminService.getStoreInfo()
      .subscribe(data => {
        this.storeData = data;
      });

    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        ajax: (dataTablesParameters: any, callback) => {
          dataTablesParameters.columns[4].search.value = this.filterPhone;
          dataTablesParameters.filterFio = this.filterFio;
          dataTablesParameters.filterApplicationNumber = this.filterApplicationNumber;

          if (this.fromDate) {
            dataTablesParameters.filterFromDate = this._adapter.toModel(this.fromDate).split('T')[0];
          }

          if (this.toDate) {
            dataTablesParameters.filterToDate = this._adapter.toModel(this.toDate).split('T')[0];
          }

          if (this.orderStatusOptionsModel && this.orderStatusOptionsModel.length) {
            dataTablesParameters.filterStatus = this.orderStatusOptionsModel.join(':');
          }

          if (this.bankOptionsModel && this.bankOptionsModel.length) {
            dataTablesParameters.filterBank = this.bankOptionsModel.join(':');
          }

          this.dataTablesParameters = dataTablesParameters;
          this.amount = 0;

          this.orderService.getAll(dataTablesParameters, '/count')
            .pipe(first())
            .subscribe(countData => {
              console.log(countData);

              const count = countData['count'];

              this.orderService.getAll(dataTablesParameters)
                .pipe(first())
                .subscribe(data => {
                  console.log(data);

                  this.orders = data;

                  this.orderService.getAll(dataTablesParameters, '/amount')
                    .pipe(first())
                    .subscribe(dataInner => {
                      console.log(dataInner);
                      this.amount = dataInner['amount'];
                    });

                  const params = {
                    recordsTotal: count,
                    recordsFiltered: count,
                    data: []
                    // data: this.orders
                    // data: { data: this.orders }
                  };

                  console.log(params);

                  callback(params);
                });
            });
        },
        columns: [
          { data: 'id' },
          { data: 'applicationNumber' },
          { data: 'createDt' },
          { data: 'fullNameStr' },
          { data: 'phoneContact' },
          { data: 'statusId' },
          { data: 'term' },
          { data: 'totalPrice' },
          { data: 'creditAmount' },
          { data: 'bankId' }
        ]
      }
    );

    this.startDate = {year: now.getFullYear(), month: now.getMonth() - 1, day: now.getDate()};
    this.maxDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.minDate = {year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate()};
  }

/*  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');

    super.ngAfterViewInit();

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        console.log(this.header());

        $('input', this.header()).on('keyup change', function () {
          console.log('keyup change');

          const near = $(this).siblings();

          let s = $(this).data('sign') + ':' + this['value'];

          if (near.length && near.val()) {
            s += ';' + near.data('sign') + ':' + near.val();
          }

          if (that.search() !== s) {
            that
              .search(s)
              .draw();
          }
        });
      });
    });
  }*/

  getOrderStatuses(): void {
    this.namedService.getNamedList('orderstatus')
      .subscribe(orderStatuses => {
        this.orderStatuses = orderStatuses;
      });
  }

  getOrderStatusById(id: number) {
    if (this.orderStatuses) {
      for (let i = 0; i < this.orderStatuses.length; i++) {
        if (this.orderStatuses[i].id === id) {
          return this.orderStatuses[i];
        }
      }
    }

    return null;
  }

  getBanks(): void {
    this.namedService.getBankPartners()
      .subscribe(data => {
        this.banks = data;
      });

    // this.namedService.getNamedList('bankpartner')
    //   .subscribe(bankNames => {
    //     this.bankNames = bankNames;
    //   });
  }

  getBankById(id: number) {
    if (this.banks) {
      for (let i = 0; i < this.banks.length; i++) {
        if (this.banks[i].id === id) {
          return this.banks[i];
        }
      }
    }

    return null;
  }

  renderOrder() {
    const modalRef = this.modalService.open(
      OrderModalComponent,
      { size: 'lg', backdrop: 'static' }
    );

    modalRef.componentInstance.storeData = this.storeData;

    modalRef.componentInstance.emitData.subscribe(($e, $data) => {
      console.log('e', $e);
      this.reload();

      const cmd = $e.command || $e;

      switch (cmd) {
        case 'issue':
          this.toastr.success('Заказ успешно сформирован');
          break;

        case 'cancel':
          this.toastr.success('Заказ успешно отменён');
          break;

        case 'delete':
          this.toastr.success('Заказ успешно удалён');
          break;

        case 'recall':
          this.toastr.success('Заказ успешно отозван');
          break;

        case 'restore':
          this.toastr.success('Заказ успешно восстановлен');
          break;

        case 'resend':
          this.toastr.success('Заказ успешно переотправлен');
          break;

        default:
          if ($e !== 'refresh') {
            this.toastr.success('Заказ успешно сохранён');
          }

          break;
      }

      if ($e.id) {
        this.onShowPop($e.id);
      }
    });

    // modalRef.result.then(() => {
    //   return false;
    // }, () => {
    //   return false;
    // });

    return modalRef;
  }

  onShowPop(orderId) {
    let modalRef = null;

    if (orderId) {
      // modalRef.componentInstance.orderId = orderId;

      this.error = '';
      this.orderService.getById(orderId)
        .pipe(first())
        .subscribe(
          data => {
            modalRef = this.renderOrder();
            modalRef.componentInstance.obj = data as any;
            modalRef.componentInstance.obj.status = this.getOrderStatusById(modalRef.componentInstance.obj.statusId);
            // modalRef.componentInstance.obj.bankPartnerId = 7;
            console.log(modalRef.componentInstance.obj);
          },
          err => {
            this.error = err.error;
            console.error(this.error);
          });
    } else {
      modalRef = this.renderOrder();
    }
  }

  onChange($e) {
    this.reload();
  }

  onDateSelection(date: NgbDateStruct) {
    console.log('onDateSelection', date);

    let parsed = '';
    let toReload = false;

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      toReload = true;
      this.toDate = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate) {
      parsed += this._parserFormatter.format(this.fromDate);
    }
    if (this.toDate) {
      parsed += ' - ' + this._parserFormatter.format(this.toDate);
    }

    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);

    if (toReload) {
      this.reload();
    }
  }

  onClearDates() {
    this.fromDate = null;
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', '');

    if (this.toDate) {
      this.toDate = null;
      this.reload();
    }
  }

  closeFix(event, datePicker) {
    console.log(event.target.offsetParent);

    if (
      event.target.offsetParent &&
      event.target.offsetParent.nodeName !== 'NGB-DATEPICKER' &&
      !jQuery(event.target.offsetParent).hasClass('ng-cal')
    ) {
      datePicker.close();

      if (this.fromDate && !this.toDate) {
        this.onDateSelection(this.fromDate);
      }
    }
  }


  onExport() {
    if (this.exporting) {
      return;
    }

    this.exporting = true;
    this.error = '';

    this.orderService.getAllExport(this.dataTablesParameters)
      .pipe(first())
      .subscribe(data => {
          console.log(data);
          this.exporting = false;
          Utils.export(data);
        },
        err => {
          this.exporting = false;
          this.error = err.error;
        });
  }
}
