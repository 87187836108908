export enum CoopTermCodeEnum {
  clsc  = 'clsc',
  auto   = 'auto',
  mixd   = 'mixd'
}

/*
[
  {"code":"clsc","id":1,"name":"Классическая схема"},
  {"code":"auto","id":2,"name":"Автоматизация"},
  {"code":"mixd","id":3,"name":"Смешанная схема"}
]
*/
