import {AbstractControl, FormControl, ValidationErrors} from '@angular/forms';
export class PasswordValidation {

  static matchPassword(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag

    if (password != confirmPassword) {
      AC.get('confirmPassword').setErrors({ matchPassword: true })
    } else {
      return null
    }
  }

/*  private passwordValidator(control: FormControl): ValidationErrors {
    const value = control.value;
    /!** Проверка на содержание цифр *!/
    const hasNumber = /[0-9]/.test(value);
    /!** Проверка на содержание заглавных букв *!/
    const hasCapitalLetter = /[A-Z]/.test(value);
    /!** Проверка на содержание прописных букв *!/
    const hasLowercaseLetter = /[a-z]/.test(value);
    /!** Проверка на минимальную длину пароля *!/
    const isLengthValid = value ? value.length > 7 : false;

    /!** Общая проверка *!/
    const passwordValid = hasNumber && hasCapitalLetter && hasLowercaseLetter && isLengthValid;

    if (!passwordValid) {
      return { invalidPassword: 'Пароль не прошел валидацию' };
    }
    return null;
  }*/

}
