import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {ProductTypeEnum} from '../../../../_business/ProductType.enum';
import {BankStatusTypeEnum} from '../../../../_business/BankStatusType.enum';
import {Utils} from '../../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../../parts/confirm-modal/confirm-modal.component';
import {StoreStatusCodeEnum} from '../../../../_business/StoreStatusCode.enum';

declare var jQuery: any;

@Component({
  selector: 'app-store-pop-modal',
  templateUrl: './store-pop-modal.component.html'
})
export class StorePopModalComponent extends DataForm implements OnInit {

  @Input() storePartnerId;

  categories = [];

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'name': [
          '', [Validators.required]
        ],
        'url': [
          [], [Validators.required]
        ],
        'logo': [
          ''
        ],
        'phone': [
          '', [Validators.required]
        ],
        'phoneStat': '',
        'categories': [
          [], [Validators.required]
        ],
        'outEmailAddr': [
          '', [Validators.required, Validators.email]
        ],
        'show': 1
      });
  }

  ngOnInit() {
    super.ngOnInit();

    setTimeout(function () {
      jQuery('select.sp.custom').selectpicker({
        actionsBox: true,
        countSelectedText: 1,
        deselectAllText: 'Убрать все',
        selectAllText: 'Выбрать все',
        noneResultsText: 'Не найдено {0}',
        // allSelectedText: 'All',
        // maxHeight: 200,
      });
    });

    if (this.obj) {
      this.rootService.getPartnerStores({
        id: this.obj.id
      })
        .pipe(first())
        .subscribe(
          data => {
            this.dataForm.patchValue(data);
            // this.dataForm.get('createDt').setValue(this.obj.createDt,{emitEvent: false});
          },
          err => {
            this.error = err.error;
          }
        );

      this.rootService.getCategories(null)
        .pipe(first())
        .subscribe(
          data => {
            this.categories = data;

            this.rootService.getPartnerStoreCategories(this.obj.id)
              .subscribe(storeCategories => {
                setTimeout(function () {
                  jQuery('#selectCategories').selectpicker('refresh');
                });

                // FIXME: selected values
                const select = [];
                const categories = [];

                for (let i = 0; i < storeCategories.length; i++) {
                  categories.push(storeCategories[i].id);
                  for (let j = 0; j < this.categories.length; j++) {
                    if (+storeCategories[i].id === +this.categories[j].id) {
                      select.push(j + ': \'' + storeCategories[i].id + '\'');
                      break;
                    }
                  }
                }

                this.dataForm.get('categories').setValue(categories, {emitEvent: false});

                console.log('select', select);

                setTimeout(function () {
                  jQuery('#selectCategories').selectpicker('val', select);
                });
              });
          },
          err => {
            this.error = err.error;
          }
        );
    } else {
      this.rootService.getCategories(null)
        .pipe(first())
        .subscribe(
          data => {
            this.categories = data;

            setTimeout(function () {
              jQuery('#selectCategories').selectpicker('refresh');
            });
          },
          err => {
            this.error = err.error;
          }
        );
    }
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    this.dataForm.value.storePartnerId = this.storePartnerId;

    if (this.dataForm.value.show) {
      this.dataForm.value.show = '1';
    } else {
      this.dataForm.value.show = '0';
    }

    const categories = [];
    const storeCategories = this.dataForm.value.categories;
    delete this.dataForm.value.categories;

    for (let i = 0; i < storeCategories.length; i++) {
      for (let j = 0; j < this.categories.length; j++) {
        if (+storeCategories[i] === +this.categories[j].id) {
          categories.push(this.categories[j]);
          break;
        }
      }
    }

    const servicePartnerStore =
      this.obj
        ? this.rootService.savePartnerStore(this.dataForm.value)
        : this.rootService.createPartnerStore(this.dataForm.value);

    servicePartnerStore
      .pipe(first())
      .subscribe(
        data => {
          this.rootService.setPartnerStoreCategories(data.id, categories)
            .pipe(first())
            .subscribe(
              innerData => {
                this.onStopSubmit();
                this.emitData.next({
                  action: this.obj ? 'save' : 'create',
                  obj: data
                });
                this.activeModal.close('Close click');
              },
              err => {
                this.onStopSubmit();
                this.error = err.error;
              }
            );
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onDelete() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.loading = true;
      this.rootService.deletePartnerStore(this.obj.id)
        .pipe(first())
        .subscribe(
          data => {
            this.emitData.next({
              action: 'delete',
              obj: this.obj
            });
            this.activeModal.close('Close click');
          },
          err => {
            this.error = err.error;
            this.loading = false;
          }
        );
    });
  }

}
