import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { User } from '../_business/user';
import { environment } from '../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {map} from 'rxjs/operators';


@Injectable()
export class UserService {

  loggedMember: User;

  private sectionMap = new Map();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.initSectionMap();
    this.loggedMember = this.authService.currentUserValue;
  }

  private initSectionMap(): void {
    this.sectionMap.set('userform', 'com.zabirai.userform');
    this.sectionMap.set('user', 'com.zabirai.user');
  }

  postPersonalData(data: any, code: string) {
    const headers = new HttpHeaders({
      'Confirm-code': code
    });

    return this.http.post(
      `${environment.api}${this.sectionMap.get('userform')}`,
      data,
      { headers: headers }
    );
  }

  getPersonalData() {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('userform')}`
    );
  }

  sendCode() {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('userform')}/sendCode`
    );
  }

  requestDelete() {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('user')}/requestDelete`
    );
  }

  logout() {
    return this.http.delete(
      `${environment.api}${this.sectionMap.get('user')}/logout`
    );
  }

  delete(code: string) {
    const headers = new HttpHeaders({
      'Confirm-code': code
    });

    return this.http.delete(
      `${environment.api}${this.sectionMap.get('user')}`,
      { headers: headers }
    );
  }

  getInfoFromOrder() {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('userform')}/fromOrder`
    );
  }

  sendEmail(email: string, message: string, token: string) {
    const headers = new HttpHeaders({
      'g-recaptcha-response': token
    });

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('user')}/usermsg`,
      {
        replyEmail: email,
        text: message
      },
      { headers: headers, observe: 'response' }
    );
  }

}
