import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './orders/orders.component';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AdminRoutingModule} from './admin-routing.module';
import {AdminService} from '../_services/admin.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SettingsComponent } from './settings/settings.component';
import { AdminHeaderComponent } from './parts/header/header.component';
import { AdminFooterComponent } from './parts/footer/footer.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { OrderModalComponent } from './orders/order-modal/order-modal.component';
import {DataTablesModule} from 'angular-datatables';
import { OptionsComponent } from './options/options.component';
import {OrderService} from '../_services/order.service';
import { DimsComponent } from './dims/dims.component';
import { AccessComponent } from './access/access.component';
import { DimModalComponent } from './dims/dim-modal/dim-modal.component';
import { OptionModalComponent } from './options/option-modal/option-modal.component';
import { AccessModalComponent } from './access/access-modal/access-modal.component';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {LibraryModule} from '../library/library.module';
import {FocusFirstInvalidDirective} from '../_helpers/focusFirstInvalid.directive';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ResendBankModalComponent} from './orders/resend-bank-modal/resend-bank-modal.component';
import {ReturnRequisitesModalComponent} from './orders/order-modal/return-requisites-modal/return-requisites-modal.component';

@NgModule({
  declarations: [
    OrdersComponent,
    SettingsComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
    SignInComponent,
    OrderModalComponent,
    ResendBankModalComponent,
    OptionsComponent,
    DimsComponent,
    AccessComponent,
    DimModalComponent,
    OptionModalComponent,
    AccessModalComponent,
    FocusFirstInvalidDirective,
    ReturnRequisitesModalComponent
  ],
  imports: [
    LibraryModule,
    AdminRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DataTablesModule,
    MultiselectDropdownModule,
    BrowserAnimationsModule
  ],
  exports: [
    AdminHeaderComponent,
    AdminFooterComponent
  ],
  providers: [
    Title,
    AdminService,
    OrderService
  ],
  entryComponents: [
    OrderModalComponent,
    ResendBankModalComponent,
    DimModalComponent,
    OptionModalComponent,
    AccessModalComponent,
    ReturnRequisitesModalComponent
  ]
})
export class AdminModule { }
