import { Component, OnInit } from '@angular/core';
import {DataTable} from '../../_helpers/dataTable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RootService} from '../../_services/root.service';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {ShopModalComponent} from './shop-modal/shop-modal.component';
import {RootAuthenticationService} from '../../_services/root-authentication.service';
import {NamedService} from '../../_services/named.service';
import {Named} from '../../_business/named';
import {StoreStatusCodeEnum} from '../../_business/StoreStatusCode.enum';
import {ToastaService} from 'ngx-toasta';
import {UserModalComponent} from '../users/user-modal/user-modal.component';
import {UserRoleCodeEnum} from '../../_business/UserRoleCode.enum';
import {UserStatusCodeEnum} from '../../_business/UserStatusCode.enum';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html'
})
export class ShopsComponent extends DataTable implements OnInit {

  storeStatusList: Named[];
  storeStatusCodeEnum = StoreStatusCodeEnum;

  coopTermList;
  channelList;
  roleList;
  userStatusList;
  userList: any[];

  productTypes: Named[];

  filterID = '';
  filterUnp = '';
  filterName = '';
  filterStores = '';
  filterProducts = '';
  filterManager = '';

  statusOptionsModel: number[];
  // managerOptionsModel: number[];

  constructor(
    private modalService: NgbModal,
    private rootService: RootService,
    private toastr: ToastaService,
    private namedService: NamedService,
    public auth: RootAuthenticationService,
  ) {
    super();
  }

  ngOnInit() {
    this.namedService.getNamedList('storestatus')
      .subscribe(data => this.storeStatusList = data);

    this.namedService.getNamedList('coopterms')
      .subscribe(data => this.coopTermList = data);

    this.namedService.getNamedList('commchannel')
      .subscribe(data => this.channelList = data);

    this.namedService.getNamedList('role')
      .subscribe(data => {
        this.roleList = data;

        this.namedService.getNamedList('userstatus')
          .subscribe(dataInner => {
            this.userStatusList = dataInner;

            this.rootService.getUsers({
              columns: [{ data: 'fullNameStr' }],
              order: [{column: 0, dir: 'asc'}],
              filterRole: this.utils.getObjFromList(this.roleList, UserRoleCodeEnum.manager, 'code').id,
              filterStatus: this.utils.getObjFromList(this.userStatusList, UserStatusCodeEnum.active, 'code').id
            })
              .pipe(first())
              .subscribe(dataList => {
                  this.userList = dataList;

                  for (let x = 0; x < this.userList.length; x++) {
                    const obj = this.userList[x];
                    obj.name = obj.lastName + ' ' + obj.firstName;
                  }
                },
                err => {
                  this.error = err.error;
                }
              );
          });
      });

    this.namedService.getNamedList('bpproducts')
      .subscribe(data => this.productTypes = data);

    const columns = [
      { data: 'id' },
      { data: 'unp' },
      { data: 'name' },
      { data: 'stores' },
      { data: 'statusId' },
      { data: 'products' },
      { data: 'manager' }
    ];

    if (this.auth.hasRight('%com.zabiray.store.partners', 'delete')) {
      columns.unshift({ data: '' });
    }

    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        columns: columns,
        order: [[1, 'desc']]
      }
    );

    this.loadData();
  }

  loadData() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      dataTablesParameters.filterID = this.filterID;
      dataTablesParameters.filterUnp = this.filterUnp;
      dataTablesParameters.filterName = this.filterName;
      dataTablesParameters.filterStores = this.filterStores;
      dataTablesParameters.filterProducts = this.filterProducts;
      dataTablesParameters.filterManager = this.filterManager;

      if (this.statusOptionsModel && this.statusOptionsModel.length) {
        dataTablesParameters.filterStatus = this.statusOptionsModel.join(':');
      }

      // if (this.managerOptionsModel && this.managerOptionsModel.length) {
      //   dataTablesParameters.filterManager = this.managerOptionsModel.join(':');
      // }

      this.rootService.getStorePartners(dataTablesParameters, '/count')
        .pipe(first())
        .subscribe(countData => {
            console.log(countData);
            const count = countData['count'];

            this.rootService.getStorePartners(dataTablesParameters)
              .pipe(first())
              .subscribe(data => {
                  if (data.length > dataTablesParameters.length) {
                    data.pop();
                  }

                  this.dataList = data;

                  callback({
                    recordsTotal: count,
                    recordsFiltered: count,
                    // recordsTotal: dataTablesParameters.start + count,
                    // recordsFiltered: this.dataList.length,
                    data: { data: this.dataList }
                  });
                },
                err => {
                  this.error = err.error;
                }
              );
          },
          err => {
            this.error = err.error;
          }
        );
    };
  }

  onShowPop(obj = null) {
    if (!this.auth.hasRight('%com.zabiray.store.partners', 'post')) {
      return false;
    }

/*    if (!this.coopTermList) {
      this.namedService.getNamedList('coopterms')
        .subscribe(data => this.coopTermList = data);
    }

    if (!this.channelList) {
      this.namedService.getNamedList('commchannel')
        .subscribe(data => this.channelList = data);
    }

    if (!this.roleList) {
      this.namedService.getNamedList('role')
        .subscribe(data => this.roleList = data);
    }

    if (!this.userStatusList) {
      this.namedService.getNamedList('userstatus')
        .subscribe(data => this.userStatusList = data);
    }*/

    const modalRef = this.modalService.open(
      ShopModalComponent,
      { size: 'lg', backdrop: 'static' }
    );

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.storeStatusList = this.storeStatusList;

    modalRef.componentInstance.coopTermList = this.coopTermList;
    modalRef.componentInstance.channelList = this.channelList;
    modalRef.componentInstance.roleList = this.roleList;
    modalRef.componentInstance.userStatusList = this.userStatusList;
    modalRef.componentInstance.productTypes = this.productTypes;
    modalRef.componentInstance.userList = this.userList;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      switch ($e.action || $e) {
        case 'save':
          this.reload();
          this.toastr.success('Данные успешно сохранены');
          break;

        // case 'register':
        //   // $e.obj
        //   this.toastr.success('Магазин-партнер зарегистрирован');
        //   break;

        default:
          this.reload();
          break;
      }
    });

    modalRef.componentInstance.onResponse.subscribe(($e) => {
      switch ($e.action || $e) {
        case 'refresh':
          this.reload();
          break;
      }
    });
  }

  toChangeStoreStatus(statusCode: StoreStatusCodeEnum, storePartnerId: number = null) {
    const status = this.utils.getObjFromList(this.storeStatusList, statusCode, 'code');
    this.error = '';

    const id = this.selectedList.shift();

    this.rootService.saveStorePartnerStatus(id, status.id)
      .pipe(first())
      .subscribe(
        data => {
          if (this.selectedList.length) {
            this.toChangeStoreStatus(statusCode);
          } else {
            this.reload();
            let notice = null;

            switch (status.code) {
              case this.storeStatusCodeEnum.block:
                notice = 'Выбранные магазины-партнеры заблокированы';
                break;

              case this.storeStatusCodeEnum.create:
                notice = 'Выбранные магазины-партнеры восстановлены';
                break;

              case this.storeStatusCodeEnum.delete:
                notice = 'Выбранные магазины-партнеры удалены';
                break;
            }

            if (notice) {
              this.toastr.success(notice);
            }
          }
        },
        err => {
          this.error = err.error;
        }
      );
  }

  onBlock() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите заблокировать?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStoreStatus(this.storeStatusCodeEnum.block);
    });
  }

  onDelete() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStoreStatus(this.storeStatusCodeEnum.delete);
    });
  }

  onRestore() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите восстановить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStoreStatus(this.storeStatusCodeEnum.create);
    });
  }

  onFilter($e) {
    console.log('onChange', $e);
    this.reload();
  }

}
