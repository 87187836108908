import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {Utils} from '../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html'
})
export class CategoryModalComponent extends DataForm {

  @Input() imgPath: string;
  @Output() emitData = new EventEmitter();

  imgPathHover = 'hover';

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'name': [
          null, [Validators.required]
        ],
        'position': [
          null, [Validators.required]
        ],
        'iconUrl': [
          null, [Validators.required]
        ],
        'hoverUrl': [
          null, [Validators.required]
        ]
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    const serviceCategory =
      this.obj
        ? this.rootService.saveCategory(this.dataForm.value)
        : this.rootService.createCategory(this.dataForm.value);

    serviceCategory
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next({action: 'save', obj: this.dataForm.value});
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onLoadFile(files: FileList, hover = false) {
    this.error = '';

    const max = 2;
    const fileToUpload: File = files.item(0);

    if (fileToUpload.size > max * 1048576) {
      this.error = 'Файл не должен превышать ' + max + 'Mb';
      return;
    }

    this.loading = true;

    const serviceCategoryImg =
      hover
        ? this.rootService.setCategoryImg(fileToUpload, this.imgPathHover)
        : this.rootService.setCategoryImg(fileToUpload);

    serviceCategoryImg
      .pipe(first())
      .subscribe(data => {
          this.loading = false;

          if (hover) {
            this.dataForm.get('hoverUrl').setValue(
              this.imgPathHover + '/' + fileToUpload.name,
              {emitEvent: false});
          } else {
            this.dataForm.get('iconUrl').setValue(
              fileToUpload.name,
              {emitEvent: false});
          }
        },
        err => {
          this.loading = false;
          this.error = err.error;
        });
  }

}
