import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import {AdminAuthenticationService} from '../_services/admin-authentication.service';
import {RootAuthenticationService} from '../_services/root-authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private authAdminService: AdminAuthenticationService,
    private authRootService: RootAuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log('ErrorInterceptor', err);

      let errorEntity;

      if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
        // https://github.com/angular/angular/issues/19888
        // When request of type Blob, the error is also in Blob instead of object of the json data
        return new Promise<any>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e: Event) => {
            try {
              const newerr = Object.assign(err, { error: JSON.parse((<any>e.target).result) });
              errorEntity = {
                error: this.selectError(newerr),
                status: err.status
              };
              console.error(errorEntity);
              reject(new HttpErrorResponse(errorEntity));
            } catch (e) {
              reject(err);
            }
          };
          reader.onerror = (e) => {
            reject(err);
          };
          reader.readAsText(err.error);
        });
      }

      errorEntity = { error: this.selectError(err), status: err.status };
      console.error(errorEntity);

      return throwError(errorEntity );
    }));
  }

  selectError(err): string {
    let error = err.statusText || err.message;

    switch (err.status) {
      case 400:
        error = 'Ошибка параметров запроса';
        break;

      case 401: // Необходима авторизация, в запросах отсутствует токен
      case 498: // Истекший или невалидный токен
                // auto logout if 401 response returned from api
        if (this.authenticationService.currentUserValue) {
          this.authenticationService.logout();
          location.reload(true);
        }

        if (this.authAdminService.currentAdminValue) {
          this.authAdminService.logout();
          location.reload(true);
        }

        if (this.authRootService.currentRootValue) {
          this.authRootService.logout();
          location.reload(true);
        }

        break;

      case 403:
        error = 'Неправильный ввод данных';
        break;

      case 404:
        error = 'Объект не найден';
        break;

      case 405:
        error = 'Выполнение метода не разрешено на основании определенных критериев';
        break;

      case 406:
        error = 'Объект уже существует';
        break;

      case 409:
        error = 'Метод не может быть выполнен из-за нарушения последовательности действий или состояния объектов';
        break;

      case 415:
        error = 'Некорретный формат медиаданных, неверный формат ссылки, изображения и т.п.';
        break;

      case 501:
        error = 'Метод или сервис не поддерживается на сервере';
        break;

      case 503:
        error = 'Служба временно недоступна';
        break;

      default:
        error = 'Неожиданная ошибка';
        break;
    }

    if (err.error) {
      if (err.error.error) {
        error = '#' + err.error.error + ' ' + err.error.errorText;
      } else if (typeof(err.error) === 'string') {
        const ptxt = '<b>message</b>';
        const p1 = err.error.indexOf(ptxt);
        const p2 = err.error.indexOf('</p>', p1 + ptxt.length);

        if (p1 > -1 && p2 > 0) {
          const e = err.error.substring(p1 + ptxt.length, p2);

          if (e) {
            error = e;
          }
        }
      }
    }

    return error;
  }
}
