import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../_business/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthenticationService {

  private sectionMap = new Map();

  private currentAdminSubject: BehaviorSubject<User>;
  public currentAdmin: Observable<User>;

  constructor(
    private http: HttpClient
  ) {
    this.setCredentials();
    this.initSectionMap();
  }

  private setCredentials() {
    this.currentAdminSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentAdmin')));
    this.currentAdmin = this.currentAdminSubject.asObservable();
  }

  public get currentAdminValue(): User {
    return this.currentAdminSubject.value;
  }

  private initSectionMap(): void {
    this.sectionMap.set('auth', 'com.zabirai.storeoffice/auth');
    this.sectionMap.set('update-pass', 'com.zabirai.user/updatePass');
    // this.sectionMap.set('request-forget-pass', 'com.zabirai.admin.user/forgetpsw');
  }

  login(login: string, password: string, token: string) {
    // login = login.replace(/[ \+]/g, '');
    // console.log(login + ':' + password);

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + Base64.encode(login + ':' + password),
      'Content-Type': 'application/x-www-form-urlencoded',
      'g-recaptcha-response': token
    });

    /*    httpOptions.params =
          new HttpParams()
            .set('login', user.login)
            .set('password', user.password);*/

    return this.http.get<User>(
      environment.api + this.sectionMap.get('auth'),
      { headers: headers, observe: 'response' }
    )
      .pipe(map(data => {
        console.log('data', data);

        // login successful
        if (data && data.body as User) {
          data.body.token = data.headers.get('Token-key');

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentAdmin', JSON.stringify(data.body));
          // this.currentUserSubject.next(data.body);
          this.setCredentials();
        }

        return data.body;
      }));
  }

  changePassword(oldPassword: string, newPassword: string) {
    const login = this.currentAdminValue.login;
    console.log(login + ':' + oldPassword);

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + Base64.encode(login + ':' + oldPassword)
    });

    // const token = this.currentUserValue.token;
    // this.currentUserValue.token = null;

    return this.http.put<User>(
      environment.api + this.sectionMap.get('update-pass'),
      { newPassword: newPassword },
      { headers: headers, observe: 'response' }
    )
      .pipe(map(data => {
        console.log('data', data);

        if (data.headers.has('Token-key')) {
          console.log('token', data.headers.get('Token-key'));
          this.currentAdminValue.token = data.headers.get('Token-key');

          localStorage.setItem('currentUser', JSON.stringify(this.currentAdminValue));
        }

        return data.body;
      }));
  }

/*  requestForgetPass(login: string, token: string) {
    login = login.replace(/[ \+]/g, '');

    const headers = new HttpHeaders({
      'g-recaptcha-response': token
    });

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('request-forget-pass')}/?login=${login}`,
      {
        login: login
      },
      { headers: headers, observe: 'response' }
    );
  }

  resendPass(login: string, code: string = null): any {
    login = login.replace(/[ \+]/g, '');

    let headers = new HttpHeaders();

    // В header добавляем Confirm-code
    // (логика следующая, если Confirm-code отсутствует,
    // пароль будет выслан только пользователю, который еще не авторизовался в системе)

    if (code) {
      headers = new HttpHeaders({
        'Confirm-code': code
      });
    }

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('request-forget-pass')}/?login=${login}&code=${code}`,
      { login: login },
      { headers: headers, observe: 'response' }
    );
  }*/

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentAdmin');
    this.currentAdminSubject.next(null);
  }

}
