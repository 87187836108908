import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate  {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      // e  - не заполнена
      // f  - заполнена
/*      if (
        currentUser.userformStatusCode == 'e' // анкета не заполнена
        //&& this.router.url != '/' && this.router.url != '/signin' // поможет ли это от циклических редиректов?
      ) {
        console.log('auth 1', this.router.url);
        this.router.navigate(['/signup']);
        return false;
      }*/

      // logged in so return true
      return true;
    }

    console.log('auth 2', this.router.url);

    // not logged in so redirect to login page
    this.router.navigate(['/signin']);

    return false;
  }

}
