import {Component, OnInit} from '@angular/core';
import {UserDataComponent} from '../../user-data.component';

@Component({
  selector: 'app-skin-inframe',
  templateUrl: './skin-inframe.component.html',
  styleUrls: ['./../../user-data.component.scss']
})
export class SkinInframeComponent extends UserDataComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();

    if (this.debug) {
      this.inframe = 'https://www.youtube.com/embed/7kjX0lQgRFY';
    } else {
      this.inframe = this.bankResponse.url;

      // this.inframe = localStorage.getItem('inframe');
      // localStorage.removeItem('inframe');
    }

    this.movie = null;
    this.renderer.addClass(document.body, 'inframe');

    if (this.bankId === this.bankEnum.idea) {
      this.renderer.addClass(document.body, 'idea');
    }
  }

}
