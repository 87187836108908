import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {Named} from '../../../_business/named';

@Component({
  selector: 'app-credit-modal',
  templateUrl: './credit-modal.component.html',
  styleUrls: ['./credit-modal.component.scss']
})
export class CreditModalComponent extends DataForm {

  @Input() banks: Named[];
  @Output() emitData = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        dimBankId: [
          null, [
            Validators.required
          ]],
        monthAmount: [
          null, [
            Validators.required
          ]]
      });
  }

  onCreateCreditSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.emitData.next(this.dataForm);
    this.activeModal.close('Close click');
  }

}
