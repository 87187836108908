import { Component, OnInit } from '@angular/core';
import {DataForm} from "../../../_helpers/dataForm";
import {Named} from "../../../_business/named";

@Component({
  selector: 'app-family-status',
  templateUrl: './family-status.component.html',
  styleUrls: ['./family-status.component.scss']
})
export class FamilyStatusComponent extends DataForm implements OnInit {

  marriages: Named[];

  isAddedValidators = false;

  ngOnInit() {
    this.getMarriages();
    super.ngOnInit();
  }

  getMarriages(): void {
    this.namedService.getNamedList('marriage')
      .subscribe(marriages => this.marriages = marriages);
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        dimMarriageId: [
          null
        ],
        childrenCount: [
          null
        ],
        dependentsCount: [
          null
        ]
      });
  }

}
