export enum StoreStatusCodeEnum {
  create = 'crt',
  register = 'reg',
  block = 'blc',
  delete = 'del'
}

/*
[
  {'code': 'crt', 'id': 1, 'name': 'Создан'},
  {'code': 'reg', 'id': 2, 'name': 'Зарегистрирован'},
  { 'code': 'blc', 'id': 3, 'name': 'Заблокирован' },
  {'code': 'del', 'id': 4, 'name': 'Помечен на удаление'}
]
*/
