import {Component, OnDestroy, Renderer2} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Title } from '@angular/platform-browser';
import {AuthenticationService} from './_services/authentication.service';
import {AdminAuthenticationService} from './_services/admin-authentication.service';
import {RootAuthenticationService} from './_services/root-authentication.service';
import {ToastaConfig} from 'ngx-toasta';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  previousUrl: string;
  section;
  area;
  eventsSubscription;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private authAdmin: AdminAuthenticationService,
    public authRoot: RootAuthenticationService,
    private toastaConfig: ToastaConfig
  ) {
    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.showClose = false;

    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera M/i.test(navigator.userAgent.toLowerCase());

          // const bgPath = 'assets/img/bg/screen/' + (isMobile ? 'mobile/' : '');

          if (this.activatedRoute.firstChild.snapshot.data) {
            this.area = this.activatedRoute.firstChild.snapshot.data.area;

            this.renderer.removeAttribute(document.body, 'class');
            this.renderer.addClass(document.body, 'loaded');
            this.renderer.addClass(document.body, this.area);

            this.section = this.activatedRoute.firstChild.snapshot.data.section;

            if (isMobile) {
              this.renderer.addClass(document.body, 'mobile');
            }

            if (this.section) {
              this.renderer.addClass(document.body, this.section);
            }

            if (this.area === 'signup' && location.href.indexOf('?phone=') >= 0) {
              this.renderer.addClass(document.body, 'checkout');
            }

            if (['admin', 'root'].indexOf(this.section) < 0) {
              if (
                !isMobile &&
                ['main', 'signin', 'contacts', 'partners'].indexOf(this.area) >= 0
              ) {
                this.renderer.addClass(document.body, 'shift');
              }

              // let bg;

              if (['main', 'signin'].indexOf(this.area) >= 0) {
                const number =
                  isMobile
                    ? 1
                    : Math.floor((Math.random() * 6) + 1);
                // bg = bgPath + 'main/' + number + '.jpg';

                this.renderer.addClass(document.body, 'bg-' + number);
              }
              // } else if (
              //   ['about', 'contacts', 'how'].indexOf(this.area) >= 0
              //   || this.area === 'signup' && !auth.currentUserValue && location.href.indexOf('?phone=') < 0
              // ) {
              //   bg = bgPath + 'page/' + this.area + '.jpg';
              // } else {
              //   bg = bgPath + 'page/orders.jpg';
              // }

              // this.renderer.setStyle(
              //   document.querySelector('.bg-image'),
              //   'background-image',
              //   'url("' + bg + '")'
              // );

              // if (['main', 'signin', 'about', 'contacts'].indexOf(this.area) >= 0) {
              //   this.renderer.addClass(document.body, 'dekor');
              // }
            }
          }

          if (
            auth.currentUserValue && (this.section === 'static' || this.section === 'cabinet') ||
            authAdmin.currentAdminValue && this.section === 'admin' ||
            authRoot.currentRootValue && this.section === 'root'
          ) {
            this.renderer.addClass(document.body, 'logged');
          }

          const title = this.getTitle(router.routerState, router.routerState.root).join('-');

          if (title) {
            titleService.setTitle(title);
          }
        }
      });
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy app');
    this.eventsSubscription.unsubscribe();
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state, parent) {
    const data = [];

    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }

    return data;
  }
}
