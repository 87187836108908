import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Utils} from '../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-access-modal',
  templateUrl: './user-modal.component.html'
})
export class UserModalComponent extends DataForm {

  @Input() roles;
  @Output() emitData = new EventEmitter();

  passwordType = 'password';

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'lastName': [
          '', [Validators.required]
        ],
        'firstName': [
          '', [Validators.required]
        ],
        'middleName': '',
        'email': [
          '', [Validators.required, Validators.email]
        ],
        'roleId': [
          '', [Validators.required]
        ],
        'login': [
          '', [Validators.required]
        ],
        'password': [
          '', (this.obj ? [Validators.minLength(8)] : [Validators.required, Validators.minLength(8)])
        ],
      });

    if (this.obj) {
      this.dataForm.get('email').disable();
      this.dataForm.get('login').disable();
    }
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;

      if (!this.dataForm.value.password) {
        delete this.dataForm.value.password;
      }
    }

    const serviceUser =
      this.obj
        ? this.rootService.saveUser(this.dataForm.value)
        : this.rootService.createUser(this.dataForm.value);

    serviceUser
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          this.emitData.next({action: 'save', obj: this.dataForm.value});
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onModalClosing() {
    if (this.obj) {
      // this.dataForm.value.statusId = this.obj.statusId;
      // this.dataForm.value.login = this.obj.login;
      // this.dataForm.value.email = this.obj.email;

      if (!this.dataForm.value.lastName && typeof(this.obj.lastName) === 'undefined') {
        delete this.dataForm.value.lastName;
      }

      if (!this.dataForm.value.firstName && typeof(this.obj.firstName) === 'undefined') {
        delete this.dataForm.value.firstName;
      }

      if (!this.dataForm.value.middleName && typeof(this.obj.middleName) === 'undefined') {
        delete this.dataForm.value.middleName;
      }

      if (!this.dataForm.value.email && typeof(this.obj.email) === 'undefined') {
        delete this.dataForm.value.email;
      }

      if (!this.dataForm.value.password) {
        delete this.dataForm.value.password;
      }
    }

    super.onModalClosing();
  }

  onViewPassword() {
    this.passwordType =
      this.passwordType === 'password'
        ? 'text'
        : 'password';
  }

  onGeneratePassword() {
    const passwordLength = 8 + Math.floor((Math.random() * 4) + 1);
    const addUpper = true;
    const addNumbers = true;
    const addSymbols = true;

    const lowerCharacters = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const upperCharacters = 'ABCDEFGHIJKLMNOPWRSTUVWXYZ'.split('');
    const numbers = '0123456789'.split('');
    const symbols = '!@#$%^&*-_=+|:;,.\<>/?~'.split('');

    const getRandom = function (array) {
      return array[Math.floor(Math.random() * array.length)];
    };

    let finalCharacters = '';

    if (addUpper) {
      finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
    }

    if (addNumbers) {
      finalCharacters = finalCharacters.concat(getRandom(numbers));
    }

    if (addSymbols) {
      finalCharacters = finalCharacters.concat(getRandom(symbols));
    }

    for (let i = 0; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }

    // shuffle!
    const value = finalCharacters.split('').sort(function () {
      return 0.5 - Math.random();
    }).join('');

    this.dataForm.get('password').setValue(
      value,
      {emitEvent: false});
  }
}
