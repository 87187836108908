import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { environment } from '../../environments/environment';
import {map} from 'rxjs/operators';
import {AdminAuthenticationService} from './admin-authentication.service';
import { Base64 } from 'js-base64';
import {Observable} from 'rxjs';
import {ProductTypeEnum} from '../_business/ProductType.enum';
import {Named} from '../_business/named';


@Injectable()
export class AdminService {
  private sectionMap = new Map();

  constructor(
    private http: HttpClient,
    private authService: AdminAuthenticationService
  ) {
    this.initSectionMap();
  }

  private initSectionMap(): void {
    this.sectionMap.set('create-order', 'com.zabirai.store/addorder');
    this.sectionMap.set('bank-terms', 'com.zabirai.storeoffice/terms');
    this.sectionMap.set('order-bank', 'com.zabirai.storeoffice.order.bank/setbank');
    this.sectionMap.set('order-recall', 'com.zabirai.storeoffice.order.withdraw/set');
    this.sectionMap.set('store-bank-products', 'com.zabirai.storeoffice/products');

    this.sectionMap.set('const', 'com.zabirai.entity.consts');
    this.sectionMap.set('access', 'com.zabirai.entity.restservices');

    this.sectionMap.set('store', 'com.zabirai.entity.stores');
  }

  getStoreInfo() {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('store')}/${this.authService.currentAdminValue.store.id}`
    );
  }

  // deprecated
  createOrder(data) {
    const login = 'store1'; // this.currentAdminValue.login;
    const password = '12345';
    console.log(login + ':' + password);

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + Base64.encode(login + ':' + password)
    });

    return this.http.post(
      `${environment.api}${this.sectionMap.get('create-order')}`,
      data,
      { headers: headers, observe: 'response' }
    )
      .pipe(map(data => {
        console.log('data', data);
        return data.body as any;
      }));
  }

  getConstList(o = null): Observable<any[]> {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.last && o.start ? o.last : '') + ':' + (o.length + 1),
        order: orders.join(';')
      };

      if (o.search.value) {
        const s = o.search.value;
        const cols = [
          `valueStr:like:${s}*`,
          `name:like:${s}*`,
          `note:like:${s}*`
        ];

        console.log('filter', cols);
        params.filter = Base64.encode(cols.join(';'));
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      // environment.api + this.sectionMap.get(section)
      `${environment.api}${this.sectionMap.get('const')}`,
      { params }
    );
  }

  createConst(data) {
    return this.http.post(
      `${environment.api}${this.sectionMap.get('const')}`,
      data
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }

  deleteConst(id: number) {
    return this.http.delete(
      `${environment.api}${this.sectionMap.get('const')}/${id}`
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }


  getAccessList(o = null): Observable<any[]> {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.last && o.start ? o.last : '') + ':' + (o.length + 1),
        order: orders.join(';')
      };

      if (o.search.value) {
        const s = o.search.value;
        const cols = [
          `auth:like:${s}*`,
          `mtd:like:${s}*`,
          `url:like:${s}*`
        ];

        console.log('filter', cols);
        params.filter = Base64.encode(cols.join(';'));
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      // environment.api + this.sectionMap.get(section)
      `${environment.api}${this.sectionMap.get('access')}`,
      { params }
    );
  }

  createAccess(data) {
    return this.http.post(
      `${environment.api}${this.sectionMap.get('access')}`,
      data
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }

  deleteAccess(id: number) {
    return this.http.delete(
      `${environment.api}${this.sectionMap.get('access')}/${id}`
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }

  setOrderBank(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('order-bank')}/${data.orderId}/${data.bankId}`,
      data
    );
  }

  setOrderRecall(orderId: number, value: boolean) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('order-recall')}/${orderId}/${value ? 1 : 2}`,
      {
        orderId: orderId,
        enabled: value
      }
    );
  }

  getBankTerms(productTypeId: ProductTypeEnum, bankId = null) {
    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('bank-terms')}?productId=${productTypeId}${bankId ? '&bankId=' + bankId : ''}`
    );
  }

  getStoreBankProducts(bankId = null, term = null): Observable<Named[]> {
    return this.http.get<Named[]>(
      `${environment.api}${this.sectionMap.get('store-bank-products')}?bankId=` + (bankId ? bankId : '') + (term ? '&term=' + term : '')
    );
  }

}
