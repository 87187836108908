import {Component, OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {OrderStatusEnum} from '../../_business/OrderStatus.enum';
import {FormGroup} from '@angular/forms';
import {PageOrderListComponent} from '../page-order-list/page-order-list.component';
import {NavigationExtras} from '@angular/router';
import {DeleteModalComponent} from '../../pages/page-settings/delete-modal/delete-modal.component';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-order',
  templateUrl: './page-order.component.html',
  styleUrls: ['./page-order.component.scss']
})
export class PageOrderComponent extends PageOrderListComponent implements OnInit {

  dataForm: FormGroup;

  orderId: number;
  order = null;

  ngOnInit() {
    // this.hero$ = this.route.paramMap.pipe(
    //   switchMap((params: ParamMap) =>
    //     this.service.getHero(params.get('id')))
    // );

    this.orderId = +this.route.snapshot.paramMap.get('id');

    this.getOrderStatuses();

    this.orderService.getUserOrder(this.orderId)
      .pipe(first())
      .subscribe(
        data => {
          this.order = data;

          this.loadBankInfo(this.order.bankPartnerId);

          let toIssue = null;

          this.route.queryParams.subscribe(params => {
            toIssue = params['toIssue'];
          });

          console.log('toIssue', toIssue);

          if (toIssue) {
            this.onIssue();
          }
        },
        err => {
          this.error = err.error;
        }
      );
  }

  onIssue() {
    const currentUser = this.auth.currentUserValue;

    // e  - не заполнена
    // f  - заполнена
    if (
      currentUser.userformStatusCode === 'e' // e  - не заполнена
    ) {
      // location.href = '/signup?issue=' + this.orderId;
      const navigationExtras: NavigationExtras = {
        queryParams: { 'issue': this.orderId }
      };
      this.router.navigate(['/signup'], navigationExtras);

      return;
    }

    this.loading = true;
    this.error = '';

    this.orderService.setUserOrderStatus(
      this.orderId,
      OrderStatusEnum.snt
    )
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.order.submitted = true;
          this.order.statusId = OrderStatusEnum.snt;

          if (
            this.banksLoaded[this.order.bankPartnerId] &&
            this.banksLoaded[this.order.bankPartnerId].bankUrl
          ) {
            const modalRef = this.modalService.open(
              ConfirmModalComponent,
              { backdrop: 'static' }
            );
            modalRef.componentInstance.body = 'Вы будете переадресованы на сайт банка для продолжения оформления заявки';
            modalRef.componentInstance.link = this.banksLoaded[this.order.bankPartnerId].bankUrl;

            // location.href = this.order.bankInfo.paramValue;
            // window.open(this.order.bankInfo.paramValue); // блокируется браузером
          } else {
            this.error = 'Отсутствуют данные банка';
          }
        },
        err => {
          this.error = err.error;
          this.loading = false;
        }
      );
  }

}
