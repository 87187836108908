import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../_business/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {first, map} from 'rxjs/operators';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class RootAuthenticationService {

  private sectionMap = new Map();

  private currentRootSubject: BehaviorSubject<User>;
  public currentRoot: Observable<User>;

  private currentServices: BehaviorSubject<any>;

  constructor(
    private http: HttpClient
  ) {
    this.setCredentials();
    this.initSectionMap();
  }

  private setCredentials() {
    this.currentRootSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentRoot')));
    this.currentRoot = this.currentRootSubject.asObservable();

    this.currentServices = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentServices')));
  }

  public get currentRootValue(): User {
    return this.currentRootSubject.value;
  }

  public get currentServicesValue() {
    return this.currentServices.value;
  }

  private initSectionMap(): void {
    this.sectionMap.set('auth', 'com.zabirai.root.user/auth');
    this.sectionMap.set('update-pass', 'com.zabirai.user/updatePass');
    this.sectionMap.set('request-forget-pass', 'com.zabirai.root.user/forgetpsw');
    this.sectionMap.set('services', 'com.zabirai.user/services');
  }

  login(login: string, password: string, token: string) {
    // login = login.replace(/[ \+]/g, '');
    // console.log(login + ':' + password);

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + Base64.encode(login + ':' + password),
      'Content-Type': 'application/x-www-form-urlencoded',
      'g-recaptcha-response': token
    });

    /*    httpOptions.params =
          new HttpParams()
            .set('login', user.login)
            .set('password', user.password);*/

    return this.http.get<User>(
      `${environment.api}${this.sectionMap.get('auth')}`,
      { headers: headers, observe: 'response' }
    )
      .pipe(map(data => {
        console.log('data', data);

        // login successful
        if (data && data.body as User) {
          data.body.token = data.headers.get('Token-key');

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentRoot', JSON.stringify(data.body));
          // this.currentUserSubject.next(data.body);
          this.setCredentials();

          this.http.get<any>(
            `${environment.api}${this.sectionMap.get('services')}`
          )
            .pipe(first())
            .subscribe(
              services => {
                localStorage.setItem('currentServices', JSON.stringify(services));
                this.setCredentials();
              },
              err => {
              }
            );
        }

        return data.body;
      }));
  }

  changePassword(oldPassword: string, newPassword: string) {
    const login = this.currentRootValue.login;
    console.log(login + ':' + oldPassword);

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + Base64.encode(login + ':' + oldPassword)
    });

    // const token = this.currentUserValue.token;
    // this.currentUserValue.token = null;

    return this.http.put<User>(
      `${environment.api}${this.sectionMap.get('update-pass')}`,
      { newPassword: newPassword },
      { headers: headers, observe: 'response' }
    )
      .pipe(map(data => {
        console.log('data', data);

        if (data.headers.has('Token-key')) {
          console.log('token', data.headers.get('Token-key'));
          this.currentRootValue.token = data.headers.get('Token-key');

          localStorage.setItem('currentUser', JSON.stringify(this.currentRootValue));
        }

        return data.body;
      }));
  }

  requestForgetPass(login: string, token: string) {
    login = login.replace(/[ \+]/g, '');

    const headers = new HttpHeaders({
      'g-recaptcha-response': token
    });

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('request-forget-pass')}/?login=${login}`,
      {
        login: login
      },
      { headers: headers, observe: 'response' }
    );
  }

  resendPass(login: string, code: string = null): any {
    login = login.replace(/[ \+]/g, '');

    let headers = new HttpHeaders();

    // В header добавляем Confirm-code
    // (логика следующая, если Confirm-code отсутствует,
    // пароль будет выслан только пользователю, который еще не авторизовался в системе)

    if (code) {
      headers = new HttpHeaders({
        'Confirm-code': code
      });
    }

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('request-forget-pass')}/?login=${login}&code=${code}`,
      { login: login },
      { headers: headers, observe: 'response' }
    );
  }

  hasRight(url, mtd, debug = false): boolean {
    // return true;

    if (this.currentServicesValue) {
      for (let x = 0; x < this.currentServicesValue.length; x++) {
        const service = this.currentServicesValue[x];

        if (debug) {
          console.log(service.url, url, service.mtd, mtd.toUpperCase());
        }

        if (
          service.url.indexOf(url) > -1 &&
          service.mtd.indexOf(mtd.toUpperCase()) > -1
        ) {
          if (debug) {
            console.log('found');
          }
          return true;
        }
      }
    }

    return false;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentRoot');
    localStorage.removeItem('currentServices');
    this.currentRootSubject.next(null);
  }

}
