export enum UserRoleCodeEnum {
  client  = 'c',
  store   = 's',
  admin   = 'a',
  manager = 'mz',
  guest   = 'g',
}

/*
[
  {"code": "c", "id": 1, "name": "Клиент"},
  {"code": "s", "id": 2, "name": "Магазин"},
  { "code": "a", "id": 3, "name": "Администратор"},
  {"code": "mz", "id": 4, "name": "Менеджер платформы"},
  {"code": "g", "id": 5, "name": "Гость"}
]
*/
