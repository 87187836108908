import { Component, OnInit } from '@angular/core';
import {Named} from "../../../_business/named";
import {DataForm} from "../../../_helpers/dataForm";

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.scss']
})
export class AccommodationComponent extends DataForm implements OnInit {

  streets: Named[];
  housings: Named[];

  isRegAddress: boolean = true;
  isAddedValidators = false;

  ngOnInit() {
    this.getStreets();
    this.getHousings();
    super.ngOnInit();
  }

  getStreets(): void {
    this.namedService.getNamedList('streettype')
      .subscribe(streets => this.streets = streets);
  }

  getHousings(): void {
    this.namedService.getNamedList('housing')
      .subscribe(housings => this.housings = housings);
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        dimHousingId: [
          null
        ],

        // reg
        regDimStreetTypeId: [
          null
        ],
        regStreet: [
          null
        ],
        regPhone: [
          null
        ],
        regBuilding: [
          null
        ],
        regHouse: [
          null
        ],
        regRoom: [
          null
        ],
        regLocality: [
          null
        ],

        // res
        resDimStreetTypeId: [
          null
        ],
        resStreet: [
          null
        ],
        resPhone: [
          null
        ],
        resBuilding: [
          null
        ],
        resHouse: [
          null
        ],
        resRoom: [
          null
        ],
        resLocality: [
          null
        ]
      });
  }

}
