import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PhoneMaskDirective} from '../_helpers/phone-mask.directive';
import {DateMaskDirective} from '../_helpers/date-mask.directive';
import {DigitOnlyDirective} from '../_helpers/digit-only.directive';
import {LetterOnlyDirective} from '../_helpers/letters-only.directive';
import {FloatDirective} from '../_helpers/float.directive';
import {PositiveDirective} from '../_helpers/positive.directive';
import {NgxMaskModule} from 'ngx-mask';
import {ToastaModule} from 'ngx-toasta';
import {SafePipe} from '../_helpers/safe.pipe';

@NgModule({
  declarations: [
    PhoneMaskDirective,
    DateMaskDirective,
    DigitOnlyDirective,
    LetterOnlyDirective,
    FloatDirective,
    PositiveDirective,
    SafePipe
  ],
  imports: [
    CommonModule,
    ToastaModule.forRoot(),
    NgxMaskModule.forRoot() // https://www.npmjs.com/package/ngx-mask
  ],
  exports: [
    CommonModule,
    PhoneMaskDirective,
    DateMaskDirective,
    DigitOnlyDirective,
    LetterOnlyDirective,
    FloatDirective,
    PositiveDirective,
    ToastaModule,
    NgxMaskModule,
    SafePipe
  ]
})
export class LibraryModule { }
