import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RootSignInComponent} from './sign-in/sign-in.component';
import {RootGuardService} from '../_services/root-guard.service';
import {RootSettingsComponent} from './settings/settings.component';
import {ReportsComponent} from './reports/stats/reports.component';
import {ReportsOrdersComponent} from './reports/orders/reports-orders.component';
import {UsersComponent} from './users/users.component';
import {ClientsComponent} from './clients/clients.component';
import {ShopsComponent} from './shops/shops.component';
import {TermsComponent} from './terms/terms.component';
import {CategoriesComponent} from './categories/categories.component';
import {ReportsShopsComponent} from './reports/shops/reports-shops.component';
import {PageNotFoundComponent} from '../pages/page-not-found/page-not-found.component';
import {PrioritiesComponent} from './priorities/priorities.component';
import {BankListComponent} from './banks/list/bank-list.component';
import {BankProductsComponent} from './banks/products/bank-products.component';
import {ScoringComponent} from './scoring/scoring.component';

const routes: Routes = [
  {
    path: 'root/signin',
    component: RootSignInComponent,
    data: { title: 'Вход администратора платформы', area: 'signin', section: 'root' },
    // canActivate: [GuestAdminGuardService]
  },
  {
    path: 'root/settings',
    component: RootSettingsComponent,
    data: { title: 'Настройки', area: 'settings', section: 'root' },
    canActivate: [RootGuardService]
  },
  {
    path: 'root/users',
    component: UsersComponent,
    data: { title: 'Пользователи', area: 'users', section: 'root' },
    canActivate: [RootGuardService]
  },
  {
    path: 'root/clients',
    component: ClientsComponent,
    data: { title: 'Клиенты', area: 'clients', section: 'root' },
    canActivate: [RootGuardService]
  },
  {
    path: 'root/shops',
    component: ShopsComponent,
    data: { title: 'Магазины-партнеры', area: 'shops', section: 'root' },
    canActivate: [RootGuardService]
  },
  {
    path: 'root/terms',
    component: TermsComponent,
    data: { title: 'Сроки рассрочки', area: 'terms', section: 'root' },
    canActivate: [RootGuardService]
  },
  {
    path: 'root/scoring',
    component: ScoringComponent,
    data: { title: 'Критерии скоринга', area: 'scoring', section: 'root' },
    canActivate: [RootGuardService]
  },
  {
    path: 'root/categories',
    component: CategoriesComponent,
    data: { title: 'Категории', area: 'categories', section: 'root' },
    canActivate: [RootGuardService]
  },

  {
    path: 'root/reports/stats/:period',
    component: ReportsComponent,
    data: { title: 'Отчёты', area: 'reports', section: 'root', item: 'stats' },
    canActivate: [RootGuardService]
  },
  { path: 'root/reports/stats', redirectTo: 'root/reports/stats/today', pathMatch: 'full' },
  {
    path: 'root/reports/orders/:period',
    component: ReportsOrdersComponent,
    data: { title: 'Отчёты - список заявок', area: 'reports', section: 'root', item: 'orders' },
    canActivate: [RootGuardService]
  },
  { path: 'root/reports/orders', redirectTo: 'root/reports/orders/today', pathMatch: 'full' },
  {
    path: 'root/reports/shops/:period',
    component: ReportsShopsComponent,
    data: { title: 'Отчёты - для магазинов', area: 'reports', section: 'root', item: 'shops' },
    canActivate: [RootGuardService]
  },
  { path: 'root/reports/shops', redirectTo: 'root/reports/shops/today', pathMatch: 'full' },
  { path: 'root/reports', redirectTo: 'root/reports/stats/today', pathMatch: 'full' },

  {
    path: 'root/banks/list',
    component: BankListComponent,
    data: { title: 'Банки - список', area: 'banks', section: 'root', item: 'banks' },
    canActivate: [RootGuardService]
  },
  { path: 'root/banks', redirectTo: 'root/banks/list', pathMatch: 'full' },
  {
    path: 'root/banks/products',
    component: BankProductsComponent,
    data: { title: 'Банковские продукты', area: 'products', section: 'root', item: 'products' },
    canActivate: [RootGuardService]
  },

  {
    path: 'root/priorities/:type',
    component: PrioritiesComponent,
    data: { title: 'Таблица приоритетов', area: 'priorities', section: 'root' },
    canActivate: [RootGuardService]
  },
  { path: 'root/priorities', redirectTo: 'root/priorities/shops', pathMatch: 'full' },

  { path: 'root', redirectTo: '/root/signin', pathMatch: 'full' },
  {
    path: 'root/:page',
    component: PageNotFoundComponent,
    data: { title: 'Страница не найдена', area: 'page-404', section: 'root' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RootRoutingModule { }
