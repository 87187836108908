import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AdminAuthenticationService} from '../../../_services/admin-authentication.service';
import {first} from 'rxjs/operators';
import {UserService} from '../../../_services/user.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  @Input() area: string;

  constructor(
    public auth: AdminAuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
/*    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.activatedRoute.firstChild.snapshot.data) {
            this.area = this.activatedRoute.firstChild.snapshot.data.area;
          }
        }
      });*/
  }

  onLogout() {
    this.userService.logout()
      .pipe(first())
      .subscribe(
        data => {
        },
        err => {
          console.error(err.error);
        }
      );

    this.auth.logout();
    this.router.navigate(['/admin/signin']);
  }

}
