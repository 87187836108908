import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { environment } from '../../environments/environment';
import {AdminAuthenticationService} from './admin-authentication.service';
import { Base64 } from 'js-base64';
import {Order} from '../_business/order';
import {Observable} from 'rxjs';
import {Named} from '../_business/named';
import {map} from 'rxjs/operators';
import {User} from '../_business/user';


@Injectable()
export class RootService {
  private sectionMap = new Map();

  constructor(
    private http: HttpClient,
    private authService: AdminAuthenticationService
  ) {
    this.initSectionMap();
  }

  private initSectionMap(): void {
    this.sectionMap.set('stats', 'com.zabirai.root.stat/stats');
    this.sectionMap.set('dimints', 'com.zabirai.root.stat/dimints');
    this.sectionMap.set('usrints', 'com.zabirai.root.stat/usrints');
    this.sectionMap.set('timedistr', 'com.zabirai.root.stat/timedistr');
    this.sectionMap.set('stores', 'com.zabirai.entity.stores');
    this.sectionMap.set('list', 'com.zabirai.root.stat/orders/list');
    this.sectionMap.set('export', 'com.zabirai.root.report/');

    this.sectionMap.set('users', 'com.zabirai.root.user/users');
    this.sectionMap.set('clients', 'com.zabirai.root.user/clients');
    this.sectionMap.set('categories', 'com.zabiray.categories');
    this.sectionMap.set('terms', 'com.zabiray.bank.product.terms');
    this.sectionMap.set('bank-terms', 'com.zabiray.bank.terms');

    this.sectionMap.set('priority', 'com.zabiray.root.bank.priority');
    this.sectionMap.set('bank-amounts', 'com.zabirai.root.stat/bankAmounts');
    this.sectionMap.set('bank-products', 'com.zabiray.root.bank.products');
    this.sectionMap.set('creditable', 'credwor');
    this.sectionMap.set('banks', 'com.zabiray.bank.partners');
    this.sectionMap.set('scoring', 'com.zabiray.bank.scoring');

    this.sectionMap.set('store-partners', 'com.zabiray.store.partners');
    this.sectionMap.set('partner-stores', 'com.zabiray.store.web');

    this.sectionMap.set('platform-info', 'com.zabiray.platform.info');
  }

  getStats(o: any) {
    const params: {[k: string]: any} = {};

    const cols = [];

    cols.push('createDt:btw:' + o.filterFromDate + ':' + o.filterToDate);

    if (o.filterStores && o.filterStores.length) {
      cols.push('storesId:in:' + o.filterStores);
    }

    if (o.filterBanks && o.filterBanks.length) {
      cols.push('bankPartnerId:in:' + o.filterBanks);
    }

    if (cols.length) {
      console.log('filter', cols.join(';'));
      // params.filters = Base64.encode(cols.join(';'));
      params.filters = cols.join(';');
    }

    console.log('params', params);

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('stats')}`,
      { params }
    );
  }

  getTimeDistribution(o: any) {
    const params: {[k: string]: any} = {};

    const cols = [];

    cols.push('createDt:btw:' + o.filterFromDate + ':' + o.filterToDate);

    if (o.filterStores && o.filterStores.length) {
      cols.push('storesId:in:' + o.filterStores);
    }

    if (o.filterBanks && o.filterBanks.length) {
      cols.push('bankPartnerId:in:' + o.filterBanks);
    }

    if (cols.length) {
      console.log('filter', cols.join(';'));
      // params.filters = Base64.encode(cols.join(';'));
      params.filters = cols.join(';');
    }

    console.log('params', params);

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('timedistr')}`,
      { params }
    );
  }

  getStores(o: any = null, $partUrl = '') {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.start ? o.start : '') + ':' + o.length,
        sort: orders.join(';')
      };

      let cols = [];

      if (o.filterName) {
        const s = o.filterName;
        cols = [
          `fullNameStr:like:%${s}%`
          // `lastName:like:%${s}%`,
          // `firstName:like:%${s}%`,
          // `middleName:like:%${s}%`
        ];
      }

      if (o.filterStatus) {
        cols.push('statusId:in:' + o.filterStatus);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('stores')}${$partUrl}`,
      { params }
    );
  }

  getStorePartners(o: any = null, $partUrl = '') {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.start ? o.start : '') + ':' + o.length,
        sort: orders.join(';')
      };

      let cols = [];

/*      if (o.filterName) {
        const s = o.filterName;
        cols = [
          `fullNameStr:like:%${s}%`
          // `lastName:like:%${s}%`,
          // `firstName:like:%${s}%`,
          // `middleName:like:%${s}%`
        ];
      }*/

      if (o.filterID) {
        cols.push('id:eq:' + o.filterID);
      }

      if (o.filterUnp) {
        cols.push('unp:eq:' + o.filterUnp);
      }

      if (o.filterName) {
        cols.push(`name:like:%${o.filterName}%`);
      }

      if (o.filterStores) {
        cols.push(`stores:like:%${o.filterStores}%`);
      }

      if (o.filterProducts) {
        cols.push(`products:like:%${o.filterProducts}%`);
      }

      if (o.filterStatus) {
        cols.push(`statusId:in:${o.filterStatus}`);
      }

      if (o.filterManager) {
        cols.push(`manager:like:%${o.filterManager}%`);
        // cols.push('managerId:in:' + o.filterManager);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('store-partners')}${$partUrl}`,
      { params }
    );
  }

  getStorePartner(id: number) {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/${id}`
    );
  }

  getPartnerBanks(id: number) {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/banks/${id}`
    );
  }

  savePartnerBanks(id: number, data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/banks/${id}`,
      data
    );
  }

  getPartnerBankProducts(id: number) {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/bankproducts/${id}`
    );
  }

  deletePartnerBankProducts(storePartnerId: number, bankTermId: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/bankproduct/${storePartnerId}/${bankTermId}`
    );
  }

  getPartnerBankProductTerms(storePartnerId: number, bankTermId: number) {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/bankproduct/${storePartnerId}/${bankTermId}`
    );
  }

  savePartnerBankProducts(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/bankproducts`,
      data
    );
  }

  createPartnerBankProducts(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/bankproducts`,
      data
    );
  }

  saveStorePartnerStatus(storePartnerId: number, statusId: number) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/status/${storePartnerId}/${statusId}`,
      {}
    );
  }

  createStorePartner(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}`,
      data
    );
  }

  saveStorePartner(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}`,
      data
    );
  }

  getPartnerStoreChannels(storePartnerId: number) {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/commChannels/${storePartnerId}`
    );
  }

  savePartnerStoreChannels(storePartnerId: number, data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('store-partners')}/commChannels/${storePartnerId}`,
      data
    );
  }

  getPartnerStores(o: any = null) {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      params = {};

      const cols = [];

      if (o.id) {
        cols.push('id:eq:' + o.id);
      }

      if (o.storePartnerId) {
        cols.push('storePartnerId:eq:' + o.storePartnerId);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('partner-stores')}`,
      { params }
    );
  }

  createPartnerStore(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('partner-stores')}`,
      data
    );
  }

  savePartnerStore(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('partner-stores')}`,
      data
    );
  }

  deletePartnerStore(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('partner-stores')}/${id}`
    );
  }

  getPartnerStoreCategories(storeId: number) {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('partner-stores')}/cat/${storeId}`
    );
  }

  setPartnerStoreCategories(storeId: number, data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('partner-stores')}/cat/${storeId}`,
      data
    );
  }

  getPriorityStores(bankId = null) {
    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('priority')}/stores${bankId ? '?bankId=' + bankId : ''}`
    );
  }

  getPriorityBanks() {
    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('priority')}/banks`
    );
  }

  getPriority(o: any) {
    const params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const cols = [];

      if (o.filterStore) {
        cols.push('storeId:in:' + o.filterStore);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('priority')}`,
      { params }
    );
  }

  getBankList() {
    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('banks')}`
    );
  }

  deleteBank(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('banks')}/${id}`
    );
  }

  createBank(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('banks')}`,
      data
    );
  }

  saveBank(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('banks')}`,
      data
    );
  }

  getScoring() {
    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('scoring')}`
    );
  }

  deleteScoring(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('scoring')}/${id}`
    );
  }

  createScoring(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('scoring')}`,
      data
    );
  }

  saveScoring(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('scoring')}`,
      data
    );
  }

  getCreditable() {
    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('creditable')}`
    );
  }

  getBankProducts(o: any) {
    const params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const cols = [];

      if (o.filterStores && o.filterStores.length) {
        cols.push('storeId:in:' + o.filterStores.join(':'));
      }

      if (o.filterBanks && o.filterBanks.length) {
        cols.push('bankId:in:' + o.filterBanks.join(':'));
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('bank-products')}`,
      { params }
    );
  }

  getOrders(o: any, $partUrl = '') {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.start ? o.start : '') + ':' + o.length,
        sort: orders.join(';')
      };

      let cols = [];

      if (o.search.value) {
        const s = o.search.value;
        cols = [
          `lastName:like:%${s}%`,
          `firstName:like:%${s}%`,
          `middleName:like:%${s}%`,
          `applicationNumber:like:%${s}%`,
          `phoneContact:like:%${s}%`
        ];
      } else if (o.filterFio) {
        const s = o.filterFio;
        cols = [
          `fullNameStr:like:%${s}%`
          // `lastName:like:%${s}%`,
          // `firstName:like:%${s}%`,
          // `middleName:like:%${s}%`
        ];
      }

      for (let x = 0; x < o.columns.length; x++) {
        if (o.columns[x].search.value) {
          const list = o.columns[x].search.value.split(';');

          for (let y = 0; y < list.length; y++) {
            list[y] = o.columns[x].data + ':like:%' + list[y] + '%';
          }

          cols = $.merge(cols, list);
        }
      }

      if (o.filterFromDate && o.filterToDate) {
        cols.push('createDt:btw:' + o.filterFromDate + ':' + o.filterToDate);
      }

      if (o.filterStatus) {
        cols.push('statusId:in:' + o.filterStatus);
      }

      if (o.filterApplicationNumber) {
        cols.push('applicationNumber:like:%' + o.filterApplicationNumber + '%');
      }

      if (o.filterStores && o.filterStores.length) {
        cols.push('storesId:in:' + o.filterStores);
      }

      if (o.filterBanks && o.filterBanks.length) {
        cols.push('bankId:in:' + o.filterBanks);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<Order[]>(
      `${environment.api}${this.sectionMap.get('list')}${$partUrl}`,
      { params }
    );
  }

  exportStoreReport(o: any, partUrl: string = '') {
    const params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const cols = [];

      if (o.filterFromDate && o.filterToDate) {
        cols.push('createDt:btw:' + o.filterFromDate + ':' + o.filterToDate);
      }

      if (o.ignoreStores && partUrl !== 'trans') {
        cols.push('storesId:ignore');
      } else if (o.filterStores && o.filterStores.length) {
        cols.push('storesId:in:' + o.filterStores);
      }

      if (o.ignoreBanks && partUrl !== 'trans') {
        cols.push('bankId:ignore');
      } else if (o.filterBanks && o.filterBanks.length) {
        cols.push('bankId:in:' + o.filterBanks);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    // http://zabirai.dev-3c.by/api/zabirai/webresources/com.zabirai.root.report/trans?filters=createDt:btw:2019-04-26:2019-04-26
    //
    //   const headers = new HttpHeaders({
    //     'Authorization': 'Basic ' + Base64.encode(login + ':' + password),
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'g-recaptcha-response': token
    //   });

    const headers = new HttpHeaders({
      // 'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream;charset=UTF8,application/json'

      // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      // 'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
    });

    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('export')}${partUrl}`,
      {
        headers,
        params,
        observe: 'response',
        // 'responseType'  : 'arraybuffer' as 'json'
        // responseType: 'blob'
        'responseType'  : 'blob' as 'json'
      }
    );
  }

  getDimIntervals(): Observable<Named> {
    return this.http.get<Named>(
      `${environment.api}${this.sectionMap.get('dimints')}`,
    );
  }

  getUserIntervals(): Observable<Named> {
    return this.http.get<Named>(
      `${environment.api}${this.sectionMap.get('usrints')}`,
    );
  }

  saveInterval(data) {
    return this.http.put(
      `${environment.api}${this.sectionMap.get('usrints')}`,
      data
    );
  }

  deleteInterval(id: number) {
    return this.http.delete(
      `${environment.api}${this.sectionMap.get('usrints')}/${id}`
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }

  getUsers(o: any, $partUrl = '') {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        sort: orders.join(';')
      };

      if (o.length) {
        params.page = (o.start ? o.start : '') + ':' + o.length;
      }

      let cols = [];

      if (o.filterFio) {
        const s = o.filterFio;
        cols = [
          `fullNameStr:like:%${s}%`
          // `lastName:like:%${s}%`,
          // `firstName:like:%${s}%`,
          // `middleName:like:%${s}%`
        ];
      }

      if (o.filterLogin) {
        cols.push(`login:like:%${o.filterLogin}%`);
      }

      if (o.filterRole) {
        cols.push('roleId:in:' + o.filterRole);
      }

      if (o.filterStatus) {
        cols.push('statusId:in:' + o.filterStatus);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('users')}${$partUrl}`,
      { params }
    );
  }

  deleteUsers(idList: number[]) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('users')}/delete`
    );
  }

  createUser(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('users')}`,
      data
    );
  }

  saveUser(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('users')}`,
      data
    );
  }

  getClients(o: any, $partUrl = '') {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.start ? o.start : '') + ':' + o.length,
        sort: orders.join(';')
      };

      let cols = [];

      if (o.filterFio) {
        const s = o.filterFio;
        cols = [
          `fullNameStr:like:%${s}%`
          // `lastName:like:%${s}%`,
          // `firstName:like:%${s}%`,
          // `middleName:like:%${s}%`
        ];
      }

      if (o.filterLogin) {
        cols.push(`login:like:%${o.filterLogin}%`);
      }

      if (o.filterStatus) {
        cols.push('statusId:in:' + o.filterStatus);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('clients')}${$partUrl}`,
      { params }
    );
  }

  deleteClient(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('clients')}/${id}`
    );
  }

  saveClient(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('clients')}`,
      data
    );
  }

  getTerms(o: any = null, $partUrl = '') {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.start ? o.start : '') + ':' + o.length,
        sort: orders.join(';')
      };

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('terms')}${$partUrl}`,
      { params }
    );
  }

  deleteTerm(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('terms')}/${id}`
    );
  }

  createTerm(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('terms')}`,
      data
    );
  }

  saveTerm(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('terms')}`,
      data
    );
  }

  getBankTerms(cols: any[] = []) {
    const params: {[k: string]: any} = {};

    // const cols = [];
    //
    // if (o.filterBanks && o.filterBanks.length) {
    //   cols.push('bankId:in:' + o.filterBanks);
    // }

    if (cols.length) {
      console.log('filter', cols.join(';'));
      params.filters = cols.join(';');
    }

    console.log('params', params);

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('bank-terms')}`,
      { params }
    );
  }

  deleteBankTerms(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('bank-terms')}/${id}`
    );
  }

  createBankTerms(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('bank-terms')}`,
      data
    );
  }

  saveBankTerms(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('bank-terms')}`,
      data
    );
  }

  getCategoryImgPath() {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'text/plain',
    //   'Accept': '*/*'
    // });

    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('categories')}/imgpath`
      // { headers: headers, observe: 'response' }
    );
  }

  setCategoryImg(fileToUpload: File, path = null) {
    const formData: FormData = new FormData();
    formData.append('categoriesImg', fileToUpload, fileToUpload.name);

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('categories')}/img` + (path ? '?path=' + path : ''),
      formData
    );
  }

  getCategories(o: any, $partUrl = '') {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.start ? o.start : '') + ':' + o.length,
        sort: orders.join(';')
      };

      console.log('params', params);
    }

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('categories')}${$partUrl}`,
      { params }
    );
  }

  deleteCategory(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('categories')}/${id}`
    );
  }

  createCategory(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('categories')}`,
      data
    );
  }

  saveCategory(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('categories')}`,
      data
    );
  }

  createPriority(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('priority')}`,
      data
    );
  }

  createCreditable(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('creditable')}`,
      data
    );
  }

  deletePriority(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('priority')}/${id}`
    );
  }

  deleteCreditable(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('creditable')}/${id}`
    );
  }

  createBankProduct(data) {
    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('bank-products')}`,
      data
    );
  }

  deleteBankProduct(id: number) {
    return this.http.delete<any>(
      `${environment.api}${this.sectionMap.get('bank-products')}/${id}`
    );
  }

  getBankAmounts(o: any) {
    const params: {[k: string]: any} = {};

    const cols = [];

    cols.push('createDt:btw:' + o.filterFromDate + ':' + o.filterToDate);

    if (o.filterStores && o.filterStores.length) {
      cols.push('storesId:in:' + o.filterStores);
    }

    if (o.filterBanks && o.filterBanks.length) {
      cols.push('bankPartnerId:in:' + o.filterBanks);
    }

    if (cols.length) {
      console.log('filter', cols.join(';'));
      // params.filters = Base64.encode(cols.join(';'));
      params.filters = cols.join(';');
    }

    console.log('params', params);

    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('bank-amounts')}`,
      { params }
    );
  }

  getPlatformInfo() {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('platform-info')}`
    );
  }

  savePlatformInfo(data) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('platform-info')}`,
      data
    );
  }

}
