import {Component, Input, OnInit} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {NavigationExtras} from '@angular/router';
import {timer} from 'rxjs';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent extends DataForm {

  number: string;
  loadingSms = false;
  disabledSms = true;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        code: [
          null, [
            Validators.required
          ]]
      });

    const secondsCounter = timer(30000);
    secondsCounter.subscribe(n => {
      this.disabledSms = false;
    });
  }

  onAcceptSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.userService.delete(this.dataForm.value.code)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.activeModal.close();

          this.auth.logout();
          this.router.navigate(['/signin']);
        },
        err => {
          this.onStopSubmit();

          if (err.status === 406) {
            err.error = 'Неверный код подтверждения удаления';
          }

          this.error = err.error;
        }
      );
  }

  onRepeatSms() {
    if (this.disabledSms) {
      return;
    }

    this.loadingSms = true;
    this.disabledSms = true;
    this.error = '';

    this.userService.requestDelete()
      .pipe(first())
      .subscribe(
        data => {
          this.loadingSms = false;

          const secondsCounter = timer(30000);
          secondsCounter.subscribe(n => {
            this.disabledSms = false;
          });
        },
        err => {
          this.loadingSms = false;
          this.disabledSms = false;
          this.error = err.error;
        }
      );
  }

}
