import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-page-main',
  templateUrl: './page-main.component.html',
  styleUrls: ['./page-main.component.scss']
})
export class PageMainComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['signin']) {
        const navigationExtras: NavigationExtras = {
          queryParams: { 'phone': params['signin'] }
        };
        this.router.navigate(['/signin'], navigationExtras);
      } else if (params['phone']) {
        const navigationExtras: NavigationExtras = {
          queryParams: { 'phone': params['phone'] }
        };
        this.router.navigate(['/signup'], navigationExtras);
      }
    });
  }

}
