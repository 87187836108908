import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class RootFooterComponent implements OnInit {

  now = new Date();

  constructor() { }

  ngOnInit() {
  }

}
