import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {FormArray, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {OrderStatusEnum} from '../../../_business/OrderStatus.enum';
import {Utils} from '../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';
import {DatePipe} from '@angular/common';
import {timer} from 'rxjs';
import {ResendBankModalComponent} from '../resend-bank-modal/resend-bank-modal.component';
import {Named} from '../../../_business/named';
import {ProductTypeEnum} from '../../../_business/ProductType.enum';
import {OrderStatusCodeEnum} from '../../../_business/OrderStatusCode.enum';
import {BankEnum} from '../../../_business/Bank.enum';
import {ReturnRequisitesModalComponent} from './return-requisites-modal/return-requisites-modal.component';

declare var jQuery: any;

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.scss']
})
export class OrderModalComponent extends DataForm implements OnInit {

  orderStatusEnum = OrderStatusEnum;
  productTypeEnum = ProductTypeEnum;
  productTypes: Named[];
  termList;
  // isAddedValidators = false;
  storeData: any;
  toValid = false;
  storeBanks: Named[];

  datePipe = new DatePipe('ru-RU');
  createDt;
  errorTop = '';

  orderStatusCodeEnum = OrderStatusCodeEnum;
  bankEnum = BankEnum;

  fileToUpload: File = null;

  invoicing = false;
  returnRequisiteDate = null;
  exporting = false;

  @ViewChild('orderForm') orderFormElement: ElementRef;

  @Output() emitData = new EventEmitter();

  initForm(): void {
    const now = new Date();

    const configGroup = {
      'actionTime': 7,
      'applicationNumber': null,
      'creditAmount': '',
      'firstName': '',
      // 'fullNameStr': '',
      'lastName': '',
      'middleName': '',
      'orderDt': this.datePipe.transform(now, 'yyyy-MM-ddTHH:mm:ss'),
      'phoneContact': '+375 ',
      'emailContact': null,
      'phoneShop': (this.storeData ? Utils.phoneFormat(this.storeData.phone) : null),
      'product': '',

      'shopLogo': (this.storeData ? this.storeData.logo : null),
      'shopName': (this.storeData ? this.storeData.name : null),
      'shopUrl': (this.storeData ? this.storeData.url : null),
      'term': null,
      'totalPrice': '',
      'initialFee': 0,
      'bankPartnerId': '',

      productOrders: this.formBuilder.array([
        this.createItem()
      ])
    };

    if (this.obj) {
      configGroup['createDt'] = '';
    }

    this.dataForm =
      this.formBuilder.group(configGroup);

    if (this.isReadonly()) {
      this.dataForm.get('actionTime').disable();
      this.dataForm.get('product').disable();
      this.dataForm.get('term').disable();
      this.dataForm.get('bankPartnerId').disable();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.obj) {
      this.createDt = this.obj.createDt;
      this.dataForm.get('createDt').setValue(
        this.datePipe.transform(this.obj.createDt, 'dd.MM.yyyy HH:mm:ss'),
        {emitEvent: false});

      if (this.obj.phoneShop) {
        this.dataForm.get('phoneShop').setValue(
          Utils.phoneFormat(this.obj.phoneShop),
          {emitEvent: false});
      }

      if (this.obj.phoneContact) {
        this.dataForm.get('phoneContact').setValue(
          Utils.phoneFormat(this.obj.phoneContact),
          {emitEvent: false});
      }

      // if (this.isResendToBank(true)) {
      //   this.namedService.getStoreBanks(this.obj.id)
      //     .subscribe(data => {
      //       this.storeBanks = data;
      //     });
      // }

      if (this.isReturnRequisites()) {
        this.onExportReturnRequisite(true);
      }
    }

    this.getStoreProducts((): void => {
      this.getTerms((): void => {
        this.getStoreBanks();
      });
    });

/*    setTimeout(function () {
      jQuery('select.sp').selectpicker({
        // actionsBox: true,
        liveSearch: true,
        // countSelectedText: 1,
        noneResultsText: 'Не найдено {0}',
        // allSelectedText: 'All',
        // maxHeight: 200,
      });
      jQuery('.bootstrap-select').addClass('form-control');
    }, 500);*/
  }

  get productOrders() {
    return this.dataForm.get('productOrders') as FormArray;
  }

  isNew(): boolean {
    return this.obj && this.obj.statusId === this.orderStatusEnum.new;
  }

  isRecalled(): boolean {
    return this.obj && this.obj.statusId === this.orderStatusEnum.wth;
  }

  isDraft(): boolean {
    return this.obj && this.obj.statusId === this.orderStatusEnum.dft;
  }

  isRequire(): boolean {
    return this.isDraft() || this.toValid;
  }

  isReadonly(hard: boolean = false): boolean {
    return this.obj && (
      this.obj.statusId !== this.orderStatusEnum.dft &&
      (hard || !this.isRecalled())
    );
  }

  isResendToBank(hard: boolean = false): boolean {

    // NOTE: Получение списка статусов заказа в которых возможно устанавливать новый банк
    // GET http://zabirai.dev-3c.by/api/zabirai/webresources/com.zabirai.storeoffice.order.bank/statuses
    // Ответ - набор полученных статусов
    // [{""code"":""new"",""id"":0,""name"":""Новая""},{""code"":""ann"",""id"":5,""name"":""Аннулирована""},{""code"":""snt"",""id"":10,""name"":""Отправлена в банк""},{""code"":""wth"",""id"":25,""name"":""Отозвана магазином""}]
    // или статус ошибки + ErrorEntity с описанием"

    return this.obj &&
      [
        this.orderStatusEnum.new,
        this.orderStatusEnum.snt,
        this.orderStatusEnum.canceled
      ].indexOf(this.obj.statusId) >= 0 && (hard || this.storeBanks && this.storeBanks.length > 0);
  }

  isInvoice(): boolean {
    return this.obj &&
      [
        this.orderStatusEnum.new,
        this.orderStatusEnum.dft,
        this.orderStatusEnum.wth
      ].indexOf(this.obj.statusId) >= 0;
  }

  isDeliveredToBank(): boolean {
    return (
      this.obj &&
      this.obj.status.code === this.orderStatusCodeEnum.dlv
      // && [ this.bankEnum.belagprom ].indexOf(this.obj.bankPartnerId) >= 0
    );
  }

  isCancel(): boolean {
    return (
      this.isDeliveredToBank() &&
      [ this.bankEnum.belweb ].indexOf(this.obj.bankPartnerId) >= 0
    );
  }

  isReturnRequisites(): boolean {
    return this.obj &&
      [ this.orderStatusEnum.finished ].indexOf(this.obj.statusId) >= 0 &&
      [ this.bankEnum.mt ].indexOf(this.obj.bankPartnerId) >= 0;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      'orderId': null,
      'modification': null,
      'brandName': '',
      'shippingPrice': 0,
      'pictureUrl': '',
      'productName': '',
      'productPrice': null,
      'productUrl': '',
      'quantity': null
    });
  }

  addItem() {
    this.productOrders.push(this.createItem());
  }

  removeProduct(i): void {
    this.productOrders.removeAt(i);

    if (!this.productOrders.length) {
      this.addItem();
    }

    this.onPriceInput(0);
  }

  toIssue() {
    console.log('toIssue', this.obj);

    this.error = '';

    this.orderService.issue(this.obj.id)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          this.emitData.next('issue');
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onSubmit(toIssue: boolean, toRecall: boolean = false, toCopy: boolean = false) {
    if (!toIssue && this.toValid) {
      this.toValid = false;
      this.switchValidators();
    }

    // if (!this.onStartSubmit()) {
    //   return;
    // }

    this.submitted = true;
    this.loading = true;
    this.error = '';

    const data = this.dataForm.value;

    // убираем пробелы из телефонов

    if (data.phoneShop) {
      data.phoneShop = data.phoneShop.replace(/[ \+]/g, '');
    }

    if (data.phoneContact) {
      data.phoneContact = data.phoneContact.replace(/[ \+]/g, '');
    }

    if (data.createDt) { // вертаем формат даты создания
      data.createDt = this.createDt;
    }

    if (this.obj && !toCopy) {
      if (this.isRecalled()) {
        this.orderService.updateWithdraw(this.obj.id, data)
          .pipe(first())
          .subscribe(
            data => {
              this.onStopSubmit();

              if (toRecall) {
                this.onRestore(true);
              } else {
                this.emitData.next('update');
                this.activeModal.close('Close click');
              }
            },
            err => {
              this.onStopSubmit();
              this.error = err.error;
            }
          );
      } else {
        this.orderService.update(this.obj.id, data)
          .pipe(first())
          .subscribe(
            data => {
              if (toIssue) {
                this.toIssue();
              } else {
                this.onStopSubmit();
                this.emitData.next('update');
                this.activeModal.close('Close click');
              }
            },
            err => {
              this.onStopSubmit();
              this.error = err.error;
            }
          );
      }
    } else {
      this.orderService.create(data)
        .pipe(first())
        .subscribe(
          data => {
            if (toIssue) {
              this.obj = data;
              this.toIssue();
            } else {
              this.onStopSubmit();

              if (toCopy) {
                data.command = 'create';
                this.emitData.next(data);
              } else {
                this.emitData.next('create');
              }

              this.activeModal.close('Close click');
            }
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    }
  }

  onDelete() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить заказ?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      // if (!this.onStartSubmit()) {
      //   return false;
      // }

      this.error = '';

      this.orderService.delete(this.obj.id)
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();

            if (data && data.error && data.errorText) {
              this.error = data.errorText;
            } else {
              this.emitData.next('delete');
              this.activeModal.close('Close click');
            }
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    });
  }

  onCancel() {
    if (this.loading) {
      return false;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.body = 'В банк будет отправлено уведомление об отмене заказа';

    modalRef.componentInstance.submit.subscribe(($e) => {
      this.onStartSubmit();

      this.orderService.setCancel(
        this.obj.id
      )
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();
            this.emitData.next('update');
            this.activeModal.close('Close click');
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    });
/*    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите отменить заказ?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      if (!this.onStartSubmit()) {
        return false;
      }

      this.orderService.setUserOrderStatus(
        this.obj.id,
        OrderStatusEnum.canceled
      )
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();

            if (data && data.error && data.errorText) {
              this.error = data.errorText;
            } else {
              this.emitData.next('cancel');
              this.activeModal.close('Close click');
            }
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    });*/
  }

  attachValidators(control, validators) {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  switchCreditAmountValidators() {
    this.attachValidators(
      this.dataForm.get('creditAmount'),
      this.isRequire()
        ? [ Validators.min(0.0001), Validators.max(this.dataForm.value.totalPrice) ]
        : [ ]
    );

    // console.log(this.dataForm.value.totalPrice + ' > ' + this.dataForm.value.initialFee);
    //
    // this.attachValidators(
    //   this.dataForm.get('totalPrice'),
    //   this.isRequire()
    //     ? [ Validators.min(this.dataForm.value.initialFee + 0.0001) ]
    //     : [ ]
    // );

    this.attachValidators(
      this.dataForm.get('initialFee'),
      this.isRequire()
        ? [ Validators.max(this.dataForm.value.totalPrice - 0.0001) ]
        : [ ]
    );
  }

  switchValidators() {
    let fields = [ 'actionTime', 'orderDt', 'product', 'shopName', 'term' ];

    for (let x = 0; x < fields.length; x++) {
      this.attachValidators(
        this.dataForm.get(fields[x]),
        this.isRequire()
          ? [ Validators.required ]
          : [ ]
      );
    }

    this.attachValidators(
      this.dataForm.get('emailContact'),
      this.isRequire()
        ? [ Validators.email ]
        : [ ]
    );

    this.attachValidators(
      this.dataForm.get('phoneContact'),
      this.isRequire()
        ? [ Validators.required, Validators.pattern('^\\+375 \\d{2} \\d{3} \\d{2} \\d{2}$') ]
        : [ ]
    );

    this.attachValidators(
      this.dataForm.get('phoneShop'),
      this.isRequire()
        ? [ Validators.required, Validators.pattern('^\\+375 \\d{2} \\d{3} \\d{2} \\d{2}$') ]
        : [ ]
    );

    // if (this.adminAuth.currentAdminValue.store.id !== 1) { // TODO: remove this
    //   this.attachValidators(
    //     this.dataForm.get('term'),
    //     this.isRequire()
    //       ? [Validators.required, Validators.min(3), Validators.max(9)]
    //       : []
    //   );
    // }

    this.switchCreditAmountValidators();

    // Products

    for (let p = 0; p < this.productOrders.length; p++) {
      const fg = this.productOrders.controls[p] as FormGroup;
      fields = [ 'modification', 'productPrice', 'quantity' ];

      for (let x = 0; x < fields.length; x++) {
        this.attachValidators(
          fg.get(fields[x]),
          this.isRequire()
            ? [ Validators.required ]
            : [ ]
        );
      }
    }
  }

  onIssue() {
    this.submitted = true;
    this.toValid = true;
    this.switchValidators();

    if (this.dataForm.invalid) {
      const secondsCounter = timer();
      secondsCounter.subscribe(n => {
        const invalidElements = this.orderFormElement.nativeElement.querySelectorAll('input.ng-invalid');

        if (invalidElements.length > 0) {
          invalidElements[0].focus();
        }
      });

      return false;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Уверены что хотите сформировать заказ?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      if (!this.onStartSubmit()) {
        return false;
      }

      this.onSubmit(true);
    });
  }

  onPriceInput($e) {
    let total = 0;

    for (let i = 0; i < this.productOrders.length; i++) {
      if (
        this.productOrders.at(i).value.productPrice &&
        this.productOrders.at(i).value.quantity
      ) {
        total = +(
          total +
          +(+this.productOrders.at(i).value.productPrice * +this.productOrders.at(i).value.quantity).toFixed(5) +
          this.productOrders.at(i).value.shippingPrice
        ).toFixed(5);
      }
    }

    this.dataForm.get('totalPrice').setValue(
      (total ? total : ''),
      {emitEvent: false});

    this.switchCreditAmountValidators();

    this.dataForm.get('creditAmount').setValue(
      (total ? +(total - this.dataForm.value.initialFee).toFixed(5) : ''),
      {emitEvent: false});
  }

  onModalClosing() {
    if (!this.isReadonly()) {
      const modalRef = this.modalService.open(
        ConfirmModalComponent,
        { backdrop: 'static' }
      );
      modalRef.componentInstance.body = 'Введенные данные не будут сохранены. Вы уверены, что хотите продолжить?';
      modalRef.componentInstance.submit.subscribe(($e) => {
        this.emitData.next('refresh');
        this.activeModal.close('Close click');
      });

      return;
    }

    this.emitData.next('refresh');
    this.activeModal.close('Close click');
  }

  onRecall() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите отозвать заказ?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      if (!this.onStartSubmit()) {
        return false;
      }

      this.adminService.setOrderRecall(
        this.obj.id,
        true
      )
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();

            this.emitData.next('recall');
            this.activeModal.close('Close click');
          },
          err => {
            this.onStopSubmit();
            this.errorTop = err.error;
          }
        );
    });
  }

  onRestore(hard: boolean = false) {
    if (hard) {
      this.doRestore();
    } else {
      const modalRef = this.modalService.open(
        ConfirmModalComponent,
        { backdrop: 'static' }
      );
      modalRef.componentInstance.body = 'Вы уверены что хотите восстановить заказ?';
      modalRef.componentInstance.submit.subscribe(($e) => {
        this.doRestore();
      });
    }
  }

  doRestore() {
    if (!this.onStartSubmit()) {
      return false;
    }

    this.adminService.setOrderRecall(
      this.obj.id,
      false
    )
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          this.emitData.next('restore');
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onResendToBank() {
    this.activeModal.close('Close click');

    const modalRef = this.modalService.open(
      ResendBankModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.orderId = this.obj.id;
    modalRef.componentInstance.banks = this.storeBanks;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      // console.log('e', $e);
      this.emitData.next($e);
    });
  }

  onInvoice(files: FileList) {
    this.errorTop = '';

    const max = 2;
    const fileToUpload: File = files.item(0);

    if (fileToUpload.size > max * 1048576) {
      this.errorTop = 'Файл не должен превышать ' + max + 'Mb';
      return;
    }

    this.invoicing = true;

    this.orderService.setInvoice(this.obj.id, fileToUpload)
      .pipe(first())
      .subscribe(data => {
          this.invoicing = false;
          console.log(data);
        },
        err => {
          this.invoicing = false;
          this.errorTop = err.error;
        });
  }

  onCopy() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Будет создана копия текущей заявки в статусе «Черновик»';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.onSubmit(false, false, true);
    });
  }

  onReturnRequisites() {
    const modalRef = this.modalService.open(
      ReturnRequisitesModalComponent
    );

    modalRef.componentInstance.orderId = this.obj.id;
    modalRef.componentInstance.creditAmount = this.obj.creditAmount;
    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.returnRequisiteDate = $e;
    });
  }

  getStoreProducts(callback = null) {
    this.error = null;

    this.adminService.getStoreBankProducts(
      this.dataForm.value.bankPartnerId,
      this.dataForm.value.term
    )
      .subscribe(data => {
          this.productTypes = data;

          // this.dataForm.get('product').setValue('');

          if (!this.productTypes.length) {
            this.error = 'Выбранный тип банковского продукта недоступен';
          } else {
            // if (this.productTypes.length === 1 && this.productTypes[0].id !== this.dataForm.value.product) {
            //   this.dataForm.get('product').setValue(
            //     this.productTypes[0].id,
            //     {emitEvent: false});
            // }
          }

          if (this.dataForm.value.product) {
            let found = false;

            for (let i = 0; i < this.productTypes.length; i++) {
              if (+this.productTypes[i].id === +this.dataForm.value.product) {
                found = true;
                break;
              }
            }

            if (!found) {
              console.log('reset product');
              this.dataForm.get('product').reset();
              // this.dataForm.value.product = '';
            }
          }

          if (callback) {
            callback();
          }
        },
        err => {
          this.error = err.error;
        });
  }

  getTerms(callback = null) {
    const productTypeId = this.isReadonly() ? this.obj.product : this.dataForm.value.product;

    if (!productTypeId) {
      // this.error = 'Банковский продукт недоступен';
      return;
    }

    this.termList = null;

    this.adminService.getBankTerms(
      productTypeId,
      this.isReadonly() ? this.obj.bankPartnerId : this.dataForm.value.bankPartnerId
    )
      .subscribe(dataInner => {
          this.termList = dataInner;

          if (!this.termList.length) {
            this.error = 'Выбранный срок недоступен';
          }

          if (this.dataForm.value.term) {
            let found = false;

            for (let i = 0; i < this.termList.length; i++) {
              if (+this.termList[i] === +this.dataForm.value.term) {
                found = true;
                break;
              }
            }

            if (!found) {
              console.log('reset term');
              this.dataForm.get('term').reset();
              // this.dataForm.value.term = '';
            }
          }

          if (callback) {
            callback();
          }
        },
        err => {
          this.error = err.error;
        });
  }

  getStoreBanks(callback = null) {
    if (!this.dataForm.value.product && !this.isReadonly()) {
      return;
    }

    this.namedService.getStoreBanks(
      this.obj ? this.obj.id : null,
      this.isReadonly() ? this.obj.product : this.dataForm.value.product,
      this.isReadonly() ? this.obj.term : this.dataForm.value.term
    )
      .subscribe(data => {
        this.storeBanks = data;

        if (this.dataForm.value.bankPartnerId) {
          let found = false;

          for (let i = 0; i < this.storeBanks.length; i++) {
            if (+this.storeBanks[i].id === +this.dataForm.value.bankPartnerId) {
              found = true;
              break;
            }
          }

          if (!found) {
            console.log('reset bankPartnerId');
            this.dataForm.get('bankPartnerId').reset();
            // this.dataForm.value.bankPartnerId = '';
          }
        }

        if (callback) {
          callback();
        }
      });
  }

  onChangeProduct() {
    this.getTerms((): void => {
      this.getStoreBanks();
    });
  }

  onChangeTerm() {
    this.getStoreProducts((): void => {
      this.getStoreBanks();
    });
  }

  onChangeBank() {
    this.getStoreProducts((): void => {
      this.getTerms();
    });
  }

  onDelivered() {
    if (this.loading) {
      return false;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.body = 'В банк будет отправлено подтверждение доставки товара';

    modalRef.componentInstance.submit.subscribe(($e) => {
      this.onStartSubmit();

      this.orderService.setDelivered(
        this.obj.id
      )
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();
            this.emitData.next('update');
            this.activeModal.close('Close click');
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    });
  }

  onExportReturnRequisite(checkFile = false) {
    if (this.exporting) {
      return;
    }

    this.exporting = true;
    this.error = '';

    this.orderService.exportReturnRequisite(this.obj.id)
      .pipe(first())
      .subscribe(data => {
          this.exporting = false;

          if (checkFile) {
            const f = data.headers.get('content-disposition');

            if (f && f.indexOf('filename') > -1) {
              this.returnRequisiteDate = decodeURIComponent(f.split('filename=')[1]);
            }
          } else {
            Utils.export(data);
          }
        },
        err => {
          this.exporting = false;

          if (!checkFile) {
            this.error = err.error;
          }
        });
  }

}
