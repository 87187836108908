import {Component, EventEmitter, Output} from '@angular/core';
import {DataForm} from '../../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';

declare var jQuery: any;

@Component({
  selector: 'app-platform-info-modal',
  templateUrl: './platform-info-modal.component.html'
})
export class PlatformInfoModalComponent extends DataForm {

  @Output() emitData = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'unp': [
          null, [Validators.required]
        ],
        'name': [
          null, [Validators.required]
        ],
        'addr': [
          null, [Validators.required]
        ],
        'bankDetails': [
          null, [Validators.required]
        ],
        'executivePost': [
          null, [Validators.required]
        ],
        'executiveFio': [
          null, [Validators.required]
        ],
        'executiveFioGen': [
          null, [Validators.required]
        ],
        'statute': [
          null, [Validators.required]
        ]
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const partnerBanks = this.dataForm.value.items;
    delete this.dataForm.value.items;

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    this.rootService.savePlatformInfo(this.dataForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next({
            action: 'save',
            obj: data
          });
          this.activeModal.close('Close');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }
}
