import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {NavigationExtras} from '@angular/router';
import {timer} from 'rxjs';
import {DataForm} from '../../../_helpers/dataForm';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-accept-password-modal',
  templateUrl: './remember-password-modal.component.html',
  styleUrls: ['./remember-password-modal.component.scss']
})
export class RememberPasswordModalComponent extends DataForm {

  loadingSms = false;
  disabledSms = true;

  siteKey: string = environment.recaptchaPublicKey;

  @Input() token: string;
  @Input() number: string;

  @Output() successEvent = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        code: [
          null, [
            Validators.required
          ]]
      });

    const secondsCounter = timer(30000);
    secondsCounter.subscribe(n => {
      this.disabledSms = false;
    });
  }

  onAcceptSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.auth.resendPass(
      this.number,
      this.dataForm.value.code
    )
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.success = true;
          this.successEvent.emit();
          // this.activeModal.close();
        },
        err => {
          this.onStopSubmit();

          if (err.status === 406) {
            err.error = 'Неверный код подтверждения';
          }

          this.error = err.error;
        });
  }

  onRepeatSms() {
    if (this.disabledSms) {
      return;
    }

    this.loadingSms = true;
    this.disabledSms = true;

    this.reCaptchaV3Service.execute(this.siteKey, 'user/fgetpsw', (token) => {
      console.log('captcha token: ', token);
      this.error = '';
      this.auth.requestForgetPass(
        this.number,
        token
      )
        .subscribe(
          data => {
            this.loadingSms = false;

            const secondsCounter = timer(30000);
            secondsCounter.subscribe(n => {
              this.disabledSms = false;
            });
          },
          err => {
            this.loadingSms = false;
            this.error = err.error;
            this.disabledSms = false;
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

}
