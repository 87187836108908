import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {DataForm} from '../../_helpers/dataForm';
import {environment} from '../../../environments/environment';
import {NavigationExtras} from '@angular/router';
import {timer} from 'rxjs';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {DeleteModalComponent} from '../page-settings/delete-modal/delete-modal.component';
import {RememberPasswordModalComponent} from './remember-password-modal/remember-password-modal.component';
import {Utils} from '../../_helpers/utils';

@Component({
  selector: 'app-page-sign-in',
  templateUrl: './page-sign-in.component.html',
  styleUrls: ['./page-sign-in.component.scss']// ,
  // encapsulation: ViewEncapsulation.None
})
export class PageSignInComponent extends DataForm implements OnInit {

  phone = '';
  siteKey: string = environment.recaptchaPublicKey;

  @Output() onSuccessCallback = new EventEmitter();

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['phone'] && params['phone'] !== 'undefined') {
        this.phone = params['phone'];
      }
    });

    super.ngOnInit();
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        login: [
          Utils.phoneFormat('+375 ' + this.phone), [
            Validators.required,
            Validators.pattern('^\\+375 \\d{2} \\d{3} \\d{2} \\d{2}$')
          ]],
        password: [
          null, [
            Validators.required
          ]]
      });

    // reset login status
    this.auth.logout();
  }

  toLogin() {
    this.reCaptchaV3Service.execute(this.siteKey, 'user/auth', (token) => {
      console.log('captcha token: ', token);
      this.auth.login(
        this.f.login.value,
        this.f.password.value,
        token
      )
        .pipe(first())
        .subscribe(
          user => {
            // if (this.phone) {
            //   this.onStopSubmit();
            //   this.renderer.addClass(document.body, 'logged');
            //   this.onSuccessCallback.next(this.success);
            // } else {

              // e  - не заполнена
              // f  - заполнена
              // if (user.userformStatusCode === 'e') { // анкета не заполнена
              //   this.router.navigate(['/signup']);
              // } else {
              this.router.navigate(['/orders']);
              // }

            // }

            if (jQuery('.navbar-collapse').hasClass('show')) {
              jQuery('.navbar-toggler').click();
            }
          },
          err => {
            this.onStopSubmit();

            if (err.status === 401) {
              err.error = 'Неверный логин или пароль';
            }

            this.error = err.error;
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

  switchValidators(isRequire: boolean) {
    const control = this.dataForm.get('password');

    const validators =
      isRequire
        ? [ Validators.required ]
        : [ ];

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  onSubmit() {
    this.switchValidators(true);
    this.error = null;

    if (!this.onStartSubmit()) {
      return;
    }

    this.toLogin();
  }

  onRemember() {
    this.switchValidators(false);

    if (!this.onStartSubmit()) {
      this.error = 'Пожалуйста, укажите корректный номер мобильного телефона';
      return;
    }

    this.reCaptchaV3Service.execute(this.siteKey, 'user/fgetpsw', (token) => {
      console.log('captcha token: ', token);
      this.auth.requestForgetPass(
        this.f.login.value,
        token
      )
        .subscribe(
          data => {
            this.onStopSubmit();

            const modalDelRef = this.modalService.open(
              RememberPasswordModalComponent,
              { backdrop: 'static' }
            );
            modalDelRef.componentInstance.number = this.f.login.value;
            modalDelRef.componentInstance.token = token;
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

}
