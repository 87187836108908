import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Utils} from '../../../../_helpers/utils';

@Component({
  selector: 'app-return-requisites-modal',
  templateUrl: './return-requisites-modal.component.html'
})
export class ReturnRequisitesModalComponent extends DataForm {

  @Input() orderId: number;
  @Input() creditAmount: number;
  @Output() emitData = new EventEmitter();

  datePipe = new DatePipe('ru-RU');

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'SumReturn': [
          this.creditAmount, [Validators.required]
        ],
        'FullReturn': true
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.orderService.exportReturnRequisite(this.orderId, this.dataForm.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.onStopSubmit();
          const date = Utils.export(data);

          this.emitData.next(
            date
            // this.datePipe.transform(new Date(), 'dd.MM.yyyy')
          );
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

}
