import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrdersComponent } from './orders/orders.component';
import {AdminGuardService} from '../_services/admin-guard.service';
import {SignInComponent} from './sign-in/sign-in.component';
import {SettingsComponent} from './settings/settings.component';
import {OptionsComponent} from './options/options.component';
import {DimsComponent} from './dims/dims.component';
import {AccessComponent} from './access/access.component';
import {PageNotFoundComponent} from '../pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'admin/signin',
    component: SignInComponent,
    data: { title: 'Вход', area: 'signin', section: 'admin' },
    // canActivate: [GuestAdminGuardService]
  },
  {
    path: 'admin/settings',
    component: SettingsComponent,
    data: { title: 'Настройки', area: 'settings', section: 'admin' },
    canActivate: [AdminGuardService]
  },
  {
    path: 'admin/orders',
    component: OrdersComponent,
    data: { title: 'Заказы', area: 'orders', section: 'admin' },
    canActivate: [AdminGuardService]
  },
/*  {
    path: 'admin/options',
    component: OptionsComponent,
    data: { title: 'Опции', area: 'options', section: 'admin' },
    canActivate: [AdminGuardService]
  },
  {
    path: 'admin/dims',
    component: DimsComponent,
    data: { title: 'Словари', area: 'dims', section: 'admin' },
    canActivate: [AdminGuardService]
  },
  {
    path: 'admin/access',
    component: AccessComponent,
    data: { title: 'Доступ', area: 'access', section: 'admin' },
    canActivate: [AdminGuardService]
  }*/

  { path: 'admin', redirectTo: '/admin/signin', pathMatch: 'full' },
  {
    path: 'admin/:page',
    component: PageNotFoundComponent,
    data: { title: 'Страница не найдена', area: 'page-404', section: 'admin' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
