import { Component, OnInit } from '@angular/core';
import {DataTable} from '../../_helpers/dataTable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RootService} from '../../_services/root.service';
import {ToastaService} from 'ngx-toasta';
import {NamedService} from '../../_services/named.service';
import {RootAuthenticationService} from '../../_services/root-authentication.service';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {CategoryModalComponent} from './category-modal/category-modal.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html'
})
export class CategoriesComponent extends DataTable implements OnInit {

  imgPath;

  constructor(
    private modalService: NgbModal,
    private rootService: RootService,
    private toastr: ToastaService,
    private namedService: NamedService,
    public auth: RootAuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.rootService.getCategoryImgPath()
      .subscribe(data => {
        this.imgPath = data.path;
      },
        err => {
          this.error = err.error;
        }
    );

    const columns = [
      // { data: 'id' },
      { data: 'name' },
      { data: 'position' }
    ];

    if (this.auth.hasRight('%com.zabiray.categories%', 'delete')) {
      columns.unshift({ data: '' });
    }

    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        columns: columns,
        order: []
      }
    );

    this.loadData();
  }

  loadData() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.rootService.getCategories(dataTablesParameters, '/count')
        .pipe(first())
        .subscribe(countData => {
            console.log(countData);
            const count = countData['count'];

            this.rootService.getCategories(dataTablesParameters)
              .pipe(first())
              .subscribe(data => {
                  if (data.length > dataTablesParameters.length) {
                    data.pop();
                  }

                  this.dataList = data;

                  callback({
                    recordsTotal: count,
                    recordsFiltered: count,
                    // recordsTotal: dataTablesParameters.start + count,
                    // recordsFiltered: this.dataList.length,
                    data: { data: this.dataList }
                  });
                },
                err => {
                  this.error = err.error;
                }
              );
          },
          err => {
            this.error = err.error;
          }
        );
    };
  }

  onDelete() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      while (this.selectedList.length > 0) {
        const id = this.selectedList.shift();
        this.rootService.deleteCategory(id)
          .pipe(first())
          .subscribe(
            data => {
              if (!this.selectedList.length) {
                this.reload();

                this.toastr.success('Данные успешно удалены');
              }
            },
            err => {
              this.error = err.error;
            }
          );
      }
    });
  }

  onShowPop(obj = null) {
    if (!this.auth.hasRight('%com.zabiray.categories%', 'post')) {
      return false;
    }

    const modalRef = this.modalService.open(
        CategoryModalComponent,
        { backdrop: 'static' }
      );

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.imgPath = this.imgPath;
    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.reload();

      switch ($e.action || $e) {
        case 'save':
          this.toastr.success('Данные успешно сохранены');
          break;
      }
    });
  }

}
