import { Component, OnInit } from '@angular/core';
import {first} from 'rxjs/operators';
import {DataForm} from '../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {PasswordValidation} from '../../_helpers/passwordValidation';
import {DeleteModalComponent} from './delete-modal/delete-modal.component';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {AcceptPasswordModalComponent} from './accept-password-modal/accept-password-modal.component';

@Component({
  selector: 'app-page-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.scss']
})
export class PageSettingsComponent extends DataForm implements OnInit {

  userData: object;

  deleting: boolean;
  deleteError;

  ngOnInit() {
    this.userService.getPersonalData()
      .pipe(first())
      .subscribe(
        data => {
          this.userData = data;

          // this.userData.userBanksLst[0].id = 26;
        },
        err => {
          this.error = err.error;
        }
      );

    super.ngOnInit();
  }

  initForm(): void {
    this.dataForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', [Validators.required, Validators.minLength(2)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: PasswordValidation.matchPassword
    });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.auth.requestUpdate(this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          const modalRef = this.modalService.open(
            AcceptPasswordModalComponent,
            { backdrop: 'static' }
          );
          modalRef.componentInstance.number = this.auth.currentUserValue.login;
          modalRef.componentInstance.oldpassword = this.f.oldPassword.value;
          modalRef.componentInstance.password = this.f.password.value;

          modalRef.componentInstance.successEvent.subscribe(($e) => {
            this.success = true;
          });
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        });
  }

  onDelete() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.body = 'Вы точно уверены что хотите удалить аккаунт?';

    modalRef.componentInstance.submit.subscribe(($e) => {
      this.deleting = true;
      this.error = '';

      this.userService.requestDelete()
        .pipe(first())
        .subscribe(
          data => {
            // this.onStopSubmit();
            this.deleting = false;

            const modalDelRef = this.modalService.open(DeleteModalComponent);
            modalDelRef.componentInstance.number = this.auth.currentUserValue.login;
          },
          err => {
            // this.onStopSubmit();
            this.error = err.error;
            this.loading = false;
          }
        );
    });
  }

}
