import { Component, OnInit } from '@angular/core';
import {DataTable} from '../../_helpers/dataTable';
import {RootService} from '../../_services/root.service';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserModalComponent} from './user-modal/user-modal.component';
import {ToastaService} from 'ngx-toasta';
import {NamedService} from '../../_services/named.service';
import {UserRoleCodeEnum} from '../../_business/UserRoleCode.enum';
import {UserStatusCodeEnum} from '../../_business/UserStatusCode.enum';
import {Named} from '../../_business/named';
import {RootAuthenticationService} from '../../_services/root-authentication.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent extends DataTable implements OnInit {

  roles: Named[];
  statuses: Named[];
  userStatusCodeEnum = UserStatusCodeEnum;

  filterFio = '';
  filterLogin = '';

  statusOptionsModel: number[];
  roleOptionsModel: number[];

  constructor(
    private modalService: NgbModal,
    private rootService: RootService,
    private toastr: ToastaService,
    private namedService: NamedService,
    public auth: RootAuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.namedService.getNamedList('userstatus')
      .subscribe(data => {
        this.statuses = data;
      });

    this.namedService.getNamedList('role')
      .subscribe(data => {
        this.roles = data;

        for (let k = 0; k < this.roles.length; k++) {
          if (
            this.roles[k].code === UserRoleCodeEnum.client ||
            this.roles[k].code === UserRoleCodeEnum.store
            // [
            //   UserRoleCodeEnum.client,
            //   UserRoleCodeEnum.store
            // ].indexOf(this.roles[k].code) >= 0
          ) {
            this.roles.splice(k, 1);
            k--;
          }
        }
      });

    const columns = [
      { data: 'id' },
      { data: 'login' },
      { data: 'fullNameStr' },
      { data: 'roleId' },
      { data: 'statusId' }
    ];

    if (this.auth.hasRight('%/webresources/com.zabirai.root.user/users%', 'delete')) {
      columns.unshift({ data: '' });
    }

    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        columns: columns,
        order: [[1, 'desc']]
      }
    );

    this.loadData();
  }

  loadData() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      dataTablesParameters.filterFio = this.filterFio;
      dataTablesParameters.filterLogin = this.filterLogin;

      if (this.statusOptionsModel && this.statusOptionsModel.length) {
        dataTablesParameters.filterStatus = this.statusOptionsModel.join(':');
      }

      if (this.roleOptionsModel && this.roleOptionsModel.length) {
        dataTablesParameters.filterRole = this.roleOptionsModel.join(':');
      }

      this.rootService.getUsers(dataTablesParameters, '/count')
        .pipe(first())
        .subscribe(countData => {
          console.log(countData);
          const count = countData['count'];

          this.rootService.getUsers(dataTablesParameters)
            .pipe(first())
            .subscribe(data => {
                if (data.length > dataTablesParameters.length) {
                  data.pop();
                }

                this.dataList = data;

                callback({
                  recordsTotal: count,
                  recordsFiltered: count,
                  // recordsTotal: dataTablesParameters.start + count,
                  // recordsFiltered: this.dataList.length,
                  data: { data: this.dataList }
                });
              },
              err => {
                this.error = err.error;
              }
            );
          },
          err => {
            this.error = err.error;
          }
        );
    };
  }

  getRoleById(id: number) {
    if (this.roles) {
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].id === id) {
          return this.roles[i];
        }
      }
    }

    return null;
  }

  getRoleByCode(code: string) {
    if (this.roles) {
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].code === code) {
          return this.roles[i];
        }
      }
    }

    return null;
  }

  onShowPop(obj = null) {
    if (!this.auth.hasRight('%/webresources/com.zabirai.root.user/users%', 'post')) {
      return false;
    }

    const modalRef = this.modalService.open(
      UserModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.roles = this.roles;
    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.reload();

      switch ($e.action || $e) {
        case 'save':
          this.toastr.success('Данные успешно сохранены');
          break;
      }
    });
  }

  onRestore() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Выбранные аккаунты будут разблокированы. Продолжить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStatus(this.userStatusCodeEnum.active);
    });
  }

  onBlock() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Выбранные аккаунты будут заблокированы. Продолжить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStatus(this.userStatusCodeEnum.block);
    });
  }

  toChangeStatus(statusCode: UserStatusCodeEnum) {
    const status = this.utils.getObjFromList(this.statuses, statusCode, 'code');
    this.error = '';

    const id = this.selectedList.shift();

    this.rootService.saveUser({
      id: id,
      statusId: status.id
    })
      .pipe(first())
      .subscribe(
        data => {
          if (this.selectedList.length) {
            this.toChangeStatus(statusCode);
          } else {
            this.reload();
            this.toastr.success(
              status.code === this.userStatusCodeEnum.block
                ? 'Выбранные аккаунты заблокированы'
                : 'Выбранные аккаунты разблокированы'
            );
          }
        },
        err => {
          this.error = err.error;
        }
      );
  }

  onFilter($e) {
    console.log('onChange', $e);
    this.reload();
  }

}
