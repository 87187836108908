import { Component, OnInit } from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Named} from '../../../_business/named';
import {FormControl} from '@angular/forms';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-select-income',
  templateUrl: './select-income.component.html',
  styleUrls: ['./select-income.component.scss']
})
export class SelectIncomeComponent extends DataForm implements OnInit {

  incomes: Named[];
  experienceTypeId = 1;
  attr;

  ngOnInit() {
    this.getIncomes();
    super.ngOnInit();
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        items: this.formBuilder.array([])
      });
  }

  getIncomes(): void {
    this.namedService.getNamedList('incomesrctype')
      .subscribe(
        data => {
          this.incomes = data;

          let d = null;

          if (this.dataForm.value && this.dataForm.value.items) {
            d = this.dataForm.value;
          }

          // const controls = this.banks.map(c => new FormControl(false));
          const controls = [];

          for (const x in this.incomes) {
            const b = this.incomes[x];
            let state = false;

            if (this.obj) {
              for (const y in this.obj) {
                if (this.obj[y].incomeSrcTypeId === b.id) {
                  state = true;

                  if (this.obj[y].incomeSrcTypeId === this.experienceTypeId) {
                    this.attr = this.obj[y].attr;
                  }

                  break;
                }
              }
            }

            controls.push(new FormControl(state));
          }

          this.dataForm =
            this.formBuilder.group({
              items: this.formBuilder.array(controls)
            });

          this.dataForm.valueChanges.subscribe(() => {
            this.toEmitData();
          });

          this.toEmitData();
        }
      );
  }

  toEmitData() {
    const selectedIds = this.dataForm.value.items
      .map((v, i) => v ? { incomeSrcTypeId: this.incomes[i].id } : null)
      .filter(v => v !== null);

    if (this.attr) {
      for (const x in selectedIds) {
        const income = selectedIds[x];

        if (income.incomeSrcTypeId === this.experienceTypeId) {
          income.attr = this.attr;
          break;
        }
      }
    }

    console.log(JSON.stringify(selectedIds));

    this.onResponse.emit({
      valid: this.dataForm.valid,
      data: selectedIds
    });
  }

  onChangeAttr($e) {
    this.attr = $e.target.value;
    console.log(this.attr);
    this.toEmitData();
  }
}
