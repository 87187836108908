import {AfterViewInit, Injectable, OnDestroy, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {Utils} from './utils';

// https://l-lin.github.io/angular-datatables/#/getting-started
// https://datatables.net/reference/option/

@Injectable()
export class DataTable implements OnDestroy, AfterViewInit {
  dataList: any[];
  selectedList: number[] = [];

  debug: boolean = environment.debug;
  utils = Utils;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  error;

  // dataTablesParameters;

  // https://www.npmjs.com/package/angular-2-dropdown-multiselect
  // Settings configuration
  mySettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'glyphicon',
    buttonClasses: 'btn btn-sm btn-default',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: true
  };

// Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Выбрать все',
    uncheckAll: 'Убрать все',
    checked: 'выбран',
    checkedPlural: 'выбраны',
    searchPlaceholder: 'Найти',
    searchEmptyResult: 'Пусто...',
    searchNoRenderText: 'Введите текст...',
    defaultTitle: 'Выбрать',
    allSelected: 'Все выбраны',
  };

  constructor() {
    const language = {
      'processing': 'Подождите...',
      'search': 'Поиск:',
      'lengthMenu': 'Показать _MENU_ записей',
      // 'info': 'Записи с _START_ до _END_ из _TOTAL_ записей',
      // 'infoEmpty': 'Записи с 0 до 0 из 0 записей',
      // 'infoFiltered': '(отфильтровано из _MAX_ записей)',
      'info': '',
      'infoEmpty': '',
      'infoFiltered': 'Всего _MAX_ записей',
      'infoPostFix': 'Всего _MAX_ записей',
      'loadingRecords': 'Загрузка записей...',
      'zeroRecords': 'Записи отсутствуют.',
      'emptyTable': 'В таблице отсутствуют данные',
      'paginate': {
        'first': 'Первая',
        'previous': 'Предыдущая страница',
        'next': 'Следующая страница',
        'last': 'Последняя'
      },
      'aria': {
        'sortAscending': ': активировать для сортировки столбца по возрастанию',
        'sortDescending': ': активировать для сортировки столбца по убыванию'
      }
    };

    this.dtOptions = {
      // pagingType: 'simple',
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      searching: false,
      language: language,
      order: [[0, 'desc']]
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  reload() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(false);
      // Destroy the table first
      // dtInstance.destroy();
      // Call the dtTrigger to rerender again
      // this.dtTrigger.next();
    });
  }

  onSelectObj(event, id: any) {
    if (event.target.checked) {
      this.selectedList.push(id);
    } else {
      const index = this.selectedList.indexOf(id);
      this.selectedList.splice(index, 1);
    }
  }
}
