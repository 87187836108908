import {Component, OnInit} from '@angular/core';
import {UserDataComponent} from '../../user-data.component';

@Component({
  selector: 'app-skin-default',
  templateUrl: './skin-default.component.html',
  styleUrls: ['./../../user-data.component.scss']
})
export class SkinDefaultComponent extends UserDataComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.getBankMovie();
  }

}
