import { Component, OnInit } from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Named} from '../../../_business/named';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-select-bank',
  templateUrl: './select-bank.component.html',
  styleUrls: ['./select-bank.component.scss']
})
export class SelectBankComponent extends DataForm implements OnInit {

  banks: Named[];

  isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera M/i.test(navigator.userAgent.toLowerCase());

  ngOnInit() {
    this.getBanks();
    super.ngOnInit();
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        items: this.formBuilder.array([])
      });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      // bankPartnerId: null,
      salary: false,
      used: false
    });
  }

  addItem(): void {
    this.items.push(this.createItem());
  }

  getBanks(): void {
    // this.namedService.getNamedList('bankpartner')
    this.namedService.getBanks()
      .subscribe(
        banks => {

          // let d = null;
          //
          // if (this.dataForm.value && this.dataForm.value.items) {
          //   d = this.dataForm.value;
          // }

          // const controls = banks.map(c => new FormControl(false));
          let index = -1;
          for (const x in banks) {
            const b = banks[x];
            if (b.name === 'Скрепка') {
              index = banks.indexOf(b, 0);
            } else {
            let state_salary = false, state_used = false;

            if (this.obj) {
              for (const y in this.obj) {
                if (this.obj[y].bankPartnerId === b.id) {
                  if (this.obj[y].salary || !this.obj[y].used) {
                    state_salary = true;
                  }

                  if (this.obj[y].used) {
                    state_used = true;
                  }

                  break;
                }
              }
            }

            // this.addItem();

            this.items.push(this.formBuilder.group({
              // bankPartnerId: null,
              salary: state_salary,
              used: state_used
            }));
          }
          }

          // if (this.obj) {
            // console.log(this.dataForm);
            // console.log(this.obj);
            // this.dataForm.patchValue({items: this.obj});
          // }

          // this.subscribeForm();
          //console.log('banks len');
          //console.log(banks.length);
          if (index > -1) {
            banks.splice(index, 1);
          }
          //console.log(banks.length);
          this.banks = banks;

          this.dataForm.valueChanges.subscribe(() => {
            this.toEmitData();
          });

          this.toEmitData();
        }
      );
  }

  toEmitData() {
    const selectedIds = this.dataForm.value.items
      .map((v, i) => v ? { bankPartnerId: this.banks[i].id, salary: v.salary ? 1 : 0, used: v.used ? 1 : 0 } : null)
      .filter(v => v !== null && (v.salary || v.used));

/*    const selectedIds_used = this.dataForm.value.items_used
      .map((v, i) => v ? { bankPartnerId: this.banks[i].id, used: 1 } : null)
      .filter(v => v !== null);

    if (selectedIds_used) {
      for (const x in selectedIds_used) {
        const bu = selectedIds_used[x];

        let found = false;

        for (const y in selectedIds) {
          const bs = selectedIds[x];

          if (bu.bankPartnerId === bs.bankPartnerId) {
            bs.used = 1;
            found = true;
            break;
          }
        }

        if (!found) {
          selectedIds.push(bu);
        }
      }
    }

    console.log(
      JSON.stringify(selectedIds_used),
      JSON.stringify(selectedIds)
    );*/

    this.onResponse.emit({
      valid: this.dataForm.valid,
      data: selectedIds
    });
  }

  getBankById(id: number) {
    for (const x in this.banks) {
      if (this.banks[x].id === id) {
        return this.banks[x];
      }
    }

    return {};
  }

}