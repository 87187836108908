import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './_services/auth-guard.service';
import { GuestGuardService } from './_services/guest-guard.service';

import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageSignInComponent } from './pages/page-sign-in/page-sign-in.component';
import { PageSignUpComponent } from './pages/page-sign-up/page-sign-up.component';
import { PageSettingsComponent } from './pages/page-settings/page-settings.component';
import {PageContactsComponent} from './pages/page-contacts/page-contacts.component';
import {PageAboutComponent} from './pages/page-about/page-about.component';
import {PageHowItWorksComponent} from './pages/page-how-it-works/page-how-it-works.component';
import {PagePartnersComponent} from './pages/page-partners/page-partners.component';
import {PageMainComponent} from './pages/page-main/page-main.component';
import {PageOrderListComponent} from './pages/page-order-list/page-order-list.component';
import {PageOrderComponent} from './pages/page-order/page-order.component';
import {PagePrivacyComponent} from './pages/page-privacy/page-privacy.component';

const routes: Routes = [
  {
    path: '',
    component: PageMainComponent,
    data: { title: 'Главная', area: 'main', section: 'static' },
    canActivate: [GuestGuardService]
  },
  {
    path: 'signin',
    component: PageSignInComponent,
    data: { title: 'Вход', area: 'signin', section: 'static' },
    canActivate: [GuestGuardService]
  },
  {
    path: 'signup',
    component: PageSignUpComponent,
    data: { title: 'Регистрация', area: 'signup', section: 'cabinet' },
    // canActivate: [GuestGuardService] // тут возможны циклические редиректы
  },

  {
    path: 'contacts',
    component: PageContactsComponent,
    data: { title: 'Контакты', area: 'contacts', section: 'static' }
  },
  {
    path: 'about',
    component: PageAboutComponent,
    data: { title: 'О проекте', area: 'about', section: 'static' }
  },
  {
    path: 'privacy',
    component: PagePrivacyComponent,
    data: { title: 'Политика конфиденциальности', area: 'privacy', section: 'static' }
  },
  {
    path: 'how-it-works',
    component: PageHowItWorksComponent,
    data: { title: 'Как это работает', area: 'how', section: 'static' }
  },
  {
    path: 'partners',
    component: PagePartnersComponent,
    data: { title: 'Магазины', area: 'partners', section: 'static' }
  },

  {
    path: 'settings',
    component: PageSettingsComponent,
    data: { title: 'Настройки', area: 'settings', section: 'cabinet' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'orders',
    component: PageOrderListComponent,
    data: { title: 'Заказы', area: 'orders', section: 'cabinet' },
    canActivate: [AuthGuardService]
  },
  // {
  //   path: 'order/:id',
  //   component: PageOrderComponent,
  //   data: { title: 'Заказ', area: 'order' },
  //   canActivate: [AuthGuardService]
  // },

  // { path: '', redirectTo: '/signin', pathMatch: 'full' },
  // { path: '', redirectTo: '/main?phone=:phone', pathMatch: 'full' },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'Страница не найдена', area: 'page-404', section: 'static' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
