import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { User } from '../_business/user';
import { environment } from '../../environments/environment';
import {map} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {OrderStatusEnum} from '../_business/OrderStatus.enum';
import { Base64 } from 'js-base64';
import {Order} from '../_business/order';
import {ProductTypeEnum} from '../_business/ProductType.enum';


@Injectable()
export class OrderService {

  currentUser: User;

  private sectionMap = new Map();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.initSectionMap();
    this.currentUser = this.authService.currentUserValue;
  }

  private initSectionMap(): void {
    this.sectionMap.set('user-list', 'com.zabirai.userorder/getOrderByUser');
    this.sectionMap.set('user-order', 'com.zabirai.userorder/getOrderById');
    this.sectionMap.set('commit-redirect', 'com.zabirai.userorder/commitOrderRedirect');
    this.sectionMap.set('user-status', 'com.zabirai.userorder/setOrderStatus');
    this.sectionMap.set('delivered', 'com.zabirai.storeoffice/delivered');
    this.sectionMap.set('cancel', 'com.zabirai.storeoffice/cancel');

    this.sectionMap.set('user-payments', 'com.zabirai.userorder/payments');
    this.sectionMap.set('user-credwor', 'com.zabirai.userorder/credwor');
    this.sectionMap.set('user-ordupd', 'com.zabirai.userorder/ordupd');

    this.sectionMap.set('list', 'com.zabirai.storeoffice/orders/list');
    this.sectionMap.set('export', 'com.zabirai.storeoffice/orders/report/trans');
    this.sectionMap.set('order', 'com.zabirai.storeoffice/order');
    this.sectionMap.set('order-withdraw', 'com.zabirai.storeoffice.order.withdraw/updateord');
    this.sectionMap.set('issue', 'com.zabirai.storeoffice/order/issue/');

    this.sectionMap.set('bank-info', 'com.zabirai.entity.bankparams/public');

    this.sectionMap.set('invoice', 'com.zabirai.storeoffice/setinvoice');

    this.sectionMap.set('bank-terms', 'com.zabirai.userorder/terms');

    this.sectionMap.set('return-requisite', 'com.zabirai.storeoffice/return/');
  }

  getBankMovie(bankId: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('bank-info')}/${bankId}/PUBLIC.IBANKMOVIE`
    )
      .pipe(map(data => {
        console.log('getBankMovie', data);
        return data as any;
      }));
  }

  getBankDoc(bankId: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('bank-info')}/${bankId}/PUBLIC.IBANKDOC`
    )
      .pipe(map(data => {
        console.log('getBankDoc', data);
        return data as any;
      }));
  }

  getBankInfo(bankId: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('bank-info')}/${bankId}/PUBLIC.IBANKURL`
    )
      .pipe(map(data => {
        console.log('getBankInfo', data);
        return data as any;
      }));
  }

  getBankLogo(bankId: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('bank-info')}/${bankId}/PUBLIC.BANKLOGO`
    )
      .pipe(map(data => {
        console.log('getBankLogo', data);
        return data as any;
      }));
  }

  getUserOrderList() {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('user-list')}`
    );
  }

  getUserOrder(orderId: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('user-order')}/${orderId}`
    );
  }

  getUserPayments(productId: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('user-payments')}/${productId}`
    );
  }

  getUserCreditable(orderId: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('user-credwor')}/${orderId}`
    );
  }

  getBankTerms(productTypeId: ProductTypeEnum, orderId: number, bankId: number = null) {
    return this.http.get<any[]>(
      `${environment.api}${this.sectionMap.get('bank-terms')}?orderId=${orderId}&productId=${productTypeId}${bankId ? '&bankId=' + bankId : ''}`
    );
  }

  commitOrderRedirect(orderId: number) {
    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('commit-redirect')}/${orderId}`,
      null
    );
  }

  setUserOrderStatus(orderId: number, status: OrderStatusEnum) {
    return this.http.put(
      `${environment.api}${this.sectionMap.get('user-status')}/${orderId}/${status}`,
      null
    )
      .pipe(map(data => {
        console.log('setUserOrderStatus', data);
        return data as any;
      }));
  }

  setDelivered(orderId: number) {
    return this.http.put(
      `${environment.api}${this.sectionMap.get('delivered')}/${orderId}`,
      null
    )
      .pipe(map(data => {
        console.log('setDelivered', data);
        return data as any;
      }));
  }

  setCancel(orderId: number) {
    return this.http.put(
      `${environment.api}${this.sectionMap.get('cancel')}/${orderId}`,
      null
    )
      .pipe(map(data => {
        console.log('setCancel', data);
        return data as any;
      }));
  }

  setUserOrderFields(order) { // Обновить поля заказа (product, totalPrice, initialFee, term, creditAmount)
    return this.http.put(
      `${environment.api}${this.sectionMap.get('user-ordupd')}`,
      order
    )
      .pipe(map(data => {
        console.log('setUserOrderFields', data);
        return data as any;
      }));
  }


  // For Admin
  private getAllParams(o: any) {
    let params: {[k: string]: any} = {};

    if (o) {
      console.log(o);

      const orders = [];

      for (let x = 0; x < o.order.length; x++) {
        orders.push(
          o.columns[o.order[x].column].data + ':' + o.order[x].dir
        );
      }

      params = {
        page: (o.start ? o.start : '') + ':' + o.length,
        sort: orders.join(';')
      };

      let cols = [];

      if (o.search.value) {
        const s = o.search.value;
        cols = [
          `lastName:like:%${s}%`,
          `firstName:like:%${s}%`,
          `middleName:like:%${s}%`,
          `applicationNumber:like:%${s}%`,
          `phoneContact:like:%${s}%`
        ];
      } else if (o.filterFio) {
        const s = o.filterFio;
        cols = [
          `fullNameStr:like:%${s}%`
          // `lastName:like:%${s}%`,
          // `firstName:like:%${s}%`,
          // `middleName:like:%${s}%`
        ];
      }

      for (let x = 0; x < o.columns.length; x++) {
        if (o.columns[x].search.value) {
          const list = o.columns[x].search.value.split(';');

          for (let y = 0; y < list.length; y++) {
            list[y] = o.columns[x].data + ':like:%' + list[y] + '%';
          }

          cols = $.merge(cols, list);
        }
      }

      if (o.filterFromDate && o.filterToDate) {
        cols.push('createDt:btw:' + o.filterFromDate + ':' + o.filterToDate);
      }

      if (o.filterStatus) {
        cols.push('statusId:in:' + o.filterStatus);
      }

      if (o.filterBank) {
        cols.push('bankId:in:' + o.filterBank);
      }

      if (o.filterApplicationNumber) {
        cols.push(`applicationNumber:like:%${o.filterApplicationNumber}%`);
      }

      if (cols.length) {
        console.log('filter', cols.join(';'));
        // params.filters = Base64.encode(cols.join(';'));
        params.filters = cols.join(';');
      }

      console.log('params', params);
    }

    return params;
  }

  getAll(o: any, partUrl: string = '') {
    const params = this.getAllParams(o);

    return this.http.get<Order[]>(
      `${environment.api}${this.sectionMap.get('list')}${partUrl}`,
      { params }
    );
  }

  getAllExport(o: any) {
    const params = this.getAllParams(o);

    const headers = new HttpHeaders({
      'Accept': 'application/octet-stream;charset=UTF8'
    });

    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('export')}`,
      {
        headers,
        params,
        observe: 'response',
        'responseType'  : 'blob' as 'json'
      }
    );
  }

  exportReturnRequisite(orderId, params: any = null) {
    const headers = new HttpHeaders({
      'Accept': 'application/octet-stream;charset=UTF8,application/json'
    });

    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('return-requisite')}${params ? 'request' : 'existing'}/${orderId}`,
      {
        headers,
        params,
        observe: 'response',
        'responseType'  : 'blob' as 'json'
      }
    );
  }

  setInvoice(orderId, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('invoicefile', fileToUpload, fileToUpload.name);
    formData.append('id', orderId);

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('invoice')}?id=${orderId}`,
      formData
    );
  }

  getById(id: number) {
    return this.http.get(
      `${environment.api}${this.sectionMap.get('order')}/${id}`
    );
  }

  create(data) {
    return this.http.post(
      `${environment.api}${this.sectionMap.get('order')}`,
      data
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }

  update(id: number, data) {
    data.id = id;

    return this.http.put<any>(
      `${environment.api}${this.sectionMap.get('order')}`,
      data
    );
  }

  updateWithdraw(id: number, data) {
    data.id = id;

    return this.http.post<any>(
      `${environment.api}${this.sectionMap.get('order-withdraw')}`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete(
      `${environment.api}${this.sectionMap.get('order')}/${id}`
    )
      .pipe(map(data => {
        console.log('delete', data);
        return data as any;
      }));
  }

  issue(id: number) {
    return this.http.put(
      `${environment.api}${this.sectionMap.get('issue')}${id}`,
      null
    )
      .pipe(map(data => {
        console.log('issue', data);
        return data as any;
      }));
  }

}
