export enum UserStatusCodeEnum {
  inactive  = 'na',
  active   = 'a',
  block   = 'b',
  delete   = 'd',
}

/*
[
  {"code": "na", "id": 1, "name": "Не активирован"},
  {"code": "a", "id": 2, "name": "Активирован"},
  {"code": "b", "id": 3, "name": "Заблокирован"},
  {"code": "d", "id": 4, "name": "Удалён"}]
*/
