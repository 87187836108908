import {Component, OnInit} from '@angular/core';
import {UserDataComponent} from '../../user-data.component';

@Component({
  selector: 'app-skin-mt',
  templateUrl: './skin-mt.component.html',
  styleUrls: ['./../../user-data.component.scss']
})
export class SkinMtComponent extends UserDataComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.getBankMovie();
  }

}
