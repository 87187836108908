import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ReportsComponent} from '../stats/reports.component';
import {first} from 'rxjs/operators';
import {PlatformInfoModalComponent} from './platform-info-modal/platform-info-modal.component';


@Component({
  selector: 'app-reports',
  templateUrl: './reports-shops.component.html',
  styleUrls: ['./reports-shops.component.scss'],
  providers: [DatePipe]
})
export class ReportsShopsComponent extends ReportsComponent implements OnInit {

  exporting = false;
  info;

  ngOnInit() {
    super.ngOnInit();

    if (this.auth.hasRight('%com.zabiray.platform.info%', 'get')) {
      this.rootService.getPlatformInfo()
        .pipe(first())
        .subscribe(data => {
            this.info = data;
          },
          err => {
            this.error = err.error;
          });
    }
  }

  reloadReports() {
  }

  onExport(section: string) {
    this.o.filterStores = this.o.filterBanks = null;
    this.o.ignoreBanks = this.o.ignoreStores = false;

    this.o = Object.assign(this.o, this.filters);

    this.exporting = true;
    this.statsError = null;

    this.rootService.exportStoreReport(this.o, section)
      .pipe(first())
      .subscribe(data => {
        this.exporting = false;
        console.log(data);
        // console.log(data.headers.get('content-disposition'));

        // const file = new Blob([Utils.s2ab(atob(data))], { type: 'application/octet-stream' });
        // const file = new Blob([data], { type: 'application/octet-stream' });
        // const file = new Blob([data], { type: 'application/vnd.ms-excel' });

        const file = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });

/*        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        location.replace(fileURL);*/

        const downloadUrl = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = downloadUrl;
        // a.download = 'data.xlsx';

        const f = data.headers.get('content-disposition');

        if (f && f.indexOf('filename') > -1) {
          a.download = decodeURIComponent(f.split('filename=')[1]);
        }

        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      err => {
        this.exporting = false;
        this.statsError = err.error;
      });
  }

  onShowPlatform() {
    const modalRef = this.modalService.open(
      PlatformInfoModalComponent,
      { backdrop: 'static', size: 'lg' }
    );

    modalRef.componentInstance.obj = this.info;
    modalRef.componentInstance.emitData.subscribe(($e) => {
      switch ($e.action || $e) {
        case 'save':
          this.info = $e.obj;
          this.toastr.success('Данные успешно сохранены');
          break;
      }
    });
  }

}
