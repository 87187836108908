import {Component, OnInit, ViewChild} from '@angular/core';
import { Named } from '../../../_business/named';
import {DataForm} from '../../../_helpers/dataForm';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-personaldata',
  templateUrl: './personal-data.component.html',
  styleUrls: ['../page-sign-up.component.scss']
})
export class PersonalDataComponent extends DataForm implements OnInit {

  genders: Named[];

  isAddedValidators = false;
  validators = {
    birthday: []
  };

  @ViewChild('d') d;
  now = new Date();

  minDate = {year: 1919, month: 1, day: 1};
  maxDate = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};

  ngOnInit() {
    this.getGenders();
    super.ngOnInit();
  }

  getGenders(): void {
    this.namedService.getNamedList('gender')
      .subscribe(genders => this.genders = genders);
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        surname: [
          null
        ],
        name: [
          null
        ],
        patronymic: [
          null
          ],
        birthday: [
          null, [ Validators.required ]
        ],
        // birthplace: [
        //   null
        // ],
        dimGenderId: [
          null
        ]
      });
  }

  datepickerToggle() {
      this.d.toggle();

      if (!this.dataForm.get('birthday').value) {
        this.d.navigateTo({year: ((new Date()).getFullYear() - 40), month: 1});
      }
  }
/*  onSubmit() {
    if (!this.onStartSubmit()) {
      this.formService.onFormSubmittedResponse.emit({ result: false, section: this.section});
      return;
    }

    let data = this.dataForm.value;

    if (data.birthday) {
      let b = data.birthday;
      let d = new Date(b.year, b.month - 1, b.day);
      data.birthday = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
    }

    this.userService.postPersonalData(data)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.formService.onFormSubmittedResponse.emit({ result: true, section: this.section});
        },
        error => {
          this.onStopSubmit();
          this.error = error;
          this.formService.onFormSubmittedResponse.emit({ result: false, section: this.section});
        }
      );
  }*/

}
