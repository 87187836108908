import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {Named} from '../../../_business/named';

@Component({
  selector: 'app-resend-bank-modal',
  templateUrl: './resend-bank-modal.component.html',
  styleUrls: ['./resend-bank-modal.component.scss']
})
export class ResendBankModalComponent extends DataForm {

  @Input() orderId: number;
  @Input() banks: Named[];
  @Output() emitData = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'bankId': [(this.banks && this.banks.length ? this.banks[0].id : null), Validators.required]
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const data = this.dataForm.value;
    data.orderId = this.orderId;

    this.adminService.setOrderBank(data)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next('resend');
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onModalClosing() {
    this.emitData.next('refresh');
    this.activeModal.close('Close click');
  }

}
