import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataForm} from "../../../_helpers/dataForm";
import {Named} from "../../../_business/named";
import {Validators} from "@angular/forms";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-identity-document',
  templateUrl: './identity-document.component.html',
  styleUrls: ['../page-sign-up.component.scss']
})
export class IdentityDocumentComponent extends DataForm implements OnInit {

  // section = 'passport';
  types: Named[];
  citizenships: Named[];

  isAddedValidators = false;

  ngOnInit() {
/*    this.formService.onFormSubmitted.subscribe( (section : any) => {
      if (section === this.section)
        this.onSubmit();
    });*/

    this.getTypes();
    this.getCitizenships();
    super.ngOnInit();
  }

  getTypes(): void {
    this.namedService.getNamedList('passporttype')
      .subscribe(types => this.types = types);
  }

  getCitizenships(): void {
    this.namedService.getNamedList('country')
      .subscribe(citizenships => this.citizenships = citizenships);
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        dimPassportTypeId: [
          null
        ],
        num: [
          null
        ],
        privateNum: [
          null
        ],
        issueDate: [
          null, [ Validators.required ]
        ],
        expDate: [
          null
        ],
        citizenshipId: [
          null
        ],
        issuer: [
          null
        ]
      });
  }

/*  onSubmit() {
    if (!this.onStartSubmit()) {
      this.formService.onFormSubmittedResponse.emit({ result: false, section: this.section});
      return;
    }

    this.userService.postIdentityDocumentData(
      this.dataForm.value
    )
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.formService.onFormSubmittedResponse.emit({ result: true, section: this.section});
        },
        error => {
          this.onStopSubmit();
          this.error = error;
          this.formService.onFormSubmittedResponse.emit({ result: false, section: this.section});
        }
      );
  }*/

}
