export enum CommChannelCodeEnum {
  api  = 'api',
  mail   = 'mail',
  cab   = 'cab'
}

/*
[
  {"code":"api","id":1,"name":"API"},
  {"code":"mail","id":2,"name":"почта (XML)"},
  {"code":"cab","id":3,"name":"Кабинет"}
]
*/
