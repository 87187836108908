import { Component, OnInit } from '@angular/core';
import {DataForm} from '../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {PasswordValidation} from '../../_helpers/passwordValidation';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class RootSettingsComponent extends DataForm {

  initForm(): void {
    this.dataForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', [Validators.required, Validators.minLength(2)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: PasswordValidation.matchPassword
    });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.rootAuth.changePassword(
      this.f.oldPassword.value,
      this.f.password.value
    )
      .pipe(first())
      .subscribe(
        data => {
          // this.alertService.success('Регистрация заверешена', true);
          // this.router.navigate(['/login']);
          this.onStopSubmit();
          this.success = true;
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        });
  }

}
