import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';
import {first} from 'rxjs/operators';
import {OrderService} from '../../_services/order.service';

@Component({
  selector: 'app-page-how-it-works',
  templateUrl: './page-how-it-works.component.html',
  styleUrls: ['./page-how-it-works.component.scss']
})
export class PageHowItWorksComponent implements OnInit {

  movie = 'https://www.youtube.com/embed/7kjX0lQgRFY';
  msi_movie = 'https://www.youtube.com/embed/Uv-5g-fTVc4';

  constructor(
    public auth: AuthenticationService,
    private orderService: OrderService
  ) { }

  ngOnInit() {
/*    this.orderService.getBankMovie(1)
      .pipe(first())
      .subscribe(
        data => {
          this.movie = data.paramValue;
        }
      );*/
  }

}
