import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  now = new Date();
  @Input() area: string;
  @Input() section: string;

  constructor(
    public auth: AuthenticationService
  ) { }

  ngOnInit() {
  }

}
