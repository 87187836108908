import { Component, OnInit } from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Named} from '../../../_business/named';
import {FormArray, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-additional-contacts',
  templateUrl: './additional-contacts.component.html',
  styleUrls: ['./additional-contacts.component.scss']
})
export class AdditionalContactsComponent extends DataForm implements OnInit {

  relationships: Named[];

  isAddedValidators = false;
  validators = {
    phone: [ Validators.pattern('^\\+375 \\d{2} \\d{3} \\d{2} \\d{2}$'), Validators.required ]
  };

  ngOnInit() {
    this.getRelationships();
    super.ngOnInit();
  }

  getRelationships(): void {
    this.namedService.getNamedList('relationship')
      .subscribe(relationships => this.relationships = relationships);
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        items: this.formBuilder.array([ this.createItem() ])
      });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      phone: [ null ],
      dimRelationshipId: [ null ],
      fio: [ null ]
    });
  }

  addItem(): void {
    this.items.push(this.createItem());
  }

}
