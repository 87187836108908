import { NgModule } from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RootRoutingModule} from './root-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataTablesModule} from 'angular-datatables';
import {OrderService} from '../_services/order.service';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {LibraryModule} from '../library/library.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RootSignInComponent} from './sign-in/sign-in.component';
import {RootHeaderComponent} from './parts/header/header.component';
import {RootFooterComponent} from './parts/footer/footer.component';
import {RootService} from '../_services/root.service';
import {RootSettingsComponent} from './settings/settings.component';
import {ReportsComponent} from './reports/stats/reports.component';
import {ReportsOrdersComponent} from './reports/orders/reports-orders.component';
import {RootSidebarComponent} from './parts/sidebar/sidebar.component';
import { UsersComponent } from './users/users.component';
import { ClientsComponent } from './clients/clients.component';
import { ShopsComponent } from './shops/shops.component';
import { TermsComponent } from './terms/terms.component';
import { CategoriesComponent } from './categories/categories.component';
import { ReportsTabsComponent } from './reports/tabs/tabs.component';
import {ReportsShopsComponent} from './reports/shops/reports-shops.component';
import { IntervalModalComponent } from './reports/interval-modal/interval-modal.component';
import {UserModalComponent} from './users/user-modal/user-modal.component';
import {ShopModalComponent} from './shops/shop-modal/shop-modal.component';
import { PrioritiesComponent } from './priorities/priorities.component';
import {PriorityModalComponent} from './priorities/priority-modal/priority-modal.component';
import {BankProductModalComponent} from './banks/products/bank-product-modal/bank-product-modal.component';
import {CreditableModalComponent} from './priorities/creditable-modal/creditable-modal.component';
import {RootRememberPasswordModalComponent} from './sign-in/remember-password-modal/remember-password-modal.component';
import {CategoryModalComponent} from './categories/category-modal/category-modal.component';
import {TermModalComponent} from './terms/term-modal/term-modal.component';
import {BankProductsComponent} from './banks/products/bank-products.component';
import {BankListComponent} from './banks/list/bank-list.component';
import {BankEditorModalComponent} from './banks/list/bank-editor-modal/bank-editor-modal.component';
import {BanksTabsComponent} from './banks/tabs/tabs.component';
import {ScoringComponent} from './scoring/scoring.component';
import {ScoringModalComponent} from './scoring/scoring-modal/scoring-modal.component';
import {StorePopModalComponent} from './shops/shop-modal/store-pop-modal/store-pop-modal.component';
import {StoreBankProductModalComponent} from './shops/shop-modal/store-bank-product-modal/store-bank-product-modal.component';
import {PlatformInfoModalComponent} from './reports/shops/platform-info-modal/platform-info-modal.component';
import {BankProductTermsModalComponent} from './banks/list/bank-editor-modal/bank-product-modal/bank-product-terms-modal.component';

@NgModule({
  declarations: [
    RootSignInComponent,
    RootRememberPasswordModalComponent,
    RootHeaderComponent,
    RootFooterComponent,
    RootSettingsComponent,
    RootSidebarComponent,
    ReportsComponent,
    ReportsOrdersComponent,
    ReportsShopsComponent,
    UsersComponent,
    ClientsComponent,
    ShopsComponent,
    BankProductsComponent,
    BankListComponent,
    TermsComponent,
    CategoriesComponent,
    ReportsTabsComponent,
    BanksTabsComponent,
    IntervalModalComponent,
    UserModalComponent,
    ShopModalComponent,
    PrioritiesComponent,
    PriorityModalComponent,
    BankProductModalComponent,
    BankEditorModalComponent,
    CreditableModalComponent,
    CategoryModalComponent,
    TermsComponent,
    TermModalComponent,
    ScoringComponent,
    ScoringModalComponent,
    StorePopModalComponent,
    StoreBankProductModalComponent,
    PlatformInfoModalComponent,
    BankProductTermsModalComponent
  ],
  imports: [
    LibraryModule,
    RootRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DataTablesModule,
    MultiselectDropdownModule,
    BrowserAnimationsModule
  ],
  exports: [
    RootHeaderComponent,
    RootFooterComponent,
    RootSidebarComponent
  ],
  providers: [
    Title,
    RootService,
    OrderService
  ],
  entryComponents: [
    IntervalModalComponent,
    UserModalComponent,
    ShopModalComponent,
    PriorityModalComponent,
    BankProductModalComponent,
    BankEditorModalComponent,
    CreditableModalComponent,
    RootRememberPasswordModalComponent,
    CategoryModalComponent,
    TermModalComponent,
    ScoringModalComponent,
    StorePopModalComponent,
    StoreBankProductModalComponent,
    PlatformInfoModalComponent,
    BankProductTermsModalComponent
  ]
})
export class RootModule { }
