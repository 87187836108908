import {Component, Input, OnInit} from '@angular/core';
import {DataForm} from '../../../../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {ProductTypeEnum} from '../../../../../_business/ProductType.enum';
import {ConfirmModalComponent} from '../../../../../parts/confirm-modal/confirm-modal.component';
import {first} from 'rxjs/operators';
import {Utils} from '../../../../../_helpers/utils';

declare var jQuery: any;

@Component({
  selector: 'app-bank-product-terms-modal',
  templateUrl: './bank-product-terms-modal.component.html'
})
export class BankProductTermsModalComponent extends DataForm implements OnInit {

  // @Input() stores;
  @Input() productTypes;
  @Input() bankId;

  productTypeEnum = ProductTypeEnum;
  termsStr = '';

  termList = [];
  stores = [];

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'productId': [
          null, [Validators.required]
        ],
        // 'terms': [
        //   [], [Validators.required]
        // ],
        items: this.formBuilder.array([])
      });

    // if (Utils.getObjFromList(this.productTypes, this.productTypeEnum.installment)) {
    //   this.dataForm.get('productId').setValue(
    //     this.productTypeEnum.installment,
    //     { emitEvent: false }
    //   );
    // } else
    if (this.productTypes.length) {
      this.dataForm.get('productId').setValue(
        this.productTypes[0].id,
        { emitEvent: false }
      );
    }

    if (this.obj) {
      this.dataForm.get('productId').disable();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.rootService.getTerms()
      .subscribe(data => {
          this.termList = data;

          for (let x = 0; x < this.termList.length; x++) {
            const b = this.termList[x];

            const obj = {
              isActive: 0,
              termId: b.id,
              term: b.term,
              rate: [ null, Validators.min(0.1) ]
            };

            if (this.obj && this.obj.btCommissions) {
              for (let y = 0; y < this.obj.btCommissions.length; y++) {
                const c = this.obj.btCommissions[y];

                if (+b.id === +c.termId) {
                  obj.isActive = c.isActive;
                  obj.rate = c.rate;
                  break;
                }
              }
            }

            this.items.push(this.formBuilder.group(obj));
          }

          this.dataForm.valueChanges.subscribe(() => {
            this.toEmitData();
          });

          this.toEmitData();

          if (this.obj) {
            // this.dataForm.controls.terms.patchValue(this.obj.terms);
            // this.dataForm.get('terms').setValue(this.obj.terms, {emitEvent: false});
          }
        },
        err => {
          this.error = err.error;
        });
  }

  toEmitData() {
    const selected = this.dataForm.value.items
      .map((v, i) => v ? v : null)
      .filter(v => v !== null && (v.isActive));

    const list = [];

    for (let x = 0; x < selected.length; x++) {
      list.push(selected[x].term);
    }

    this.termsStr = list.length ? list.join(', ') : '';

    // this.onResponse.emit({
    //   valid: this.dataForm.valid,
    //   data: selected
    // });
  }

  isTermErrors() {
    for (let x = 0; x < this.dataForm.value.items.length; x++) {
      const item = this.dataForm.value.items[x];

      if (item.isActive && !item.rate) {
        return true;
      }
    }

    return false;
  }

  onSubmit() {
    if (this.isTermErrors()) {
      jQuery('#termList').addClass('show');
    }

    if (!this.onStartSubmit() || !this.termsStr) {
      this.loading = false;
      return;
    }

    const value = this.obj || {};
    value.bankId = this.bankId;
    value.btCommissions = [];

    if (!this.obj) {
      value.productId = +this.dataForm.value.productId;
    }

    for (let x = 0; x < this.dataForm.value.items.length; x++) {
      const item = this.dataForm.value.items[x];

      if (item.isActive) {
        item.isActive = 1;
        value.btCommissions.push(item);
      }
    }

    const serviceBankTerms =
      this.obj
        ? this.rootService.saveBankTerms(value)
        : this.rootService.createBankTerms(value);

    serviceBankTerms
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next({
            action: this.obj ? 'save' : 'create',
            obj: value
          });
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onDelete() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.rootService.deleteBankTerms(this.obj.id)
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();
            this.emitData.next({
              action: 'delete',
              obj: this.obj
            });
            this.activeModal.close('Close click');
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    });
  }

  onActive(event, control) {
    const validators = [ Validators.min(0.1) ];

    if (event.target.checked) {
      validators.push(Validators.required);
    }

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

}
