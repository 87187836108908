import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RootService} from '../../_services/root.service';
import {ToastaService} from 'ngx-toasta';
import {RootAuthenticationService} from '../../_services/root-authentication.service';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {TermModalComponent} from './term-modal/term-modal.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html'
})
export class TermsComponent implements OnInit {

  debug: boolean = environment.debug;
  dataList: any[];
  selectedList = [];
  error;

  constructor(
    private modalService: NgbModal,
    private rootService: RootService,
    private toastr: ToastaService,
    public auth: RootAuthenticationService
  ) {
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.dataList = this.error = null;

    this.rootService.getTerms()
      .subscribe(data => {
          this.dataList = data;
        },
        err => {
          this.error = err.error;
        });
  }

  onDelete() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      while (this.selectedList.length > 0) {
        const id = this.selectedList.shift();
        this.rootService.deleteTerm(id)
          .pipe(first())
          .subscribe(
            data => {
              if (!this.selectedList.length) {
                this.reload();

                this.toastr.success('Данные успешно удалены');
              }
            },
            err => {
              this.error = err.error;
            }
          );
      }
    });
  }

  onShowPop(obj = null) {
    if (!this.auth.hasRight('%com.zabiray.bank.product.terms%', 'post')) {
      return false;
    }

    const modalRef = this.modalService.open(
        TermModalComponent,
        { backdrop: 'static' }
      );

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.reload();

      switch ($e.action || $e) {
        case 'save':
          this.toastr.success('Данные успешно сохранены');
          break;
      }
    });
  }

  onSelectObj(event, id: any) {
    if (event.target.checked) {
      this.selectedList.push(id);
    } else {
      const index = this.selectedList.indexOf(id);
      this.selectedList.splice(index, 1);
    }
  }

}
