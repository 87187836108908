import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {StoreStatusCodeEnum} from '../../../_business/StoreStatusCode.enum';
import {StorePopModalComponent} from './store-pop-modal/store-pop-modal.component';
import {FormArray, Validators} from '@angular/forms';
import {StoreBankProductModalComponent} from './store-bank-product-modal/store-bank-product-modal.component';
import {CommChannelCodeEnum} from '../../../_business/CommChannelCode.enum';

declare var jQuery: any;

@Component({
  selector: 'app-access-modal',
  templateUrl: './shop-modal.component.html',
  styleUrls: ['./shop-modal.component.scss']
})
export class ShopModalComponent extends DataForm implements OnInit {

  @Input() storeStatusList;

  @Input() coopTermList;
  @Input() channelList;
  @Input() roleList;
  @Input() userStatusList;
  @Input() productTypes;
  @Input() userList;

  @Output() emitData = new EventEmitter();

  // bankList;
  banks;
  storeList;
  partnerBanks;
  partnerBanksStr = '';
  bankProducts;

  registering = false;
  toLoadPartnerBanks = false;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'unp': [
          '', [Validators.required]
        ],
        'name': [
          '', [Validators.required]
        ],
        'addr': '',
        'bankDetails': '',
        'phone': '',
        'managerId': [
          '', [Validators.required]
        ],
        'executiveFio': '',
        'executiveFioGen': '',
        'executive': '',
        'statute': '',
        'email': '',
        // 'bank': '',
        'inEmailAddr': '',
        'inEmailPass': '',
        'contractDate': null,
        'contractNum': '',
        'coopTermId': [
          '', [Validators.required]
        ],
        'commChannelId': [
          '', [Validators.required]
        ],
        items: this.formBuilder.array([])
      });

    this.rootService.getPriorityBanks()
      .subscribe(data => {
        this.banks = data;
      });

    // this.rootService.getBankList()
    //   .subscribe(data => {
    //       this.bankList = data;
    //     }
    //   );

    this.onLoad();
  }

  ngOnInit() {
    super.ngOnInit();

    setTimeout(function () {
      jQuery('select.sp.custom').selectpicker({
        actionsBox: false,
        countSelectedText: 1
      });
    });
  }

  getBankProducts() {
    this.rootService.getPartnerBankProducts(this.obj.id)
      .pipe(first())
      .subscribe(
        data => {
          this.bankProducts = data;
        },
        err => {
          this.error = err.error;
        }
      );
  }

  onLoad() {
    if (!this.obj) {
      return;
    }

    this.loading = true;

    this.getBankProducts();

    this.rootService.getStorePartner(this.obj.id)
      .pipe(first())
      .subscribe(
        data => {
          this.dataForm.patchValue(data);
          this.obj = data;

          // if (this.storePartner.managerId) {
          //   this.dataForm.get('managerId').setValue(this.storePartner.managerId, {emitEvent: false});
          // }

          this.rootService.getPartnerStoreChannels(this.obj.id)
            .pipe(first())
            .subscribe(
              innerData => {
                // this.channelList = innerData;
                const ids = [], select = [];

                // innerData.find(x => ids.push(x.id));
                for (let x = 0; x < innerData.length; x++) {
                  const id = innerData[x].id;
                  ids.push(id);
                  select.push((+id - 1) + ": '" + id + "'");
                }

                this.dataForm.get('commChannelId').setValue(ids, {emitEvent: false});

                setTimeout(function () {
                  jQuery('#selectStoreChannels').selectpicker('val', select);
                  // jQuery('#selectStoreChannels').selectpicker('refresh');
                });
              },
              err => {
                this.error = err.error;
              }
            );
        },
        err => {
          this.error = err.error;
        }
      );

    this.rootService.getPartnerStores({
      storePartnerId: this.obj.id
    })
      .pipe(first())
      .subscribe(
        data => {
          this.storeList = data;
          // this.dataForm.get('createDt').setValue(this.obj.createDt,{emitEvent: false});
        },
        err => {
          this.error = err.error;
        }
      );

    this.getPartnerBanks();
  }

  toEmitData() {
    const selected = this.dataForm.value.items
      .map((v, i) => v ? v : null)
      .filter(v => v !== null && (v.isActive));

    const list = [];

    for (let x = 0; x < selected.length; x++) {
      list.push(selected[x].bankName);
    }

    this.partnerBanksStr = list.length ? list.join(', ') : '';

    // this.onResponse.emit({
    //   valid: this.dataForm.valid,
    //   data: selected
    // });
  }

  isRegister() {
    const status = this.utils.getObjFromList(this.storeStatusList, StoreStatusCodeEnum.create, 'code');

     return !this.obj || this.obj.statusId === status.id;
  }

  isMailXML() {
    const mailChannel = this.utils.getObjFromList(this.channelList, CommChannelCodeEnum.mail, 'code');

    return this.dataForm.value.commChannelId && (
      +this.dataForm.value.commChannelId === +mailChannel.id ||
      this.dataForm.value.commChannelId.find && this.dataForm.value.commChannelId.find(x => +x === mailChannel.id)
    );
  }

  onShowStorePop(obj = null) {
    if (!obj && !this.obj) {
      this.onSubmit((): void => {
        this.onShowStorePop();
      });
      return;
    }

    const modalRef = this.modalService.open(
      StorePopModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.storePartnerId = this.obj.id;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      switch ($e.action || $e) {
        case 'save':
          this.toastr.success('Данные интернет-магазина успешно сохранены');

          for (let k = 0; k < this.storeList.length; k++) {
            if (+this.storeList[k].id === +$e.obj.id) {
              this.storeList[k] = $e.obj;
              break;
            }
          }

          break;

        case 'delete':
          this.toastr.success('Интернет-магазин успешно удален');

          for (let k = 0; k < this.storeList.length; k++) {
            if (+this.storeList[k].id === +$e.obj.id) {
              this.storeList.splice(k, 1);
              break;
            }
          }

          break;

        case 'create':
          this.toastr.success('Интернет-магазин успешно создан');
          this.onLoad();
          break;
      }
    });
  }

  getPartnerBanks() {
    this.rootService.getPartnerBanks(this.obj.id)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.partnerBanks = data;

          const control = <FormArray>this.dataForm.controls['items'];

          for (let i = control.length - 1; i >= 0; i--) {
            control.removeAt(i);
          }

          for (let x = 0; x < this.partnerBanks.length; x++) {
            const b = this.partnerBanks[x];

            this.items.push(this.formBuilder.group({
              isActive: b.isActive,
              bankId: b.bankId,
              bankName: b.bankName,
              login: b.login,
              password: b.password// ,
              // shopCode: b.shopCode,
              // shopName: b.shopName
            }));
          }

          this.dataForm.valueChanges.subscribe(() => {
            this.toEmitData();
          });

          this.toEmitData();
        },
        err => {
          this.loading = false;
          this.error = err.error;
        }
      );
  }

  onShowPartnerBanks() {
    if (!this.obj) {
      this.onSubmit((): void => {
        this.getPartnerBanks();
        this.toLoadPartnerBanks = true;
      });
    }
  }

  onShowBankPop(obj = null) {
    if (!obj && !this.obj) {
      this.onSubmit((): void => {
        this.onShowBankPop();
      });
      return;
    }

    const modalRef = this.modalService.open(
      StoreBankProductModalComponent,
      { backdrop: 'static' }
    );

    if (obj) {
      // FIXME: wtf
      delete obj.items;

      // modalRef.componentInstance.banks = this.banks;
    }

    modalRef.componentInstance.banks = [];

    console.log('items', this.dataForm.value.items);

    for (let x = 0; x < this.dataForm.value.items.length; x++) {
      const partnerBank = this.dataForm.value.items[x];

      if (partnerBank.isActive || obj && obj.bankId === partnerBank.bankId) {
        partnerBank.id = partnerBank.bankId;
        partnerBank.name = partnerBank.bankName;
        modalRef.componentInstance.banks.push(partnerBank);
      }
    }

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.productTypes = this.productTypes;
    modalRef.componentInstance.coopTermList = this.coopTermList;
    modalRef.componentInstance.storePartnerId = this.obj.id;
    modalRef.componentInstance.coopTermId = this.dataForm.value.coopTermId;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      switch ($e.action || $e) {
        case 'save':
          for (let k = 0; k < this.bankProducts.length; k++) {
            if (+this.bankProducts[k].id === +$e.obj.id) {
              this.bankProducts[k] = $e.obj;
              break;
            }
          }

          break;

        case 'delete':
          for (let k = 0; k < this.bankProducts.length; k++) {
            if (+this.bankProducts[k].id === +$e.obj.id) {
              this.bankProducts.splice(k, 1);
              break;
            }
          }

          break;

        case 'create':
          // this.bankProducts.push($e.obj);
          this.getBankProducts();
          break;
      }
    });
  }

  onSubmit(callback = null) {
    if (!this.onStartSubmit()) {
      return;
    }

    const partnerBanks = this.dataForm.value.items;
    delete this.dataForm.value.items;

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    const serviceStorePartner =
      this.obj
        ? this.rootService.saveStorePartner(this.dataForm.value)
        : this.rootService.createStorePartner(this.dataForm.value);

    serviceStorePartner
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          if (callback) {
            if (data) {
              this.obj = data;
            }

            callback();
          } else {
            const id = this.obj ? this.obj.id : data.id;

            for (let x = 0; x < partnerBanks.length; x++) {
              partnerBanks[x].isActive = partnerBanks[x].isActive ? 1 : 0;
            }

            // this.rootService.savePartnerBankProducts(this.bankProducts)
            //   .pipe(first())
            //   .subscribe(
            //     innerData => {
                  this.rootService.savePartnerBanks(id, partnerBanks)
                    .pipe(first())
                    .subscribe(
                      innerInnerData => {
                        this.emitData.next(this.dataForm);
                        this.activeModal.close('Close');
                      },
                      err => {
                        this.onStopSubmit();
                        this.error = err.error;
                      }
                    );

                    this.rootService.savePartnerStoreChannels(id, this.dataForm.value.commChannelId)
                      .pipe(first())
                      .subscribe(
                        innerInnerData => {
                        },
                        err => {
                          this.error = err.error;
                        }
                      );
              //   },
              //   err => {
              //     this.onStopSubmit();
              //     this.error = err.error;
              //   }
              // );
          }
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onRegister() {
      this.onSubmit((): void => {
        const status = this.utils.getObjFromList(this.storeStatusList, StoreStatusCodeEnum.register, 'code');
        this.error = '';
        this.registering = true;

        this.rootService.saveStorePartnerStatus(this.obj.id, status.id)
          .pipe(first())
          .subscribe(
            data => {
              this.toastr.success('Магазин-партнер зарегистрирован');
              this.emitData.next(this.dataForm);
              this.activeModal.close('Close');
            },
            err => {
              this.error = err.error;
              this.registering = false;
            }
          );

        // this.emitData.next({
        //   action: 'register',
        //   obj: this.obj
        // });
      });
  }

  onChangeCommChannel($e) {
    let validators = [];

    if (this.isMailXML()) {
      validators = [ Validators.required ];
    }

    this.dataForm.get('inEmailAddr').setValidators(validators);
    this.dataForm.get('inEmailAddr').updateValueAndValidity();

    this.dataForm.get('inEmailPass').setValidators(validators);
    this.dataForm.get('inEmailPass').updateValueAndValidity();
  }

  onModalClosing() {
    if (this.obj) {
      delete this.dataForm.value.items;
      this.dataForm.value.id = this.obj.id;
    }

    super.onModalClosing();
  }

}
