import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {NavigationExtras} from '@angular/router';
import {timer} from 'rxjs';
import {DataForm} from '../../../_helpers/dataForm';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-accept-password-modal',
  templateUrl: './remember-password-modal.component.html',
  styleUrls: ['./remember-password-modal.component.scss']
})
export class RootRememberPasswordModalComponent extends DataForm {

  loadingSending = false;
  disabledSending = true;

  siteKey: string = environment.recaptchaPublicKey;

  @Input() token: string;
  @Input() login: string;

  @Output() successEvent = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        code: [
          null, [
            Validators.required
          ]]
      });

    const secondsCounter = timer(30000);
    secondsCounter.subscribe(n => {
      this.disabledSending = false;
    });
  }

  onAcceptSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.rootAuth.resendPass(
      this.login,
      this.dataForm.value.code
    )
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.success = true;
          this.successEvent.emit();
          // this.activeModal.close();
        },
        err => {
          this.onStopSubmit();

          if (err.status === 406) {
            err.error = 'Неверный код подтверждения';
          }

          this.error = err.error;
        });
  }

  onRepeatSending() {
    if (this.disabledSending) {
      return;
    }

    this.loadingSending = true;
    this.disabledSending = true;

    this.reCaptchaV3Service.execute(this.siteKey, 'root/fgetpsw', (token) => {
      console.log('captcha token: ', token);
      this.error = '';

      this.rootAuth.requestForgetPass(
        this.login,
        token
      )
        .subscribe(
          data => {
            this.loadingSending = false;

            const secondsCounter = timer(30000);
            secondsCounter.subscribe(n => {
              this.disabledSending = false;
            });
          },
          err => {
            this.loadingSending = false;
            this.error = err.error;
            this.disabledSending = false;
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

}
