import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {Utils} from '../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';

declare var jQuery: any;

@Component({
  selector: 'app-creditable-modal',
  templateUrl: './creditable-modal.component.html',
  styleUrls: ['./creditable-modal.component.scss']
})
export class CreditableModalComponent extends DataForm implements OnInit {

  @Input() named: string;
  @Input() stores;
  @Input() selectedStore = null;
  @Input() selectedPriority;
  @Input() banks;
  @Output() emitData = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'storeId': [
          []
        ],
        'bankId': [
          [], [Validators.required]
        ],
        'calcPercent': [
          '', [Validators.required, Validators.max(100)]
        ],
        'adjCoeff': [
          '', [Validators.required]
        ]
      });
  }

  ngOnInit() {
    super.ngOnInit();

    setTimeout(function () {
      jQuery('select.sp.custom').selectpicker({
        actionsBox: true,
        countSelectedText: 1,
        deselectAllText: 'Убрать все',
        selectAllText: 'Выбрать все',
        noneResultsText: 'Не найдено {0}',
        // allSelectedText: 'All',
        // maxHeight: 200,
      });
    });

    if (this.obj) {
      // FIXME: selected values
      const select1 = [], select2 = [];

      for (let i = 0; i < this.stores.length; i++) {
        for (let j = 0; j < this.obj.storeId.length; j++) {
          if (this.stores[i].id === this.obj.storeId[j]) {
            select1.push(i + ": '" + this.obj.storeId[j] + "'");
          }
        }
      }

      for (let i = 0; i < this.banks.length; i++) {
        for (let j = 0; j < this.obj.bankId.length; j++) {
          if (this.banks[i].id === this.obj.bankId[j]) {
            select2.push(i + ": '" + this.obj.bankId[j] + "'");
          }
        }
      }

      setTimeout(function () {
        jQuery('#selectStoreCreditable').selectpicker('val', select1);
        jQuery('#selectBankCreditable').selectpicker('val', select2);
      });
    }
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    this.rootService.createCreditable(this.dataForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next(this.dataForm);
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

}
