import {Component, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {FormArray, Validators} from '@angular/forms';
import {ProductTypeEnum} from '../../../../_business/ProductType.enum';
import {BankStatusTypeEnum} from '../../../../_business/BankStatusType.enum';
import {ConfirmModalComponent} from '../../../../parts/confirm-modal/confirm-modal.component';
import {CoopTermCodeEnum} from '../../../../_business/CoopTermCode.enum';

declare var jQuery: any;

@Component({
  selector: 'app-store-bank-product-modal',
  templateUrl: './store-bank-product-modal.component.html'
})
export class StoreBankProductModalComponent extends DataForm implements OnInit {

  // @Input() stores;
  @Input() productTypes;
  @Input() bankStatusTypes;
  @Input() coopTermList;
  // @Input() selectedStore = null;
  @Input() banks;
  @Input() storePartnerId;
  @Input() coopTermId;

  productTypeEnum = ProductTypeEnum;
  bankStatusTypeEnum = BankStatusTypeEnum;

  termsStr = '';
  termList = [];
  stores = [];
  coopFormatList;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'bankId': [
          '', [Validators.required]
        ],
        'productId': [
          this.productTypeEnum.installment, [Validators.required]
        ],
        'coopFormatId': [
          '', [Validators.required]
        ],
        // 'terms': [
        //   [], [Validators.required]
        // ],
        items: this.formBuilder.array([])
      });

    const coopTerm = this.utils.getObjFromList(this.coopTermList, CoopTermCodeEnum.mixd, 'code');

    // console.log('coopTerm.id=' + (coopTerm ? coopTerm.id : ''), 'coopTermId=' + this.coopTermId);

    if (this.obj || !coopTerm || +this.coopTermId !== +coopTerm.id) {
      this.dataForm.get('coopFormatId').disable();
    }

    if (this.obj) {
      this.dataForm.get('bankId').disable();
      this.dataForm.get('productId').disable();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.getTerms();

    this.namedService.getNamedList('coopformats')
      .subscribe(data => this.coopFormatList = data);

    setTimeout(function () {
      jQuery('select.sp.custom').selectpicker({
        actionsBox: true,
        countSelectedText: 1,
        deselectAllText: 'Убрать все',
        selectAllText: 'Выбрать все',
        noneResultsText: 'Не найдено {0}',
        // allSelectedText: 'All',
        // maxHeight: 200,
      });
    });

    if (this.obj) {
      // this.dataForm.controls.terms.patchValue(this.obj.terms);
      // this.dataForm.get('terms').setValue(this.obj.terms, {emitEvent: false});
    }
  }

  getTerms() {
    const bankId = this.dataForm.value.bankId || (this.obj ? this.obj.bankId : null);
    const productId = this.dataForm.value.productId || (this.obj ? this.obj.productId : null);

    this.termsStr = '';
    this.termList = [];

    const control = <FormArray>this.dataForm.controls['items'];

    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i);
    }

    if (!bankId || !productId) {
      return;
    }

    this.rootService.getTerms(null, `/${bankId}/${productId}`)
      .subscribe(data => {
          this.termList = data;

          if (this.obj) {
            this.rootService.getPartnerBankProductTerms(this.storePartnerId, this.obj.id)
              .subscribe(dataInner => {
                  if (dataInner.coopFormatId) {
                    this.dataForm.get('coopFormatId').setValue(dataInner.coopFormatId, {emitEvent: false});
                  }

                  this.obj.btCommissions = dataInner.btCommissions;
                  this.initTerms();
                },
                err => {
                  this.error = err.error;
                });
          } else {
            this.initTerms();
          }
        },
        err => {
          this.error = err.error;
        });
  }

  initTerms() {
    for (let x = 0; x < this.termList.length; x++) {
      const b = this.termList[x];

      const obj = {
        isActive: 0,
        termId: b.id,
        term: b.term,
        rate: [ null, Validators.min(0.1) ]
      };

      if (this.obj && this.obj.btCommissions) {
        for (let y = 0; y < this.obj.btCommissions.length; y++) {
          const c = this.obj.btCommissions[y];

          if (+b.id === +c.termId) {
            obj.isActive = c.isActive;
            obj.rate = c.rate;
            break;
          }
        }
      }

      this.items.push(this.formBuilder.group(obj));
    }

    this.dataForm.valueChanges.subscribe(() => {
      this.toEmitData();
    });

    this.toEmitData();
  }

  toEmitData() {
    const selected = this.dataForm.value.items
      .map((v, i) => v ? v : null)
      .filter(v => v !== null && (v.isActive));

    const list = [];

    for (let x = 0; x < selected.length; x++) {
      list.push(selected[x].term);
    }

    this.termsStr = list.length ? list.join(', ') : '';

    // this.onResponse.emit({
    //   valid: this.dataForm.valid,
    //   data: selected
    // });
  }

  isTermErrors() {
    for (let x = 0; x < this.dataForm.value.items.length; x++) {
      const item = this.dataForm.value.items[x];

      if (item.isActive && !item.rate) {
        return true;
      }
    }

    return false;
  }

  onSubmit() {
    if (this.isTermErrors()) {
      jQuery('#termList').addClass('show');
    }

    if (!this.onStartSubmit() || !this.termsStr) {
      this.loading = false;
      return;
    }

    const value = this.obj || {};
    value.storePartnerId = this.storePartnerId;

    if (!this.obj) {
      value.productId = +this.dataForm.value.productId;
    }

    if (this.dataForm.value.bankId) {
      value.bankId = +this.dataForm.value.bankId;
    }

    if (this.dataForm.value.coopFormatId) {
      value.coopFormatId = +this.dataForm.value.coopFormatId;
    }

    value.btCommissions = [];

    for (let x = 0; x < this.dataForm.value.items.length; x++) {
      const item = this.dataForm.value.items[x];

      if (item.isActive) {
        item.isActive = 1;
        value.btCommissions.push(item);
      }
    }

    // this.onStopSubmit();
    // this.emitData.next({
    //   action: this.obj ? 'save' : 'create',
    //   obj: value
    // });
    // this.activeModal.close('Close click');

    const servicePartnerBankTerms =
      this.obj
        ? this.rootService.savePartnerBankProducts(value)
        : this.rootService.createPartnerBankProducts(value);

    servicePartnerBankTerms
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next({
            action: this.obj ? 'save' : 'create',
            obj: value
          });
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onDelete() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.rootService.deletePartnerBankProducts(this.storePartnerId, this.obj.id)
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();
            this.emitData.next({
              action: 'delete',
              obj: this.obj
            });
            this.activeModal.close('Close click');
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    });
  }

  onActive(event, control) {
    const validators = [ Validators.min(0.1) ];

    if (event.target.checked) {
      validators.push(Validators.required);
    }

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

}
