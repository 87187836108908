import { Component, OnInit } from '@angular/core';
import {DataForm} from "../../../_helpers/dataForm";

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent extends DataForm {

  isAddedValidators = false;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        expirience: [
          null
        ],
        // realProperty: [
        //   null
        // ],
        // vechicle: [
        //   null
        // ],
        netIncom: [
          null
        ]
      });
  }

}
