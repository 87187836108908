import {Component, OnDestroy, OnInit} from '@angular/core';
import {RootService} from '../../../_services/root.service';
import {PeriodTypeEnum} from '../../../_business/PeriodType.enum';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataTable} from '../../../_helpers/dataTable';
import {NamedService} from '../../../_services/named.service';
import {environment} from '../../../../environments/environment';
import {IntervalModalComponent} from '../interval-modal/interval-modal.component';
import {ToastaService} from 'ngx-toasta';
import {RootAuthenticationService} from '../../../_services/root-authentication.service';

declare var jQuery: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  providers: [DatePipe]
})
export class ReportsComponent extends DataTable implements OnInit, OnDestroy {

  debug: boolean = environment.debug;

  stats;
  statsError;
  distribs;
  distribsError;
  bankAmounts;
  bankAmountsError;

  stores;
  banks;
  periodType;
  periodTypeEnum = PeriodTypeEnum;
  o = <any>{};
  filters = <any>{};

  eventsSubscription;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public rootService: RootService,
    public datePipe: DatePipe,
    public namedService: NamedService,
    public modalService: NgbModal,
    public toastr: ToastaService,
    public auth: RootAuthenticationService
  ) {
    super();

    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        // console.log(event);

        if (event instanceof NavigationEnd) {
          console.log(event);

          // this.route.queryParams.subscribe(params => {
          //   if (params['store']) {
          //     this.filterStores = +params['store'];
          //     this.o.filterStores = params['store'];
          //   }
          // });

          this.periodType = this.route.snapshot.paramMap.get('period');

          console.log('periodType', this.periodType);

          const nd = new Date();

          switch (this.periodType) {
            case this.periodTypeEnum.today:
              this.o.filterFromDate = this.datePipe.transform(nd, 'yyyy-MM-dd');
              this.o.filterToDate = this.datePipe.transform(nd, 'yyyy-MM-dd');
              break;

            case this.periodTypeEnum.yesterday:
              nd.setDate(
                nd.getDate() - 1
              );
              this.o.filterFromDate = this.datePipe.transform(nd, 'yyyy-MM-dd');
              this.o.filterToDate = this.datePipe.transform(nd, 'yyyy-MM-dd');
              break;

            case this.periodTypeEnum.week:
              const from = new Date();
              from.setDate(
                from.getDate() - 7
              );
              this.o.filterFromDate = this.datePipe.transform(from, 'yyyy-MM-dd');
              this.o.filterToDate = this.datePipe.transform(nd, 'yyyy-MM-dd');
              break;

            case this.periodTypeEnum.custom:
              this.route.queryParams.subscribe(params => {
                this.o.filterFromDate = params['from'];
                this.o.filterToDate = params['to'];
              });
              break;
          }

          this.reloadReports();
        }
      });
  }

  ngOnInit() {
    this.namedService.getBankPartners()
      .subscribe(data => {
        this.banks = data;

        this.rootService.getStores()
          .subscribe(dataStores => {
            this.stores = dataStores;

            jQuery('select.sp').selectpicker({
              actionsBox: true,
              liveSearch: true,
              countSelectedText: 1,
              deselectAllText: 'Убрать все',
              selectAllText: 'Выбрать все',
              noneResultsText: 'Не найдено {0}',
              // allSelectedText: 'All',
              // maxHeight: 200,
            });

            jQuery('.selectContainer').each(function () {
              if (!jQuery(this).find('.selectAddition').length) {
                return;
              }

              const group = jQuery(`<div class="btn-group btn-group-sm btn-block"></div>`);
              group.append(
                jQuery(this).find('.selectAddition').children()
              );

              jQuery(this).find('.bs-actionsbox').append(group);

              jQuery(this).find('.bs-deselect-all').click(function () {
                const select = jQuery(this).parents('.selectContainer').find('select.sp');
                select.selectpicker('selectAll');
                select.selectpicker('deselectAll');
              });
            });

          });
      });
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this.eventsSubscription.unsubscribe();
  }

  reloadReports(distribsOnly = false) {
    this.o.filterStores = this.o.filterBanks = null;
    this.o.ignoreBanks = this.o.ignoreStores = false;

    this.o = Object.assign(this.o, this.filters);

    console.log(this.o);

    this.statsError = null;

    if (!distribsOnly) {
      this.stats = null;
      this.statsError = null;
      this.rootService.getStats(this.o)
        .subscribe(data => {
            this.stats = data;
            // this.stats.approvalPercentage.percent = 75;
          },
          err => {
            this.statsError = err.error;
          }
        );
    }

    this.distribs = this.distribsError = null;

    this.rootService.getTimeDistribution(this.o)
      .subscribe(data => {
          // this.distribs = this.intervals;
          //
          // for (const x in data) {
          //   let found = false;
          //
          //   for (const y in this.distribs) {
          //     if (this.distribs[y].name === data[x].name) {
          //       this.distribs[y] = data[x];
          //       found = true;
          //       break;
          //     }
          //   }
          //
          //   if (!found) {
          //     this.distribs.push(data[x]);
          //   }
          // }

          this.distribs = data;
        },
        err => {
          this.distribsError = err.error;
        }
      );

    this.bankAmounts = this.bankAmountsError = null;

    this.rootService.getBankAmounts(this.o)
      .subscribe(data => {
          this.bankAmounts = data;
        },
        err => {
          this.bankAmountsError = err.error;
        }
      );
  }

  getBankById(id: number) {
    if (this.banks) {
      for (let i = 0; i < this.banks.length; i++) {
        if (this.banks[i].id === id) {
          return this.banks[i];
        }
      }
    }

    return null;
  }

  onShowPopInterval(interval = null) {
    const modalRef = this.modalService.open(
      IntervalModalComponent,
      { backdrop: 'static' }
    );

    modalRef.componentInstance.obj = interval;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      console.log('e', $e);
      this.reloadReports(true);
    });
  }

  onEmitData($e) {
    this.filters = $e;
    this.reloadReports();
  }
}
