import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Named } from '../_business/named';
import { environment } from '../../environments/environment';
import { Base64 } from 'js-base64';


@Injectable({
  providedIn: 'root'
})
export class NamedService {

    private sectionMap = new Map();

    constructor(
        private http: HttpClient
    ) {
        this.initSectionMap();
    }

    getNamedList(section: string, o = null): Observable<Named[]> {
      let params: {[k: string]: any} = {};

      if (o) {
        console.log(o);

        const orders = [];

        for (let x = 0; x < o.order.length; x++) {
          orders.push(
            o.columns[o.order[x].column].data + ':' + o.order[x].dir
          );
        }

        params = {
          page: (o.last && o.start ? o.last : '') + ':' + (o.length + 1),
          order: orders.join(';')
        };

        if (o.search.value) {
          const s = o.search.value;
          const cols = [
            `code:like:${s}*`,
            `name:like:${s}*`
          ];

          console.log('filter', cols);
          params.filter = Base64.encode(cols.join(';'));
        }

        console.log('params', params);
      }

      return this.http.get<Named[]>(
        // environment.api + this.sectionMap.get(section)
        `${environment.api}com.zabirai.dims/${section}`,
        { params }
      )
      .pipe(
        tap(_ => this.log(section + ' fetched named list')),
        catchError(this.handleError('getNamedList', []))
      );
    }

  getNamed(section: string): Observable<Named> {
    return this.http.get<Named>(
      // environment.api + this.sectionMap.get(section)
      `${environment.api}com.zabirai.dims/${section}`
    );
  }

  getBanks(): Observable<Named[]> {
    return this.http.get<Named[]>(
      `${environment.api}${this.sectionMap.get('banks')}`
    );
  }

  getBankPartners(): Observable<Named[]> {
    return this.http.get<Named[]>(
      `${environment.api}${this.sectionMap.get('bank-partners')}`
    );
  }

  getStoreBanks(orderId, productId, term = null): Observable<Named[]> {
    return this.http.get<Named[]>(
      `${environment.api}${this.sectionMap.get('store-banks')}?orderId=` + (orderId ? orderId : '') +
            (productId ? '&productId=' + productId : '') +
            (term ? '&term=' + term : '')
    );
  }

  create(section: string, data) {
    return this.http.post(
      `${environment.api}com.zabirai.dims/${section}`,
      data
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }

  delete(section: string, id: number) {
    return this.http.delete(
      `${environment.api}com.zabirai.dims/${section}/${id}`
    )
      .pipe(map(data => {
        console.log('data', data);
        return data as any;
      }));
  }

    private initSectionMap(): void {
      this.sectionMap.set('banks', 'com.zabirai.entity.banks');
      this.sectionMap.set('store-banks', 'com.zabirai.storeoffice.order.bank/banks');
      this.sectionMap.set('bank-partners', 'com.zabiray.bank.partners/banks');
/*      this.sectionMap.set('gender', 'com.zabirai.dimgender');
      this.sectionMap.set('country', 'com.zabirai.dimcountry');
      this.sectionMap.set('passport', 'com.zabirai.dimpassporttype');
      this.sectionMap.set('relationship', 'com.zabirai.entity.dimrelationship');
      this.sectionMap.set('housing', 'com.zabirai.dimhousing');
      this.sectionMap.set('marriage', 'com.zabirai.dimmarriage');
      this.sectionMap.set('street', 'com.zabirai.dimstreettype');
      this.sectionMap.set('education', 'com.zabirai.dimeducationtype');
      this.sectionMap.set('employment', 'com.zabirai.dimemploymenttype');
      this.sectionMap.set('position', 'com.zabirai.dimpositiontype');
      this.sectionMap.set('occupation', 'com.zabirai.dimoccupation');
      this.sectionMap.set('bank', 'com.zabirai.dimbank');
      this.sectionMap.set('orderstatus', 'com.zabirai.dimorderstatus');*/
    }

    private log(message: string) {
        // TODO: don't forget about me
        console.log(message);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
        };
    }
}
