export class Utils {
  public static phoneFormat(phone, login = false) {
    if (!phone || phone.length <= 4) {
      return phone;
    }

    phone = phone.replace(/ |\+|\D/g, '');

    if (login) {
      return phone.replace(/^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/, '+$1($2)$3-$4-$5');
    }

    if (phone.length <= 5) {
      phone = phone.replace(/^(\d{0,3})(\d{0,2})/, '+$1 $2');
    } else if (phone.length <= 8) {
      phone = phone.replace(/^(\d{0,3})(\d{0,2})(\d{0,3})/, '+$1 $2 $3');
    } else if (phone.length <= 10) {
      phone = phone.replace(/^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})/, '+$1 $2 $3 $4');
    } else {
      phone = phone.substring(0, 12);
      phone = phone.replace(/^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/, '+$1 $2 $3 $4 $5');
    }

    return phone;
  }

  public static s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }

    return buf;
  }

  public static isEquivalent(a, b, full = false) {
    console.log('isEquivalent', a, b);

    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length && full) {
      console.log('number of properties is different =>', aProps.length, bProps.length);
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (
        a[propName] !== b[propName] &&
        typeof(a[propName]) !== 'undefined' &&
        typeof(b[propName]) !== 'undefined'
      ) {
        console.log('values of same property are not equal =>', propName);
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  public static getObjFromList(list, find, id = 'id') {
    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i][id] === find) {
          return list[i];
        }
      }
    }

    return null;
  }

  public static export(data) {
    // console.log(data.headers.get('content-disposition'));

    // const file = new Blob([Utils.s2ab(atob(data))], { type: 'application/octet-stream' });
    // const file = new Blob([data], { type: 'application/octet-stream' });
    // const file = new Blob([data], { type: 'application/vnd.ms-excel' });

    const file = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });

    /*        const fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            location.replace(fileURL);*/

    const downloadUrl = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = downloadUrl;
    // a.download = 'data.xlsx';

    const f = data.headers.get('content-disposition');

    if (f && f.indexOf('filename') > -1) {
      a.download = decodeURIComponent(f.split('filename=')[1]);
    }

    const filename = a.download;

    document.body.appendChild(a);
    a.click();
    a.remove();

    return filename;
  }
}
