import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {ProductTypeEnum} from '../../../../_business/ProductType.enum';
import {BankStatusTypeEnum} from '../../../../_business/BankStatusType.enum';
import {Utils} from '../../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../../parts/confirm-modal/confirm-modal.component';
import {Named} from '../../../../_business/named';
import {StoreBankProductModalComponent} from '../../../shops/shop-modal/store-bank-product-modal/store-bank-product-modal.component';
import {BankProductTermsModalComponent} from './bank-product-modal/bank-product-terms-modal.component';

declare var jQuery: any;

@Component({
  selector: 'app-editor-modal',
  templateUrl: './bank-editor-modal.component.html'
})
export class BankEditorModalComponent extends DataForm implements OnInit {

  @Input() banks;
  @Input() productTypes;

  bankProducts;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'bankId': [
          '', [Validators.required]
        ],
        'fullName': [
          '', [Validators.required]
        ],
        'contractNum': [
          '', [Validators.required]
        ],
        'contractDate': [
          '', [Validators.required]
        ]
      });
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.obj) {
      this.onChangeBank();
    }

    setTimeout(function () {
      jQuery('select.sp.custom').selectpicker({
        actionsBox: true,
        countSelectedText: 1,
        deselectAllText: 'Убрать все',
        selectAllText: 'Выбрать все',
        noneResultsText: 'Не найдено {0}',
        // allSelectedText: 'All',
        // maxHeight: 200,
      });
    });
  }

  onChangeBank() {
    if (!this.dataForm.value.bankId) {
      return;
    }

    this.bankProducts = null;

    this.rootService.getBankTerms([
      'bankId:eq:' + this.dataForm.value.bankId
    ])
      .subscribe(dataInner => {
          this.bankProducts = dataInner;
        },
        err => {
          this.error = err.error;
        });
  }

  onShowProductPop(obj = null) {
    if (!obj && !this.obj) {
      this.onSubmit((): void => {
        this.onShowProductPop();
      });
      return;
    }

    const modalRef = this.modalService.open(
      BankProductTermsModalComponent,
      { backdrop: 'static' }
    );

    if (obj) {
      delete obj.items; // FIXME: wtf
      modalRef.componentInstance.productTypes = this.productTypes;
    } else {
      const ptList = Object.assign([], this.productTypes);

      for (let x = 0; x < this.bankProducts.length; x++) {
        const bp = this.bankProducts[x];

        for (let k = 0; k < ptList.length; k++) {
          if (ptList[k].id === bp.productId) {
            ptList.splice(k, 1);
            k--;
          }
        }
      }

      modalRef.componentInstance.productTypes = ptList;
    }

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.bankId = this.dataForm.value.bankId;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      switch ($e.action || $e) {
        case 'save':
          for (let k = 0; k < this.bankProducts.length; k++) {
            if (+this.bankProducts[k].id === +$e.obj.id) {
              this.bankProducts[k] = $e.obj;
              break;
            }
          }

          break;

        case 'delete':
          for (let k = 0; k < this.bankProducts.length; k++) {
            if (+this.bankProducts[k].id === +$e.obj.id) {
              this.bankProducts.splice(k, 1);
              break;
            }
          }

          break;

        case 'create':
          // this.bankProducts.push($e.obj);
          this.onChangeBank();
          break;
      }
    });
  }

  onSubmit(callback = null) {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    const serviceBank =
      this.obj
        ? this.rootService.saveBank(this.dataForm.value)
        : this.rootService.createBank(this.dataForm.value);

    serviceBank
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          if (callback) {
            this.obj = data;
            callback();
          } else {
            this.emitData.next('save');
            this.activeModal.close('Close click');
          }
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onModalClosing() {
    if (this.obj) {
      // if (!this.obj.contractNum) {
      //   this.obj.contractNum = '';
      // }

      if (!this.obj.contractDate) {
        this.obj.contractDate = '';
      }
    }

    super.onModalClosing();
  }

}
