import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {NavigationExtras} from '@angular/router';
import {timer} from 'rxjs';
import {DataForm} from '../../../_helpers/dataForm';

@Component({
  selector: 'app-accept-password-modal',
  templateUrl: './accept-password-modal.component.html',
  styleUrls: ['./accept-password-modal.component.scss']
})
export class AcceptPasswordModalComponent extends DataForm {

  loadingSms = false;
  disabledSms = true;

  @Input() oldpassword: string;
  @Input() password: string;
  @Input() number: string;
  @Input() issueOrderId: number;

  @Output() successEvent = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        code: [
          null, [
            Validators.required
          ]]
      });

    const secondsCounter = timer(30000);
    secondsCounter.subscribe(n => {
      this.disabledSms = false;
    });
  }

  onAcceptSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.auth.changePassword(
      this.oldpassword,
      this.password,
      this.dataForm.value.code
    )
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.successEvent.emit();
          this.activeModal.close();
        },
        err => {
          this.onStopSubmit();

          if (err.status === 406) {
            err.error = 'Неверный код подтверждения';
          }

          this.error = err.error;
        });
  }

  onRepeatSms() {
    if (this.disabledSms) {
      return;
    }

    this.loadingSms = true;
    this.disabledSms = true;
    this.error = '';

    this.auth.requestUpdate(this.password)
      .pipe(first())
      .subscribe(
        data => {
          this.loadingSms = false;

          const secondsCounter = timer(30000);
          secondsCounter.subscribe(n => {
            this.disabledSms = false;
          });
        },
        err => {
          this.loadingSms = false;
          this.error = err.error;
          this.disabledSms = false;
        }
      );
  }

}
