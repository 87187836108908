import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RootService} from '../../_services/root.service';
import {NamedService} from '../../_services/named.service';
import {UserModalComponent} from '../users/user-modal/user-modal.component';
import {PriorityModalComponent} from './priority-modal/priority-modal.component';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {first} from 'rxjs/operators';
import {CreditableModalComponent} from './creditable-modal/creditable-modal.component';
import {RootAuthenticationService} from '../../_services/root-authentication.service';

declare var jQuery: any;

@Component({
  selector: 'app-priorities',
  templateUrl: './priorities.component.html',
  styleUrls: ['./priorities.component.scss']
})
export class PrioritiesComponent implements OnInit, OnDestroy {

  debug: boolean = environment.debug;

  title = 'Таблица приоритетов';
  stores;
  banks;
  filterStore;
  filterStoreName;

  dataList: any[];
  selectedList = [];

  type;
  error;
  eventsSubscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rootService: RootService,
    private namedService: NamedService,
    private modalService: NgbModal,
    public auth: RootAuthenticationService
  ) {
    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        // console.log(event);

        if (event instanceof NavigationEnd) {
          console.log(event);

          // this.route.queryParams.subscribe(params => {
          //   if (params['store']) {
          //     this.filterStores = +params['store'];
          //     this.o.filterStores = params['store'];
          //   }
          // });

          this.type = this.route.snapshot.paramMap.get('type');

          switch (this.type) {
            case 'creditable':
              this.title = 'Параметры расчета кредитоспособности';
              break;

            default:
              this.title = 'Таблица приоритетов';
              break;
          }

          this.reload();
        }
      });
  }

  ngOnInit() {
    this.rootService.getStores()
      .subscribe(data => {
        this.stores = data;

        if (this.stores.length) {
          this.filterStore = this.stores[0].id;
          this.filterStoreName = this.stores[0].name;
        }

        if (this.isShops()) {
          this.reload();
        }

        setTimeout(function () {
          jQuery('select.sp').selectpicker({
            // actionsBox: true,
            liveSearch: true,
            // countSelectedText: 1,
            noneResultsText: 'Не найдено {0}',
            // allSelectedText: 'All',
            // maxHeight: 200,
          });
        }, 500);
      });

    this.namedService.getBanks()
      .subscribe(data => {
        this.banks = data;
      });
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this.eventsSubscription.unsubscribe();
  }

  reload() {
    if (this.isShops() && !this.filterStore) {
      return;
    }

    this.dataList = this.error = null;

    switch (this.type) {
      case 'creditable':
        this.rootService.getCreditable()
          .subscribe(data => {
              this.dataList = data;
            },
            err => {
              this.error = err.error;
            });

        break;

      default:
        this.rootService.getPriority(
          this.isShops() ? { filterStore: this.filterStore } : null
        )
          .subscribe(data => {
              this.dataList = data;
            },
            err => {
              this.error = err.error;
            });

        break;
    }
  }

  isShops() {
    return this.type === 'shops';
  }

  isCreditable() {
    return this.type === 'creditable';
  }

  getStoreById(id: number) {
    if (this.stores) {
      for (let i = 0; i < this.stores.length; i++) {
        if (this.stores[i].id === id) {
          return this.stores[i];
        }
      }
    }

    return null;
  }

  getBankById(id: number) {
    if (this.banks) {
      for (let i = 0; i < this.banks.length; i++) {
        if (this.banks[i].id === id) {
          return this.banks[i];
        }
      }
    }

    return null;
  }

  getStoreNamesByIds(ids) {
    const names = [];

    for (let i = 0; i < ids.length; i++) {
      const store = this.getStoreById(ids[i]);
      names.push(
        store ? store.name : ids[i]
      );
    }

    return names.length ? names : ['Все магазины'];
  }

  getBankNamesByIds(ids) {
    const names = [];

    for (let i = 0; i < ids.length; i++) {
      const bank = this.getBankById(ids[i]);
      names.push(
        bank ? bank.name : ids[i]
      );
    }

    return names;
  }

  onShowPop(obj = null) {
    if (!this.hasRight()) {
      return false;
    }

    let modalRef;

    switch (this.type) {
      case 'creditable':
        modalRef = this.modalService.open(
          CreditableModalComponent,
          { backdrop: 'static' }
        );
        break;

      default:
        modalRef = this.modalService.open(
          PriorityModalComponent,
          { backdrop: 'static' }
        );
        modalRef.componentInstance.selectedPriority = this.dataList.length + 1;

        if (obj) {
          obj.msi = obj.msi === '1';
        }

        if (this.isShops()) {
          modalRef.componentInstance.selectedStore = this.filterStore;
        }

        break;
    }

    modalRef.componentInstance.obj = obj;
    modalRef.componentInstance.banks = this.banks;
    modalRef.componentInstance.stores = this.stores;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.reload();
    });
  }

  onSelectPrior(event, d: any) {
    if (event.target.checked) {
      this.selectedList.push(d.id);
    } else {
      const index = this.selectedList.indexOf(d.id);
      this.selectedList.splice(index, 1);
    }
  }

  hasRight(debug = false) {
    switch (this.type) {
      case 'creditable':
        return this.auth.hasRight('%/webresources/credwor%', 'post');
        break;

      default:
        return this.auth.hasRight('%/webresources/com.zabiray.root.bank.priority%', 'post', debug);
        break;
    }
  }

  toDelete() {
    this.error = '';

    const id = this.selectedList.shift();

    switch (this.type) {
      case 'creditable':
        this.rootService.deleteCreditable(id)
          .pipe(first())
          .subscribe(
            data => {
              for (let x = 0; x < this.dataList.length; x++) {
                const d = this.dataList[x];

                if (d.id === id) {
                  this.dataList.splice(x, 1);
                  break;
                }
              }

              if (this.selectedList.length) {
                this.toDelete();
              }
            },
            err => {
              this.error = err.error;
            }
          );
        break;

      default:
        this.rootService.deletePriority(id)
          .pipe(first())
          .subscribe(
            data => {
              for (let x = 0; x < this.dataList.length; x++) {
                const d = this.dataList[x];

                if (d.id === id) {
                  this.dataList.splice(x, 1);
                  break;
                }
              }

              if (this.selectedList.length) {
                this.toDelete();
              }
            },
            err => {
              this.error = err.error;
            }
          );
        break;
    }
  }

  onDelete() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить выбранные элементы?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toDelete();
    });
  }

  onChangeStore($e) {
    this.reload();
  }

}
