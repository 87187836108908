import {Component, OnInit} from '@angular/core';
import {RootAuthenticationService} from '../../../_services/root-authentication.service';

@Component({
  selector: 'app-root-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class RootSidebarComponent implements OnInit {

  constructor(
    public auth: RootAuthenticationService
  ) { }

  ngOnInit() {
  }

}
