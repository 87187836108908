import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageSignInComponent } from './pages/page-sign-in/page-sign-in.component';
import { PageSignUpComponent } from './pages/page-sign-up/page-sign-up.component';
import { PersonalDataComponent } from './pages/page-sign-up/personal-data/personal-data.component';
import { IdentityDocumentComponent } from './pages/page-sign-up/identity-document/identity-document.component';
import { ContactInformationComponent } from './pages/page-sign-up/contact-information/contact-information.component';
import { UserDataComponent } from './pages/page-sign-up/user-data/user-data.component';
import {UserService} from './_services/user.service';
import { AlertComponent } from './parts/alert/alert.component';
import {AlertService} from './_services/alert.service';

import { HeaderComponent } from './pages/parts/header/header.component';
import { FooterComponent } from './pages/parts/footer/footer.component';

import { ErrorInterceptor } from './_helpers/error.interceptor';
import { TokenInterceptor } from './_helpers/token.interceptor';
import {NgbDateCustomParserFormatter} from './_helpers/NgbDateCustomParserFormatter';
import {FormService} from './_services/form.service';
import { AcceptModalComponent } from './pages/page-sign-up/accept-modal/accept-modal.component';
import { AccommodationComponent } from './pages/page-sign-up/accommodation/accommodation.component';
import { CreditModalComponent } from './pages/page-sign-up/credit-modal/credit-modal.component';
import { CreditsComponent } from './pages/page-sign-up/credits/credits.component';
import { SelectBankComponent } from './pages/page-sign-up/select-bank/select-bank.component';
import { AdditionalContactsComponent } from './pages/page-sign-up/additional-contacts/additional-contacts.component';
import { FamilyStatusComponent } from './pages/page-sign-up/family-status/family-status.component';
import { EducationComponent } from './pages/page-sign-up/education/education.component';
import { EmploymentComponent } from './pages/page-sign-up/employment/employment.component';
import { IncomeComponent } from './pages/page-sign-up/income/income.component';
import {NgbUTCStringAdapter} from './_helpers/NgbUTCStringAdapter';
import { PageSettingsComponent } from './pages/page-settings/page-settings.component';

import localeRu from '@angular/common/locales/ru';
import {registerLocaleData} from '@angular/common';
import {AdminModule} from './admin/admin.module';
import { PageContactsComponent } from './pages/page-contacts/page-contacts.component';
import { PageAboutComponent } from './pages/page-about/page-about.component';
import { PageHowItWorksComponent } from './pages/page-how-it-works/page-how-it-works.component';
import { PagePartnersComponent } from './pages/page-partners/page-partners.component';
import {BsDropdownModule, CollapseModule} from 'ngx-bootstrap';
import {LibraryModule} from './library/library.module';
import {NgxCaptchaModule} from 'ngx-captcha';
import { DeleteModalComponent } from './pages/page-settings/delete-modal/delete-modal.component';
import { ConfirmModalComponent } from './parts/confirm-modal/confirm-modal.component';
import { AcceptPasswordModalComponent } from './pages/page-settings/accept-password-modal/accept-password-modal.component';
import { PageMainComponent } from './pages/page-main/page-main.component';
import { MainInfoComponent } from './pages/parts/main-info/main-info.component';
import { RegistrationComponent } from './pages/parts/registration/registration.component';
import {RememberPasswordModalComponent} from './pages/page-sign-in/remember-password-modal/remember-password-modal.component';
import {RootModule} from './root/root.module';
import {PageOrderListComponent} from './pages/page-order-list/page-order-list.component';
import {PageOrderComponent} from './pages/page-order/page-order.component';
import {SelectIncomeComponent} from './pages/page-sign-up/select-income/select-income.component';
import {NgxBootstrapSliderModule} from 'ngx-bootstrap-slider';
import {SkinDefaultComponent} from './pages/page-sign-up/user-data/skins/skin-default/skin-default.component';
import {SkinInframeComponent} from './pages/page-sign-up/user-data/skins/skin-inframe/skin-inframe.component';
import {SkinBelagpromComponent} from './pages/page-sign-up/user-data/skins/skin-belagprom/skin-belagprom.component';
import {PagePrivacyComponent} from './pages/page-privacy/page-privacy.component';
import {SkinBelarusComponent} from './pages/page-sign-up/user-data/skins/skin-belarus/skin-belarus.component';
import {SkinBelinvestComponent} from './pages/page-sign-up/user-data/skins/skin-belinvest/skin-belinvest.component';
import {SkinMtComponent} from './pages/page-sign-up/user-data/skins/skin-mt/skin-mt.component';

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    PageSignInComponent,
    PageSignUpComponent,
    PersonalDataComponent,
    IdentityDocumentComponent,
    ContactInformationComponent,
    UserDataComponent,
    AlertComponent,
    HeaderComponent,
    FooterComponent,
    AcceptModalComponent,
    AccommodationComponent,
    CreditModalComponent,
    CreditsComponent,
    SelectBankComponent,
    SelectIncomeComponent,
    AdditionalContactsComponent,
    FamilyStatusComponent,
    EducationComponent,
    EmploymentComponent,
    IncomeComponent,
    PageSettingsComponent,
    PageContactsComponent,
    PageAboutComponent,
    PagePrivacyComponent,
    PageHowItWorksComponent,
    PagePartnersComponent,
    DeleteModalComponent,
    ConfirmModalComponent,
    AcceptPasswordModalComponent,
    PageMainComponent,
    MainInfoComponent,
    RegistrationComponent,
    PageOrderListComponent,
    PageOrderComponent,
    RememberPasswordModalComponent,
    SkinDefaultComponent,
    SkinInframeComponent,
    SkinBelagpromComponent,
    SkinBelarusComponent,
    SkinBelinvestComponent,
    SkinMtComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LibraryModule,
    AdminModule,
    RootModule,
    AppRoutingModule,
    NgbModule,
    NgxCaptchaModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    NgxBootstrapSliderModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    Title,
    UserService,
    AlertService,
    FormService,
    NgbActiveModal,

    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbUTCStringAdapter }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AcceptModalComponent,
    CreditModalComponent,
    DeleteModalComponent,
    ConfirmModalComponent,
    AcceptPasswordModalComponent,
    RememberPasswordModalComponent
  ]
})
export class AppModule { }
