import { Component, OnInit } from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Named} from '../../../_business/named';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent extends DataForm implements OnInit {

  educations: Named[];

  isAddedValidators = false;

  ngOnInit() {
    this.getEducations();
    super.ngOnInit();
  }

  getEducations(): void {
    this.namedService.getNamedList('educationtype')
      .subscribe(educations => this.educations = educations);
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        dimEducationTypeId: [
          null
        ]
      });
  }

}
