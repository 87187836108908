import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {Utils} from '../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-term-modal',
  templateUrl: './term-modal.component.html'
})
export class TermModalComponent extends DataForm {

  @Input() imgPath: string;
  @Output() emitData = new EventEmitter();

  imgPathHover = 'hover';

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'term': [
          null, [Validators.required]
        ]
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    const serviceCategory =
      this.obj
        ? this.rootService.saveTerm(this.dataForm.value)
        : this.rootService.createTerm(this.dataForm.value);

    serviceCategory
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next({action: 'save', obj: this.dataForm.value});
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }
}
