import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {NgModel} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router} from '@angular/router';
import {PeriodTypeEnum} from '../../../_business/PeriodType.enum';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? true : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-reports-tabs',
  templateUrl: './tabs.component.html'
})
export class ReportsTabsComponent implements OnInit, OnDestroy {

  @Input() stores;
  @Input() banks;
  @Input() item: string;
  @Output() emitData = new EventEmitter();

  filterStores = [];
  filterBanks = [];
  periodType;
  periodTypeEnum = PeriodTypeEnum;

  ignoreBanks = false;
  ignoreStores = false;
  toIgnore = false;

  eventsSubscription;

  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  model: any;
  private _subscription: Subscription;
  private _selectSubscription: Subscription;
  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  isHovered = date =>
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  constructor(
    private _parserFormatter: NgbDateParserFormatter,
    private _adapter: NgbDateAdapter<any>,
    private renderer: Renderer2,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.periodType = this.route.snapshot.paramMap.get('period');

          console.log('tab periodType', this.periodType);

          if (this.periodType === this.periodTypeEnum.custom) {
            this.route.queryParams.subscribe(params => {
              if (params['from'] && params['to']) {
                let d = params['from'].split('-');
                this.onDateSelection(
                  { day: d[2], month: d[1], year: d[0]},
                  false
                );

                d = params['to'].split('-');
                this.onDateSelection(
                  { day: d[2], month: d[1], year: d[0]},
                  false
                );
              }
            });
          } else {
            this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', '');
          }
        }
      });
  }

  ngOnInit() {
    this.startDate = {year: now.getFullYear(), month: now.getMonth() - 1, day: now.getDate()};
    this.maxDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.minDate = {year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate()};
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy tabs');
    this.eventsSubscription.unsubscribe();
  }

  onDateSelection(date: NgbDateStruct, withReload = true) {
    console.log('onDateSelection');

    let parsed = '';
    let toReload = false;

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      toReload = true;
      this.toDate = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate) {
      parsed += this._parserFormatter.format(this.fromDate);
    }

    if (this.toDate) {
      parsed += ' - ' + this._parserFormatter.format(this.toDate);
    }

    console.log(this.fromDate, this.toDate, parsed);

    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);

    if (toReload && withReload) {
      // this.emitData.next(this.dataForm);

      const from = this._parserFormatter.format(this.fromDate).split('.');
      const to = this._parserFormatter.format(this.toDate).split('.');

      const navigationExtras: NavigationExtras = {
        queryParams: {
          'from': from[2] + '-' + from[1] + '-' + from[0],
          'to': to[2] + '-' + to[1] + '-' + to[0]
        }
      };
      this.router.navigate(['/root/reports/' + this.item + '/custom'], navigationExtras);
    }
  }

  onChangeData($e, selId) {
    console.log(selId, $e, this.ignoreBanks);

    if (this.toIgnore) {
      this.toIgnore = false;
    } else {
      if (selId === 'selectBanks') {
        this.ignoreBanks = false;
      } else {
        this.ignoreStores = false;
      }
    }

    // For multiple select with ignore

/*    if (this.toIgnore) {
      this.toIgnore = false;
    } else {
      if (selId === 'selectBanks') {
        if (
          this.ignoreBanks &&
          (!$e.length || $e.length === this.banks.length)
        ) {
          this.ignoreBanks = false;
        }
      } else {
        if (
          this.ignoreStores &&
          (!$e.length || $e.length === this.stores.length)
        ) {
          this.ignoreStores = false;
        }
      }
    }*/

    this.emitData.next({
      filterStores: this.filterStores.join(':'),
      filterBanks: this.filterBanks.join(':'),
      ignoreBanks: this.ignoreBanks,
      ignoreStores: this.ignoreStores
    });

    if (!$e.length) {
      setTimeout(function() {
        jQuery('#' + selId).next().find('.filter-option-inner-inner').html(
          jQuery('#' + selId).attr('title')
        );

        jQuery('#' + selId).next().attr(
          'title',
          jQuery('#' + selId).attr('title')
        );
      });
    } else if (jQuery('#' + selId).attr('title') === 'Не учитывать') {
      setTimeout(function() {
        const txt = jQuery('#' + selId).next().find('.filter-option-inner-inner').html();

        jQuery('#' + selId).next().find('.filter-option-inner-inner').html(
          jQuery('#' + selId).attr('title') + ' ' + txt
        );
        jQuery('#' + selId).next().attr(
          'title',
          jQuery('#' + selId).attr('title') + ' ' + txt
        );
      });
    }

    // const navigationExtras: NavigationExtras = {
    //   queryParamsHandling: 'merge',
    //   queryParams: { stores: this.filterStores }
    // };
    // this.router.navigate(['/root/reports/stats/' + this.periodType], navigationExtras);
  }

  closeFix(event, datePicker) {
    if (
      event.target.offsetParent &&
      event.target.offsetParent.nodeName !== 'NGB-DATEPICKER' &&
      !jQuery(event.target.offsetParent).hasClass('ng-cal')
    ) {
      datePicker.close();

      if (this.fromDate && !this.toDate) {
        this.onDateSelection(this.fromDate);
      }
    }
  }

  toIgnoreBanks() {
    console.log('length', this.filterBanks.length, this.ignoreBanks);

    this.ignoreBanks = !this.ignoreBanks;

    if (this.ignoreBanks) {
      this.toIgnore = true;

      if (!this.filterBanks.length) {
        this.onChangeData([], 'selectBanks');
      }
    } else {
      this.onChangeData([], 'selectBanks');
    }
  }

  toIgnoreStores() {
    this.ignoreStores = !this.ignoreStores;

    if (this.ignoreStores) {
      this.toIgnore = true;

      if (!this.filterStores.length) {
        this.onChangeData([], 'selectStores');
      }
    } else {
      this.onChangeData([], 'selectStores');
    }
  }

}
