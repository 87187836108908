import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-priority-modal',
  templateUrl: './priority-modal.component.html'
})
export class PriorityModalComponent extends DataForm {

  @Input() named: string;
  @Input() stores;
  @Input() selectedStore = null;
  @Input() selectedPriority;
  @Input() banks;
  @Output() emitData = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'storeId': [
          this.selectedStore, (this.selectedStore ? [ Validators.required ] : [ ])
        ],
        'bankId': [
          null, [Validators.required]
        ],
        'priority': [
          this.selectedPriority, [Validators.required]
        ],
        'planMonth': [
          null, [Validators.required]
        ],
        'planDay': [
          null, [Validators.required]
        ],
        'msi': ''
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    if (this.dataForm.value.msi) {
      this.dataForm.value.msi = '1';
    } else {
      this.dataForm.value.msi = '0';
    }

    this.rootService.createPriority(this.dataForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next(this.dataForm);
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

}
