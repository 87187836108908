import {Component, Input, OnInit} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {NavigationExtras} from '@angular/router';
import {timer} from 'rxjs';
import {OrderStatusEnum} from '../../../_business/OrderStatus.enum';

@Component({
  selector: 'app-accept-modal',
  templateUrl: './accept-modal.component.html',
  styleUrls: ['./accept-modal.component.scss']
})
export class AcceptModalComponent extends DataForm {

  @Input() data: any;
  number: string;
  issueOrderId: number;

  loadingSms = false;
  disabledSms = true;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        code: [
          null, [
            Validators.required
          ]]
      });

    const secondsCounter = timer(30000);
    secondsCounter.subscribe(n => {
      this.disabledSms = false;
    });
  }

  onAcceptSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.userService.postPersonalData(this.data, this.dataForm.value.code)
      .pipe(first())
      .subscribe(
        data => {
          if (this.data.userInfo && this.data.userInfo.name) {
            this.auth.currentUserValue.name = this.data.userInfo.name;
          }

          // e  - не заполнена
          // f  - заполнена
          this.auth.currentUserValue.userformStatusCode = 'f';
          localStorage.setItem('currentUser', JSON.stringify(this.auth.currentUserValue));

          this.onStopSubmit();
          this.activeModal.close();

          if (this.issueOrderId) {
            const navigationExtras: NavigationExtras = {
              queryParams: { 'issue': this.issueOrderId }
            };
            this.router.navigate(['/orders'], navigationExtras);

            // const navigationExtras: NavigationExtras = {
            //   queryParams: { 'toIssue': true }
            // };
            // this.router.navigate(['/order/' + this.issueOrderId], navigationExtras);
          } else {
            this.onStopSubmit();
            this.activeModal.close();

            this.router.navigate(['/orders']);
          }
        },
        err => {
          this.onStopSubmit();

          if (err.status === 406) {
            err.error = 'Неверный код подтверждения анкеты';
          }

          this.error = err.error;
        }
      );
  }

  onRepeatSms() {
    if (this.disabledSms) {
      return;
    }

    this.loadingSms = true;
    this.disabledSms = true;
    this.error = '';

    this.userService.sendCode()
      .pipe(first())
      .subscribe(
        data => {
          this.loadingSms = false;

          const secondsCounter = timer(30000);
          secondsCounter.subscribe(n => {
            this.disabledSms = false;
          });
        },
        err => {
          this.loadingSms = false;
          this.error = err.error;
          this.disabledSms = false;
        }
      );
  }

}
