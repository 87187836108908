import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { map, catchError, tap} from 'rxjs/operators';
import { User } from '../_business/user';
import { environment } from '../../environments/environment';
import { Base64 } from 'js-base64';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private sectionMap = new Map();

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient
  ) {
    this.setCredentials();
    this.initSectionMap();
  }

  private setCredentials() {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private initSectionMap(): void {
    this.sectionMap.set('user', 'com.zabirai.user');
    this.sectionMap.set('authuser', 'com.zabirai.user/auth');
    this.sectionMap.set('updatePass', 'com.zabirai.user/updatePass');
    this.sectionMap.set('resendpass', 'com.zabirai.user/resendPass');
    this.sectionMap.set('request-forget-pass', 'com.zabirai.user/requestForgetPass');
    this.sectionMap.set('request-update', 'com.zabirai.user/requestUpdate');

    this.sectionMap.set('categories', 'com.zabirai.partners/categories');
    this.sectionMap.set('stores', 'com.zabirai.partners/stores');
    this.sectionMap.set('categories-root', 'com.zabiray.categories');
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(login: string, password: string, token: string) {
    login = login.replace(/[ \+]/g, '');
    console.log(login + ':' + password, token);

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + Base64.encode(login + ':' + password),
      'Content-Type': 'application/x-www-form-urlencoded',
      'g-recaptcha-response': token
    });

    /*    httpOptions.params =
          new HttpParams()
            .set('login', user.login)
            .set('password', user.password);*/

    return this.http.get<User>(
      environment.api + this.sectionMap.get('authuser'),
      { headers: headers, observe: 'response' }
    )
      .pipe(map(data => {
        console.log('data', data);

        // login successful
        if (data && data.body as User) {
          data.body.token = data.headers.get('Token-key');

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(data.body));
          // this.currentUserSubject.next(data.body);
          this.setCredentials();
        }

        return data.body;
      }));
  }

  changePassword(oldPassword: string, newPassword: string, code: string) {
    const login = this.currentUserValue.login;
    console.log(login + ':' + oldPassword);

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + Base64.encode(login + ':' + oldPassword),
      'Confirm-code': code
    });

    // const token = this.currentUserValue.token;
    // this.currentUserValue.token = null;

    return this.http.put<User>(
      environment.api + this.sectionMap.get('updatePass'),
      { newPassword: newPassword },
      { headers: headers, observe: 'response' }
    )
      .pipe(map(data => {
        console.log('data', data);

        if (data.headers.has('Token-key')) {
          console.log('token', data.headers.get('Token-key'));
          this.currentUserValue.token = data.headers.get('Token-key');

          localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));
        }

        return data.body;
      }));
  }

  requestUpdate(newPassword: string): any {
    return this.http.post<any>(
      environment.api + this.sectionMap.get('request-update'),
      { newPassword: newPassword }
    );
  }

  register(login: string, token: string) {
    login = login.replace(/[ \+]/g, '');

    const headers = new HttpHeaders({
      'g-recaptcha-response': token
    });

    return this.http.post<User>(
      environment.api + this.sectionMap.get('user'),
      {
        login: login
      },
      { headers: headers, observe: 'response' }
    )
      .pipe(
        tap((data) => {
          console.log('tap', data);

          if (data && data.body as User) {
            console.log(`register user id=${data.body.id}`);
          }

          return data.body;
        })
      );
  }

  requestForgetPass(login: string, token: string) {
    login = login.replace(/[ \+]/g, '');

    const headers = new HttpHeaders({
      'g-recaptcha-response': token
    });

    return this.http.post<any>(
      environment.api + this.sectionMap.get('request-forget-pass'),
      {
        login: login
      },
      { headers: headers, observe: 'response' }
    );
  }

  resendPass(login: string, code: string = null): any {
    login = login.replace(/[ \+]/g, '');

    let headers = new HttpHeaders();

    // В header добавляем Confirm-code
    // (логика следующая, если Confirm-code отсутствует,
    // пароль будет выслан только пользователю, который еще не авторизовался в системе)

    if (code) {
      headers = new HttpHeaders({
        'Confirm-code': code
      });
    }

    return this.http.post<any>(
      environment.api + this.sectionMap.get('resendpass'),
      { login: login },
      { headers: headers, observe: 'response' }
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  getCategories() {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('categories')}`
    );
  }

  getCategoryImgPath() {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('categories-root')}/imgpath`
    );
  }

  getStores() {
    return this.http.get<any>(
      `${environment.api}${this.sectionMap.get('stores')}`
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
