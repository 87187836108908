import { Component, OnInit } from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Named} from '../../../_business/named';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent extends DataForm implements OnInit {

  streets: Named[];
  positions: Named[];
  occupations: Named[];
  employments: Named[];

  isAddedValidators = false;
  validators = {
    enrollmentDate: []
  };

  ngOnInit() {
    this.getStreets();
    this.getPositions();
    this.getOccupations();
    this.getEmployments();
    super.ngOnInit();
  }

  getStreets(): void {
    this.namedService.getNamedList('streettype')
      .subscribe(streets => this.streets = streets);
  }

  getPositions(): void {
    this.namedService.getNamedList('positiontype')
      .subscribe(positions => this.positions = positions);
  }

  getOccupations(): void {
    this.namedService.getNamedList('occupation')
      .subscribe(occupations => this.occupations = occupations);
  }

  getEmployments(): void {
    this.namedService.getNamedList('employmenttype')
      .subscribe(employments => this.employments = employments);
  }

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        dimStreetTypeId: [
          null
        ],
        phoneAcc: [
          null
        ],
        phoneWork: [
          null
        ],
        enrollmentDate: [
          null, [ Validators.required ]
        ],
        locality: [
          null
        ],
        building: [
          null
        ],
        house: [
          null
        ],
        office: [
          null
        ],
        dimPositionTypeId: [
          null
        ],
        dimOccupationId: [
          null
        ],
        dimEmploymentTypeId: [
          null
        ],
        staffCount: [
          null
        ],
        employer: [
          null
        ],
        position: [
          null
        ]
      });
  }

}
