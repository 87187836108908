import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../../_helpers/dataForm';
import {first} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {ProductTypeEnum} from '../../../../_business/ProductType.enum';
import {BankStatusTypeEnum} from '../../../../_business/BankStatusType.enum';
import {Utils} from '../../../../_helpers/utils';
import {ConfirmModalComponent} from '../../../../parts/confirm-modal/confirm-modal.component';

declare var jQuery: any;

@Component({
  selector: 'app-access-modal',
  templateUrl: './bank-product-modal.component.html'
})
export class BankProductModalComponent extends DataForm implements OnInit {

  // @Input() stores;
  @Input() productTypes;
  @Input() bankStatusTypes;
  // @Input() selectedStore = null;
  @Input() banks;

  productTypeEnum = ProductTypeEnum;
  bankStatusTypeEnum = BankStatusTypeEnum;

  terms = [];
  stores = [];

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'bankId': [
          '', [Validators.required]
        ],
        'storeId': [
          [], [Validators.required]
          // this.selectedStore, (this.selectedStore ? [ Validators.required ] : [ ])
        ],
        'productId': [
          this.productTypeEnum.installment, [Validators.required]
        ],
        'statusId': [
          this.bankStatusTypeEnum.active, [Validators.required]
        ],
        'terms': [
          [], [Validators.required]
        ]
      });
  }

  ngOnInit() {
    super.ngOnInit();

    for (let x = 1; x <= 60; x++) {
      this.terms.push(x);
    }

    setTimeout(function () {
      jQuery('select.sp.custom').selectpicker({
        actionsBox: true,
        countSelectedText: 1,
        deselectAllText: 'Убрать все',
        selectAllText: 'Выбрать все',
        noneResultsText: 'Не найдено {0}',
        // allSelectedText: 'All',
        // maxHeight: 200,
      });
    });

    if (this.obj) {
      // this.dataForm.controls.terms.patchValue(this.obj.terms);
      // this.dataForm.get('terms').setValue(this.obj.terms, {emitEvent: false});

      const select = [];

      for (let i = 0; i < this.obj.terms.length; i++) {
        select.push((+this.obj.terms[i] - 1) + ": '" + this.obj.terms[i] + "'");
      }

      setTimeout(function () {
        jQuery('#selectTerms').selectpicker('val', select);
        // jQuery('#selectTerms').selectpicker('val', ["0: '1'", "1: '2'"]);
      });

      this.onChangeBank();
    }
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    if (this.obj) {
      this.dataForm.value.id = this.obj.id;
    }

    this.rootService.createBankProduct(this.dataForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next('save');
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onChangeBank(force = false) {
    this.rootService.getPriorityStores(this.dataForm.value.bankId)
      .subscribe(data => {
        this.stores = data;

        if (force) { // reset stores
          this.dataForm.get('storeId').setValue([], {emitEvent: false});
        }

        setTimeout(function () {
          jQuery('#selectBankStores').selectpicker('refresh');
        });

        if (!force) {
          // FIXME: selected values
          const select = [];

          for (let i = 0; i < this.stores.length; i++) {
            for (let j = 0; j < this.obj.storeId.length; j++) {
              if (+this.stores[i].id === +this.obj.storeId[j]) {
                select.push(i + ": '" + this.obj.storeId[j] + "'");
                break;
              }
            }
          }

          console.log(select);

          setTimeout(function () {
            jQuery('#selectBankStores').selectpicker('val', select);
          });
        }
      });
  }

}
