import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';
import {DataForm} from '../../_helpers/dataForm';
import {Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-page-contacts',
  templateUrl: './page-contacts.component.html',
  styleUrls: ['./page-contacts.component.scss']
})
export class PageContactsComponent extends DataForm {

  siteKey: string = environment.recaptchaPublicKey;

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        email: [
          null, [
            Validators.required,
            Validators.email
          ]],
        message: [
          null, [
            Validators.required
          ]]
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.reCaptchaV3Service.execute(this.siteKey, 'user/message', (token) => {
      console.log('captcha token: ', token);
      this.userService.sendEmail(
        this.f.email.value,
        this.f.message.value,
        token
      )
        .pipe(first())
        .subscribe(
          user => {
            this.onStopSubmit();
            this.error = null;
            this.success = true;
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    }, {
      useGlobalDomain: false
    });
  }

}
