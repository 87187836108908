import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-page-partners',
  templateUrl: './page-partners.component.html',
  styleUrls: ['./page-partners.component.scss']
})
export class PagePartnersComponent implements OnInit {

  path = 'assets/img/shop/';
  categories;
  stores = {};
  dataLoaded = false;

  constructor(
    public auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.auth.getCategoryImgPath()
      .subscribe(data => {
          this.path = data.path;
        }
      );

    this.auth.getCategories()
      .subscribe(data => {
        this.categories = data;

        for (let x = 0; x < this.categories.length; x++) {
          const category = this.categories[x];
          category.iconUrl = encodeURI(category.iconUrl);
          category.hoverUrl = encodeURI(category.hoverUrl);
        }

        this.auth.getStores()
          .subscribe(stores => {
            for (let x = 0; x < stores.length; x++) {
              const store = stores[x];

              if (!this.stores[store.categoryId]) {
                this.stores[store.categoryId] = [];
              }

              this.stores[store.categoryId].push(store);
            }

            this.dataLoaded = true;
          });
      });
  }

}
