import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuardService implements CanActivate  {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      const url = '/orders';

      console.log('guest', this.router.url);

      // e  - не заполнена
      // f  - заполнена
      // if (
      //   currentUser.userformStatusCode == 'e' // e  - не заполнена
      //   //&& this.router.url != '/' && this.router.url != '/signin' // поможет ли это от циклических редиректов?
      // )
      //   url = '/signup';

      this.router.navigate([url]);

      return false;
    }

    return true;
}

}
