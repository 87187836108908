import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {Utils} from '../../../_helpers/utils';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-interval-modal',
  templateUrl: './interval-modal.component.html',
  styleUrls: ['./interval-modal.component.scss']
})
export class IntervalModalComponent extends DataForm implements OnInit {

  @Output() emitData = new EventEmitter();

  initForm(): void {
    const now = new Date();

    let from = '', from_dim = 'm', to = '', to_dim = 'm';

    if (this.obj) {
      const o = this.obj.name.split('-');

      from = o[0].replace(/\s|свыше/g, '');

      if (o[0].indexOf('час') > -1) {
        from_dim = to_dim = 'h';
      }

      if (o.length > 1) {
        to = o[1].replace(/\s|свыше/g, '');

        if (o[1].indexOf('час') > -1) {
          to_dim = 'h';

          if (o[0].indexOf('час') < 0) {
            from_dim = to_dim;
          }
        }
      }
    }

    this.dataForm =
      this.formBuilder.group({
        'from': [from, Validators.required],
        'from_dim': from_dim,
        'to': to,
        'to_dim': to_dim
      });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const d = this.dataForm.value;
    const data = <any>{};

    if (this.obj) {
      data.id = this.obj.id;
    }

    data.start = d.from + d.from_dim;

    if (d.to) {
      data.stop = d.to + d.to_dim;
    }

    this.rootService.saveInterval(data)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();
          this.emitData.next('create');
          this.activeModal.close('Close click');
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

  onDelete() {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить интервал?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      // if (!this.onStartSubmit()) {
      //   return false;
      // }

      this.error = '';
      this.rootService.deleteInterval(this.obj.id)
        .pipe(first())
        .subscribe(
          data => {
            this.onStopSubmit();

            if (data && data.error && data.errorText) {
              this.error = data.errorText;
            } else {
              this.emitData.next('delete');
              this.activeModal.close('Close click');
            }
          },
          err => {
            this.onStopSubmit();
            this.error = err.error;
          }
        );
    });
  }

}
