import { Component, OnInit } from '@angular/core';
import {Named} from '../../_business/named';
import {DataTable} from '../../_helpers/dataTable';
import {NamedService} from '../../_services/named.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {first} from 'rxjs/operators';
import {OrderModalComponent} from '../orders/order-modal/order-modal.component';
import {DimModalComponent} from './dim-modal/dim-modal.component';
import {environment} from '../../../environments/environment';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-dims',
  templateUrl: './dims.component.html',
  styleUrls: ['./dims.component.scss']
})
export class DimsComponent extends DataTable implements OnInit {

  debug: boolean = environment.debug;
  nameds: Named[];
  dimDropDown: string;

  dims = [
    { name: 'bank', title: 'Банки' },
    { name: 'bankpartner', title: 'Банки-партнеры' },
    { name: 'country', title: 'Страны' },
    { name: 'educationtype', title: 'Тип образования' },
    { name: 'employmenttype', title: 'Тип занятости' },
    { name: 'gender', title: 'Пол' },
    { name: 'housing', title: 'Жилищные условия' },
    { name: 'marriage', title: 'Семейное положение' },
    { name: 'occupation', title: 'Сфера деятельности' },
    { name: 'orderstatus', title: 'Статус заказа' },
    { name: 'passporttype', title: 'Тип документа' },
    { name: 'positiontype', title: 'Вид должности' },
    { name: 'relationship', title: 'Тип связи' },
    { name: 'streettype', title: 'Тип улицы' },
    { name: 'userstatus', title: 'Статус пользователя' },
    { name: 'userformstatus', title: 'Статус анкеты пользователя' }
  ];

  constructor(
    private namedService: NamedService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.dimDropDown = this.dims[0].name;

    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        columns: [
          { data: 'id' },
          { data: 'code' },
          { data: 'name' }
        ]
      }
    );

    this.loadData();
  }

  loadData() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.namedService.getNamedList(this.dimDropDown, dataTablesParameters)
        .pipe(first())
        .subscribe(data => {
          console.log(data);

          const count = data.length;

          this.nameds = data;

          callback({
            recordsTotal: dataTablesParameters.start + count,
            recordsFiltered: this.nameds.length,
            data: { data: this.nameds }
          });
        });
    };
  }

  onChangeDim(e) {
    console.log(e);
    this.loadData();

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  onShowPop() {
    const modalRef = this.modalService.open(
      DimModalComponent
    );

    modalRef.componentInstance.named = this.dimDropDown;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.reload();
    });
  }

  onDelete(id: number) {
    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить #' + id + '?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.namedService.delete(this.dimDropDown, id)
        .pipe(first())
        .subscribe(
          data => {
            if (data && data.errorText) {
              alert(data.errorText);
            } else {
              this.reload();
            }
          },
          err => {
            alert(err.error);
          }
        );
    });
  }

}
