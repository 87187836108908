import { Component, OnInit } from '@angular/core';
import {CreditModalComponent} from '../credit-modal/credit-modal.component';
import {DataForm} from '../../../_helpers/dataForm';
import {FormArray, FormGroup, Validators} from '@angular/forms';
import {Named} from '../../../_business/named';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent extends DataForm implements OnInit {

  banks: Named[];

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        items: this.formBuilder.array([])
      });
  }

  ngOnInit() {
    this.getBanks();
    super.ngOnInit();
  }

  getBanks(): void {
    this.namedService.getNamedList('bank')
      .subscribe(banks => this.banks = banks);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      dimBankId: [
        null, [
          Validators.required
        ]],
      monthAmount: [
        null, [
          Validators.required
        ]]
    });
  }

  removeItem(i): void {
    this.items.removeAt(i);
  }

  onAddCredit() {
    const modalRef = this.modalService.open(CreditModalComponent);
    modalRef.componentInstance.banks = this.banks;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.items.push($e);
    });
  }

  getBankName(id: number): string {
    let title = 'Bank undefined';

    for (const x in this.banks) {
      if (this.banks[x].id == id) {
        title = this.banks[x].name;
      }
    }

    return title;
  }

}
