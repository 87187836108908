import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-option-modal',
  templateUrl: './option-modal.component.html',
  styleUrls: ['./option-modal.component.scss']
})
export class OptionModalComponent extends DataForm {

  @Output() emitData = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'name': '',
        'valueStr': '',
        'note': ''
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.adminService.createConst(this.dataForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          if (data && data.errorText) {
            this.error = data.errorText;
          } else {
            this.emitData.next(this.dataForm);
            this.activeModal.close('Close click');
          }
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

}
