import { Component, OnInit } from '@angular/core';
import {DataTable} from '../../_helpers/dataTable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RootService} from '../../_services/root.service';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../parts/confirm-modal/confirm-modal.component';
import {Named} from '../../_business/named';
import {UserStatusCodeEnum} from '../../_business/UserStatusCode.enum';
import {ToastaService} from 'ngx-toasta';
import {NamedService} from '../../_services/named.service';
import {RootAuthenticationService} from '../../_services/root-authentication.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html'
})
export class ClientsComponent extends DataTable implements OnInit {

  statuses: Named[];
  userStatusCodeEnum = UserStatusCodeEnum;

  filterFio = '';
  filterLogin = '';

  statusOptionsModel: number[];

  constructor(
    private modalService: NgbModal,
    private rootService: RootService,
    private toastr: ToastaService,
    private namedService: NamedService,
    public auth: RootAuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.namedService.getNamedList('userstatus')
      .subscribe(data => {
        this.statuses = data;
      });

    const columns = [
      { data: 'login' },
      { data: 'fullNameStr' },
      { data: 'statusId' }
    ];

    if (this.auth.hasRight('%/webresources/com.zabirai.root.user/clients%', 'delete')) {
      columns.unshift({ data: '' });
    }

    this.dtOptions = Object.assign(
      {},
      this.dtOptions,
      {
        columns: columns,
        order: [[1, 'desc']]
      }
    );

    this.loadData();
  }

  loadData() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      dataTablesParameters.filterFio = this.filterFio;
      dataTablesParameters.filterLogin = this.filterLogin;

      if (this.statusOptionsModel && this.statusOptionsModel.length) {
        dataTablesParameters.filterStatus = this.statusOptionsModel.join(':');
      }

      this.rootService.getClients(dataTablesParameters, '/count')
        .pipe(first())
        .subscribe(countData => {
          console.log(countData);
          const count = countData['count'];

          this.rootService.getClients(dataTablesParameters)
            .pipe(first())
            .subscribe(data => {
                if (data.length > dataTablesParameters.length) {
                  data.pop();
                }

                this.dataList = data;

                callback({
                  recordsTotal: count,
                  recordsFiltered: count,
                  // recordsTotal: dataTablesParameters.start + count,
                  // recordsFiltered: this.dataList.length,
                  data: { data: this.dataList }
                });
              },
              err => {
                this.error = err.error;
              }
            );
          },
          err => {
            this.error = err.error;
          }
        );
    };
  }

  onRestore() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Выбранные аккаунты будут разблокированы. Продолжить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStatus(this.userStatusCodeEnum.active);
    });
  }

  onBlock() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Выбранные аккаунты будут заблокированы. Продолжить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      this.toChangeStatus(this.userStatusCodeEnum.block);
    });
  }

  toChangeStatus(statusCode: UserStatusCodeEnum) {
    const status = this.utils.getObjFromList(this.statuses, statusCode, 'code');
    this.error = '';

    const id = this.selectedList.shift();

    this.rootService.saveClient({
      id: id,
      statusId: status.id
    })
      .pipe(first())
      .subscribe(
        data => {
          if (this.selectedList.length) {
            this.toChangeStatus(statusCode);
          } else {
            this.reload();
            this.toastr.success(
              status.code === this.userStatusCodeEnum.block
                ? 'Выбранные аккаунты заблокированы'
                : 'Выбранные аккаунты разблокированы'
            );
          }
        },
        err => {
          this.error = err.error;
        }
      );
  }

  onDelete() {
    if (!this.selectedList.length) {
      return;
    }

    const modalRef = this.modalService.open(
      ConfirmModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.body = 'Вы уверены что хотите удалить?';
    modalRef.componentInstance.submit.subscribe(($e) => {
      while (this.selectedList.length > 0) {
        const id = this.selectedList.shift();
        this.rootService.deleteClient(id)
          .pipe(first())
          .subscribe(
            data => {
              if (!this.selectedList.length) {
                this.reload();
              }
            },
            err => {
              this.error = err.error;
            }
          );
      }
    });
  }

  onFilter($e) {
    console.log('onChange', $e);
    this.reload();
  }

}
