import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import {first} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../../parts/confirm-modal/confirm-modal.component';
import {ActivatedRoute, NavigationExtras} from '@angular/router';
import {AuthenticationService} from '../../../_services/authentication.service';
import {OrderService} from '../../../_services/order.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NamedService} from '../../../_services/named.service';
import {BankEnum} from '../../../_business/Bank.enum';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit, AfterViewInit {

  phone = '';
  success = false;
  movie = 'https://www.youtube.com/embed/7kjX0lQgRFY';
  doc;

  loading = false;
  error = '';

  bankEnum = BankEnum;
  bankId = this.bankEnum.idea;
  bankName;
  inframe = null;
  bankResponse = <any>{};

  debug: boolean = environment.debug;

  constructor(
    public route: ActivatedRoute,
    public renderer: Renderer2,
    public auth: AuthenticationService,
    public orderService: OrderService,
    public modalService: NgbModal,
    public namedService: NamedService
  ) { }

  ngOnInit() {
    if (this.auth.currentUserValue) {
      this.bankId = null;
    }

    if (localStorage.getItem('bankResponse')) {
      try {
        this.bankResponse = JSON.parse(localStorage.getItem('bankResponse'));
        // localStorage.removeItem('bankResponse');
        // console.log('response', this.bankResponse);
      } catch (e) {
        console.error('catch Exception:', e);
      }
    }

    this.route.queryParams.subscribe(params => {
      if (params['phone'] && params['phone'] !== 'undefined') {
        this.phone = params['phone'];
      }

      if (params['bank']) {
        this.bankId = +params['bank'];
      }

      if (params['inframe']) {
        this.inframe = true;
      }
    });

    if (this.phone) {
      this.renderer.addClass(document.body, 'logged-fake');

      if (this.auth.currentUserValue) {
        this.movie = null;
      }

      if (typeof (history.pushState) !== 'undefined' && !this.debug) {
        const obj = { Title: 'Мои покупки', Url: '/orders' };
        history.pushState(obj, obj.Title, obj.Url);
      }
    }
  }

  ngAfterViewInit() {
    if (localStorage.getItem('bankResponse')) {
      localStorage.removeItem('bankResponse');
    }
  }

  getBankDoc() {
    this.getBankName();
    this.orderService.getBankDoc(this.bankId)
      .pipe(first())
      .subscribe(
        data => {
          this.doc = data.paramValue;
        }
      );
  }

  getBankMovie() {
    // if (this.movie) {
    //   return;
    // }

    if (!this.bankId) {
      // this.getBankDoc();
      return;
    }

    this.orderService.getBankMovie(this.bankId)
      .pipe(first())
      .subscribe(
        data => {
          this.movie = data.paramValue;
        },
        err => {
          // if (err.status === 404) {
            this.getBankDoc();
          // }
        }
      );
  }

  getBankName(): void {
    this.namedService.getNamedList('bankpartner')
      .subscribe(
        banks => {
          for (const x in banks) {
            const b = banks[x];

            if (+b.id === +this.bankId) {
              this.bankName = b.name;
              break;
            }
          }
        }
      );
  }

  onIssue() {
    this.loading = true;
    this.error = '';

    this.orderService.getBankInfo(this.bankId || this.bankEnum.idea)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          const bankUrl = data.paramValue;

          if (bankUrl) {
            const modalRef = this.modalService.open(
              ConfirmModalComponent,
              { backdrop: 'static' }
            );
            modalRef.componentInstance.body = 'Вы будете переадресованы на сайт банка для продолжения оформления заявки';
            modalRef.componentInstance.link = bankUrl;
          } else {
            this.error = 'Отсутствуют данные банка';
          }
        },
        err => {
          this.error = err.error;
          this.loading = false;
        }
      );
  }

}
