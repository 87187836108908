import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataForm} from '../../../_helpers/dataForm';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-access-modal',
  templateUrl: './access-modal.component.html',
  styleUrls: ['./access-modal.component.scss']
})
export class AccessModalComponent extends DataForm {

  @Input() named: string;
  @Output() emitData = new EventEmitter();

  initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        'auth': '',
        'mtd': '',
        'url': '',
      });
  }

  onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.adminService.createAccess(this.dataForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.onStopSubmit();

          if (data && data.errorText) {
            this.error = data.errorText;
          } else {
            this.emitData.next(this.dataForm);
            this.activeModal.close('Close click');
          }
        },
        err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      );
  }

}
